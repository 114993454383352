<template>
    <div id="timeline" class="timeline">
        <h1 class="mt-8">News AI from ZEBU</h1>
        <!-- <v-divider></v-divider> -->
        <div class="btn-group">
            <button v-if="!expand" class="btn" @click="expandAll"><v-icon class="timeline__arrow-icon"
                    viewBox="0 0 24 24">mdi mdi-chevron-down</v-icon></button>
            <button v-if="!collapse" class="btn" @click="collapseAll"><v-icon class="timeline__arrow-icon"
                    viewBox="0 0 24 24">mdi mdi-chevron-left</v-icon></button>
        </div>
        <div v-for="(item, index) in MainData" :key="index" class="timeline__item">
            <div class="timeline__item-header">
                <button class="timeline__arrow" :id="'item' + index" :aria-labelledby="'item' + index + '-name'"
                    :aria-expanded="item.expanded.toString()" :aria-controls="'item' + index + '-ctrld'"
                    :aria-haspopup="true" @click="toggleItem(index)">
                    <v-icon class="timeline__arrow-icon" viewBox="0 0 24 24">mdi mdi-chevron-down</v-icon>
                    <!-- <svg  width="24px" height="24px">
              
            </svg> -->
                </button>
                <span class="timeline__dot"></span>

                <div style="cursor: pointer" @click="toggleItem(index)" data-aos="fade-left">
                    <span class="mb-0 body-2 grey--text ">{{ item.time }}</span>

                    <p class="mb-0 body-1 mt-2 font-weight-bold">{{ item.title }}</p>
                    <v-chip v-for="(item, chipIndex) in item.sector.split(',').map(s => s.trim())" :key="chipIndex"
                        small class="pa-2 mr-2 mt-1" color="blue" outlined>
                        {{ item }}
                    </v-chip>
                </div>



            </div>
            <div :id="'item' + index + '-ctrld'" class="timeline__item-body" role="region"
                :aria-labelledby="'item' + index" :aria-hidden="(!item.expanded).toString()"
                :class="{ 'timeline__item-body--expanded': item.expanded }">
                <div class="timeline__item-body-content">
                    <v-card class="pa-4 elevation-0"
                        style="background-color: #edeff0; border-radius: 10px; width: 70%;">
                        <p data-aos="fade-left" class="body-2 mt-6 ml-6 black--text  ">
                            <span class="mb-1 grey--text body-2 pr-2">

                            </span> {{ item.summary }}
                        </p>
                        <v-row>
                            <v-col class="ma-2">
                                <v-card class="elevation-0 "
                                    style="padding: 1px;   height: 150px ; background-color: #ECF8F1; border-radius: 10px; overflow: hidden;">
                                    <p data-aos="fade-left" class="body-2 mt-6 ml-6 black--text   ">
                                        <span class="mb-1 green--text body-2 pr-2">

                                        </span> {{ item.positive_impact }}
                                    </p>
                                </v-card>
                            </v-col>
                            <v-col class="ma-2">
                                <v-card class="elevation-0"
                                    style="padding: 1px; ;width: 100%; height: 150px ; background-color: #FCF3F3 ; border-radius: 10px; overflow: hidden;">
                                    <p data-aos="fade-left" class="body-2 mt-6 ml-6 black--text  ">
                                        <span class="mb-1 red--text body-2 pr-2">

                                        </span> {{ item.negative_impact }}
                                    </p>
                                </v-card>
                            </v-col>

                        </v-row>
                        <a class="ml-4 mt-8" v-if="item.link != null" target="_blank" :href="item.link">{{ item.source
                            }} >>></a>


                    </v-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            MainData: [],
            collapse: true,
            expand: false,
            timelineItems: [
                {
                    id: 1,
                    date: "1970-01-01",
                    dateFormatted: "January 1, 1970",
                    title: "Unix Epoch",
                    description:
                        "This is the day the Unix clock began (or <time datetime='1969-12-31'>December 31, 1969</time> if you live behind UTC 😉).",
                    expanded: false,
                },
                {
                    id: 2,
                    date: "1973-10-17",
                    dateFormatted: "October 17, 1973",
                    title: "Digits Within ISO 8601 Format",
                    description:
                        "At 6:36:57 PM UTC, the date in ISO 8601 format (1973-10-17) within the time digits (119731017) appeared for the first time.",
                    expanded: false,
                },
                {
                    id: 3,
                    date: "2001-09-09",
                    dateFormatted: "September 9, 2001",
                    title: "1 Billion Seconds",
                    description:
                        "Unix time reached 1,000,000,000 seconds at 1:46:40 AM UTC. The Danish UNIX User Group celebrated this in Copenhagen, Denmark.",
                    expanded: false,
                },
                {
                    id: 4,
                    date: "2009-02-13",
                    dateFormatted: "February 13, 2009",
                    title: "1,234,567,890 Seconds",
                    description:
                        "At 11:31:30 PM UTC, the digits of the time were 1234567890. This was celebrated worldwide, and even Google had a <a href='https://www.google.com/logos/unix1234567890.gif' target='_blank' rel='noopener'>doodle</a> for it.",
                    expanded: false,
                },
                {
                    id: 5,
                    date: "2033-05-18",
                    dateFormatted: "May 18, 2033",
                    title: "2 Billion Seconds",
                    description:
                        "Unix time will reach 2,000,000,000 seconds at 3:33:20 AM UTC.",
                    expanded: false,
                },
                {
                    id: 6,
                    date: "2038-01-19",
                    dateFormatted: "January 19, 2038",
                    title: "Unix Epochalypse",
                    description:
                        "Also known as the year 2038 problem, clocks running on a 32-bit signed integer will flip from 3:14:08 AM UTC on this day to 8:45:52 PM UTC on December 13, 1901. Therefore, values only from -2,147,483,648 to 2,147,483,647 for the second are supported.",
                    expanded: false,
                },
            ],
        };
    },
    methods: {
        toggleItem(index) {
            const item = this.MainData[index];
            item.expanded = !item.expanded;
        },
        expandAll() {
            this.expand = true
            this.collapse = false
            this.MainData.forEach((item) => {
                item.expanded = true;
            });
        },
        collapseAll() {
            this.expand = false
            this.collapse = true
            this.MainData.forEach((item) => {
                item.expanded = false;
            });
        },
        mountedData() {
            const axios = require('axios');

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'http://192.168.5.163:9001/getNews',
                headers: {}
            };
            let axiosthis = this
            axios.request(config)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    console.log(response.data, "lolo");

                    for (let i = 0; i < response.data.length; i++) {
                        const element = response.data[i];
                        element['expanded'] = false


                    }
                    axiosthis.MainData = response.data
                    console.log(axiosthis.MainData, 'popo');

                    // axiosthis.MainData = response.data


                })
                .catch((error) => {
                    console.log(error);
                });

        },
    },
    mounted() {
        this.mountedData()
    }
};
</script>

<style scoped>
* {
    border: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:root {
    --hue: 223;
    --bg: hsl(var(--hue), 10%, 90%);
    --fg: hsl(var(--hue), 10%, 10%);
    --primary: hsl(var(--hue), 90%, 50%);
    --trans-dur: 0.3s;
    --trans-timing: cubic-bezier(0.65, 0, 0.35, 1);
    font-size: calc(16px + (24 - 16) * (100vw - 320px) / (2560 - 320));
}

a {
    color: var(--primary);
    transition: color var(--trans-dur);
}

body,
button {
    color: var(--fg);
    font: 1em/1.5 "IBM Plex Sans", sans-serif;
}

body {
    background-color: var(--bg);
    height: 100vh;
    transition:
        background-color var(--trans-dur),
        color var(--trans-dur);
}

h1 {
    font-size: 2em;
    margin: 0 0 3rem;
    padding-top: 1.5rem;
    text-align: center;
}

.btn {
    background-color: var(--fg);
    border-radius: 0.25em;
    color: var(--bg);
    cursor: pointer;
    padding: 0.375em 0.75em;
    transition:
        background-color calc(var(--trans-dur) / 2) linear,
        color var(--trans-dur);
    -webkit-tap-highlight-color: transparent;
}

.btn:hover {
    background-color: hsl(var(--hue), 10%, 50%);
}

.btn-group {
    display: flex;
    gap: 0.375em;
    margin-bottom: 1.5em;
}

.timeline {
    margin-left: 100px;
    /* padding: 0 1.5em;
	width: 100%;
	max-width: 36em; */
}

.timeline__arrow {
    color: red;
    background-color: transparent;
    border-radius: 0.25em;
    cursor: pointer;
    flex-shrink: 0;
    margin-inline-end: 0.25em;
    outline: transparent;
    width: 2em;
    height: 2em;
    transition:
        background-color calc(var(--trans-dur) / 2) linear,
        color var(--trans-dur);
    -webkit-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.timeline__arrow:focus-visible,
.timeline__arrow:hover {
    background-color: hsl(var(--hue), 10%, 50%, 0.4);
}

.timeline__arrow-icon {
    display: block;
    pointer-events: none;
    transform: rotate(-90deg);
    transition: transform var(--trans-dur) var(--trans-timing);
    width: 100%;
    height: auto;
}

.timeline__date {
    font-size: 0.833em;
    line-height: 2.4;
}

.timeline__dot {
    background-color: currentColor;
    border-radius: 50%;
    display: inline-block;
    flex-shrink: 0;
    margin: 0.625em 0;
    margin-inline-end: 1em;
    position: relative;
    width: 0.75em;
    height: 0.75em;
}

.timeline__item {
    position: relative;
    padding-bottom: 2.25em;
}

.timeline__item:not(:last-child):before {
    background-color: currentColor;
    content: "";
    display: block;
    position: absolute;
    top: 1em;
    left: 2.625em;
    width: 0.125em;
    height: 100%;
    transform: translateX(-50%);
}

[dir="rtl"] .timeline__arrow-icon {
    transform: rotate(90deg);
}

[dir="rtl"] .timeline__item:not(:last-child):before {
    right: 2.625em;
    left: auto;
    transform: translateX(50%);
}

.timeline__item-header {
    display: flex;
}

.timeline__item-body {
    border-radius: 0.375em;
    overflow: hidden;
    margin-top: 0.5em;
    margin-inline-start: 4em;
    height: 0;
}

.timeline__item-body-content {
    background-color: hsl(var(--hue), 10%, 50%, 0.2);
    opacity: 0;
    padding: 0.5em 0.75em;
    visibility: hidden;
    transition:
        opacity var(--trans-dur) var(--trans-timing),
        visibility var(--trans-dur) steps(1, end);
}

.timeline__meta {
    width: 100%;
}

.timeline__title {
    font-size: 1.5em;
    line-height: 1.333;
}

/* Expanded state */
.timeline__item-body--expanded {
    height: auto;
}

.timeline__item-body--expanded .timeline__item-body-content {
    opacity: 1;
    visibility: visible;
    transition-delay: var(--trans-dur), 0s;
}

.timeline__arrow[aria-expanded="true"] .timeline__arrow-icon {
    transform: rotate(0);
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
    :root {
        --bg: hsl(var(--hue), 10%, 10%);
        --fg: hsl(var(--hue), 10%, 90%);
        --primary: hsl(var(--hue), 90%, 70%);
    }
}
</style>