<template>
  <div id="app" class="ma-lg-10 ml-lg-10 ma-md-10 ml-md-10">
    <v-snackbar class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" transition="slide-x-reverse-transition"
      v-model="snackbar" :timeout="4000" :value="true" color="warning" text-color="white">
      <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
      Session <strong>{{ txt }}</strong> Kindly Login again.
      <v-icon @click="snackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
    </v-snackbar>
    <v-snackbar class="snakbar-sty rounded-pill" transition="slide-x-reverse-transition" v-model="snackbarnew"
      timeout="6000" :value="true" :color="snackcolor" top right outlined absolute text-color="white">
      <v-icon class="mr-2" :color="snackcolor">mdi-alert-outline</v-icon>
      {{ mesg }}
      <v-icon @click="snackbarnew = false" class="float-right" :color="snackcolor">mdi-close-circle</v-icon>
    </v-snackbar>
    <div class="mx-3 px-1">
      <!-- <div class="py-md-7  d-block d-md-none "></div> -->
      <div class="px-md-3">
        <v-list-item class="pl-0 pl-md-1 ">
            <v-list-item-content>
                <v-list-item-title class="text-start"
                    style="color: #000000;font-size: 20px;font-weight:559;line-height:24px;letter-spacing:-1px">
                    Trading Preference</v-list-item-title>
                <v-list-item-subtitle class="text-start pt-1"
                    style="color: #666666;font-size: 12px;font-weight:469;line-height:14px"> Your account has access to only these currently active segments.
                </v-list-item-subtitle>
            </v-list-item-content>
       </v-list-item>


        <!-- <p class="font-weight-bold fs-22 mb-0">Trading Preference</p>
        <p class="subtitle-2 txt-666">
          These segments are enabled for your account
          Your account has access to only these currently active segments.
        </p> -->
      </div>
      <v-divider></v-divider>
      <v-card class="py-md-6 mt-6 px-md-3 mb-6 pb-md-10" elevation="0"
        :outlined="$vuetify.breakpoint.smAndDown ? false : true" width="100%">
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <p class="subtitle-1 txt-666 font-weight-medium mb-0">Equities</p>
            <v-list-item v-for="folder in equty" :key="folder.COMPANY_CODE" class="pa-0">
              <v-list-item-content class="pa-0">
                <v-list-item-title style="font-size: 14px; font-weight: 599">{{ folder.COMPANY_CODE }}
                  <v-list-item-action>
                    <v-card v-if="folder.Exchange_ACTIVE_INACTIVE == 'A'" height="16" elevation="0" class="px-2 ml-1"
                      style="display: inline; float: right" color="#43A833">
                      <p class="pa-0 mb-0" style="
                          font-size: 9px;
                          color: white;
                          font-weight: 500;
                          margin-top: 4px;
                        ">
                        ACTIVE
                      </p>
                    </v-card>
                    <v-card v-else-if="folder.Exchange_ACTIVE_INACTIVE == 'I'" height="16" elevation="0"
                      class="px-2 ml-1" style="display: inline; float: right" color="#FF1717">
                      <p class="pa-0 mb-0" style="
                          font-size: 9px;
                          font-weight: 500;
                          color: white;
                          margin-top: 4px;
                        ">
                        INACTIVE
                      </p>
                    </v-card>
                    <v-card v-else height="16" elevation="0" class="px-2 ml-1" style="display: inline; float: right"
                      color="orange">
                      <p class="pa-0 mb-0" style="
                          font-size: 9px;
                          color: white;
                          font-weight: 500;
                          margin-top: 4px;
                        ">
                        Not open
                      </p>
                    </v-card>
                  </v-list-item-action>
                </v-list-item-title>
                <v-list-item-subtitle class="mt-n2" style="font-size: 10px">{{
        folder.REGISTRATION_DATE
      }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <!-- <p class="subtitle-1 txt-666 font-weight-medium mb-0 mt-8">
              Mutual funds
            </p>
            <v-list-item
              v-for="folder in mfdataarray"
              :key="folder.COMPANY_CODE"
              class="pa-0"
            >
              <v-list-item-content class="pa-0">
                <v-list-item-title style="font-size: 14px; font-weight: 599"
                  >{{ folder.COMPANY_CODE }}
                  <v-list-item-action>
                    <v-card
                      v-if="folder.Exchange_ACTIVE_INACTIVE == 'A'"
                      height="16"
                      elevation="0"
                      class="px-2 ml-1"
                      style="display: inline; float: right"
                      color="#43A833"
                    >
                      <p
                        class="pa-0 mb-0"
                        style="
                          font-size: 9px;
                          font-weight: 500;
                          color: white;
                          margin-top: 4px;
                        "
                      >
                        ACTIVE
                      </p>
                    </v-card>
                    <v-card
                      v-else-if="folder.Exchange_ACTIVE_INACTIVE == 'I'"
                      height="16"
                      elevation="0"
                      class="px-2 ml-1"
                      style="display: inline; float: right"
                      color="#FF1717"
                    >
                      <p
                        class="pa-0 mb-0"
                        style="
                          font-size: 9px;
                          color: white;
                          font-weight: 500;
                          margin-top: 4px;
                        "
                      >
                        INACTIVE
                      </p>
                    </v-card>
                    <v-card
                      v-else
                      height="16"
                      elevation="0"
                      class="px-2 ml-1"
                      style="display: inline; float: right"
                      color="orange"
                    >
                      <p
                        class="pa-0 mb-0"
                        style="
                          font-size: 9px;
                          font-weight: 500;
                          color: white;
                          margin-top: 4px;
                        "
                      >
                        Not open
                      </p>
                    </v-card>
                  </v-list-item-action>
                </v-list-item-title>
                <v-list-item-subtitle class="mt-n2" style="font-size: 10px">{{
                  folder.REGISTRATION_DATE
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item> -->
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <p class="subtitle-1 txt-666 font-weight-medium mb-0">F&O</p>
            <v-list-item v-for="folder in derivat" :key="folder.COMPANY_CODE" class="pa-0">
              <v-list-item-content class="pa-0">
                <v-list-item-title style="font-size: 14px; font-weight: 599">{{ folder.COMPANY_CODE }}
                  <v-list-item-action>
                    <v-card v-if="folder.Exchange_ACTIVE_INACTIVE == 'A'" height="16" elevation="0" class="px-2 ml-1"
                      style="display: inline; float: right" color="#43A833">
                      <p class="pa-0 mb-0" style="
                          font-size: 9px;
                          font-weight: 500;
                          color: white;
                          margin-top: 4px;
                        ">
                        ACTIVE
                      </p>
                    </v-card>
                    <v-card v-else-if="folder.Exchange_ACTIVE_INACTIVE == 'I'" height="16" elevation="0"
                      class="px-2 ml-1" style="display: inline; float: right" color="#FF1717">
                      <p class="pa-0 mb-0" style="
                          font-size: 9px;
                          font-weight: 500;
                          color: white;
                          margin-top: 4px;
                        ">
                        INACTIVE
                      </p>
                    </v-card>
                    <v-card v-else height="16" elevation="0" class="px-2 ml-1" style="display: inline; float: right"
                      color="orange">
                      <p class="pa-0 mb-0" style="
                          font-size: 9px;
                          font-weight: 500;
                          color: white;
                          margin-top: 4px;
                        ">
                        Not open
                      </p>
                    </v-card>
                  </v-list-item-action>
                </v-list-item-title>
                <v-list-item-subtitle class="mt-n2" style="font-size: 10px">{{
        folder.REGISTRATION_DATE
      }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <p class="subtitle-1 txt-666 font-weight-medium mb-0">Currency</p>
            <v-list-item v-for="folder in currency" :key="folder.COMPANY_CODE" class="pa-0">
              <v-list-item-content v-if="folder.COMPANY_CODE != 'CD_MCX'" class="pa-0">
                <v-list-item-title style="font-size: 14px; font-weight: 599">{{ folder.COMPANY_CODE }}
                  <v-list-item-action>
                    <v-card v-if="folder.Exchange_ACTIVE_INACTIVE == 'A'" height="16" elevation="0" class="px-2 ml-1"
                      style="display: inline; float: right" color="#43A833">
                      <p class="pa-0 mb-0" style="
                          font-size: 9px;
                          color: white;
                          font-weight: 500;
                          margin-top: 4px;
                        ">
                        ACTIVE
                      </p>
                    </v-card>
                    <v-card v-else-if="folder.Exchange_ACTIVE_INACTIVE == 'I'" height="16" elevation="0"
                      class="px-2 ml-1" style="display: inline; float: right" color="#FF1717">
                      <p class="pa-0 mb-0" style="
                          font-size: 9px;
                          color: white;
                          font-weight: 500;
                          margin-top: 4px;
                        ">
                        INACTIVE
                      </p>
                    </v-card>
                    <v-card v-else height="16" elevation="0" class="px-2 ml-1" style="display: inline; float: right"
                      color="orange">
                      <p class="pa-0 mb-0" style="
                          font-size: 9px;
                          color: white;
                          margin-top: 4px;
                          font-weight: 500;
                        ">
                        Not open
                      </p>
                    </v-card>
                  </v-list-item-action>
                </v-list-item-title>
                <v-list-item-subtitle class="mt-n2" style="font-size: 10px">{{
        folder.REGISTRATION_DATE
      }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <p class="subtitle-1 txt-666 font-weight-medium mb-0">
              Commodities
            </p>
            <v-list-item v-for="folder in commoditi" :key="folder.COMPANY_CODE" class="pa-0">
              <v-list-item-content v-if="folder.COMPANY_CODE != 'ICEX'" class="pa-0">
                <v-list-item-title style="font-size: 14px; font-weight: 599">{{ folder.COMPANY_CODE }}
                  <v-list-item-action>
                    <v-card v-if="folder.Exchange_ACTIVE_INACTIVE == 'A'" height="16" elevation="0" class="px-2 ml-1"
                      style="display: inline; float: right" color="#43A833">
                      <p class="pa-0 mb-0" style="
                          font-size: 9px;
                          font-weight: 500;
                          color: white;
                          margin-top: 4px;
                        ">
                        ACTIVE
                      </p>
                    </v-card>
                    <v-card v-else-if="folder.Exchange_ACTIVE_INACTIVE == 'I'" height="16" elevation="0"
                      class="px-2 ml-1" style="display: inline; float: right" color="#FF1717">
                      <p class="pa-0 mb-0" style="
                          font-size: 9px;
                          color: white;
                          font-weight: 500;
                          margin-top: 4px;
                        ">
                        INACTIVE
                      </p>
                    </v-card>
                    <v-card v-else height="16" elevation="0" class="px-2 ml-1" style="display: inline; float: right"
                      color="orange">
                      <p class="pa-0 mb-0" style="
                          font-size: 9px;
                          font-weight: 500;
                          color: white;
                          margin-top: 4px;
                        ">
                        Not open
                      </p>
                    </v-card>
                  </v-list-item-action>
                </v-list-item-title>
                <v-list-item-subtitle class="mt-n2" style="font-size: 10px">{{
        folder.REGISTRATION_DATE
      }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <!-- <v-col cols="12" sm="6" md="3">
                    <p class="subtitle-1 txt-666 font-weight-medium mb-0">
                      Mutual funds
                    </p>
                    <v-list-item
                      v-for="folder in mfdataarray"
                      :key="folder.COMPANY_CODE"
                      class="pa-0"
                    >
                      <v-list-item-content class="pa-0">
                        <v-list-item-title 
                          style="font-size: 14px; font-weight: 599"
                          >{{ folder.COMPANY_CODE }}
                          <v-list-item-action>
                            <v-card
                              v-if="folder.Exchange_ACTIVE_INACTIVE == 'A'"
                              height="16"
                              elevation="0"
                              class="px-2 ml-1"
                              style="display: inline; float: right"
                              color="#43A833"
                            >
                              <p
                                class="pa-0 mb-0"
                                style="
                                  font-size: 9px;font-weight: 500;
                                  color: white;
                                  margin-top: 4px;
                                "
                              >
                                ACTIVE
                              </p>
                            </v-card>
                            <v-card
                              v-else-if="folder.Exchange_ACTIVE_INACTIVE == 'I'"
                              height="16"
                              elevation="0"
                              class="px-2 ml-1"
                              style="display: inline; float: right"
                              color="#FF1717"
                            >
                              <p
                                class="pa-0 mb-0"
                                style="
                                  font-size: 9px;
                                  color: white;font-weight: 500;
                                  margin-top: 4px;
                                "
                              >
                                INACTIVE
                              </p>
                            </v-card>
                            <v-card
                              v-else
                              height="16"
                              elevation="0"
                              class="px-2 ml-1"
                              style="display: inline; float: right"
                              color="orange"
                            >
                              <p
                                class="pa-0 mb-0"
                                style="
                                  font-size: 9px;font-weight: 500;
                                  color: white;
                                  margin-top: 4px;
                                "
                              >
                                Not open
                              </p>
                            </v-card>
                          </v-list-item-action>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          class="mt-n2"
                          style="font-size: 10px"
                          >{{ folder.REGISTRATION_DATE }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-col> -->
        </v-row>

        <v-card rounded="lg" v-if="mobStatus.segment_status == 'e-signed pending'" color="#FCEFD4" elevation="0"
          class="mt-4">
          <div class="px-2 py-1">
            <p style="
                font-size: 10.5px;
                font-weight: 459;
                margin: 0;
                display: inline;
                color: #666666;
              ">
              Your Segment Change request is not yet Completed,<span style="color: #0037b7; cursor: pointer" @click="
        digioEsign(
          mobStatus.segment_file_id,
          mobStatus.segment_client_email.toLowerCase(),
          mobStatus.segment_session
        ),
        (editType = 'segment_change')
        ">
                Click here esign</span>
              <span @click="
        (editType = 'segment_change'),
        (fileide = mobStatus.segment_file_id),
        (confirmation = true)
        " class="mt-1 mr-2" style="color: red; cursor: pointer; float: right">Cancel request</span>
            </p>
          </div>
        </v-card>
        <v-card rounded="lg" v-if="mobStatus.segment_status == 'e-signed completed'" color="#FCEFD4" elevation="0"
          class="">
          <div class="px-2 py-1">
            <p style="
                font-size: 10.5px;
                font-weight: 459;
                margin: 0;
                display: inline;
                color: #666666;
              ">
              Your Segment Change request is in process
              <!-- <span style="color: #0037B7;cursor: pointer;"> Click here esign</span> -->
            </p>
          </div>
        </v-card>
      </v-card>

      <div v-if="accountstatus != 'client_close'">
      <v-btn class="" v-if="mobStatus.segment_status != 'e-signed pending' &&
        mobStatus.segment_status != 'e-signed completed'
        
        " :disabled="accountstatus == 'client_close'" :block="$vuetify.breakpoint.xsOnly ? true : false" elevation="0" color="black white--text" rounded
        :loading="emailLoader" @click="mobileEdit('segment')">
        <span class="text-none px-8">+ Activate Segments</span>
      </v-btn></div>
      <div v-if="accountstatus == 'client_close'">
                  <v-tooltip color="black" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn disabled class="text-none"  elevation="0" color="black white--text" rounded>
                          + Activate Segments
                        </v-btn>
                      </span>
                    </template>
                    <span>Your trading account is closed</span>
                  </v-tooltip>

                </div>
      <!-- <v-btn
        v-if="
          mobStatus.segment_status != 'e-signed pending' &&
          mobStatus.segment_status != 'e-signed completed'
        "
        :block="$vuetify.breakpoint.xsOnly ? true : false"
        elevation="0"
        color="black white--text"
        rounded
        class=" "
        :loading="emailLoader"
        @click="deacticepoup()"
      >
        <span class="text-none px-8"> - DeActivate Segments</span>
      </v-btn> -->


      <!-- <div class="d-none  d-lg-flex d-xl-flex">
      <v-btn class=" mr-md-6"
        v-if="
          mobStatus.segment_status != 'e-signed pending' &&
          mobStatus.segment_status != 'e-signed completed'
        "
        :block="$vuetify.breakpoint.xsOnly ? true : false"
        elevation="0"
        color="black white--text"
        rounded
        :loading="emailLoader"
        @click="mobileEdit('segment')"
      >
        <span class="text-none px-8">+ Activate Segments</span>
      </v-btn>

      <v-btn
        v-if="
          mobStatus.segment_status != 'e-signed pending' &&
          mobStatus.segment_status != 'e-signed completed'
        "
        :block="$vuetify.breakpoint.xsOnly ? true : false"
        elevation="0"
        color="black white--text"
        rounded
        class=" "
        :loading="emailLoader"
        @click="deacticepoup()"
      >
        <span class="text-none px-8"> - DeActivate Segments</span>
      </v-btn>
    </div>

    <div class="d-block d-lg-none">
      <v-row>
        <v-col cols="12" sm="12">
          <v-btn class=" "
        v-if="
          mobStatus.segment_status != 'e-signed pending' &&
          mobStatus.segment_status != 'e-signed completed'
        "
        :block="$vuetify.breakpoint.xsOnly ? true : false"
        elevation="0"
        color="black white--text"
        rounded
        :loading="emailLoader"
        @click="mobileEdit('segment')"
      >
        <span class="text-none px-8">+ Activate Segments</span>
      </v-btn>
        </v-col>
        <v-col cols="12" sm="12">
          
      <v-btn
        v-if="
          mobStatus.segment_status != 'e-signed pending' &&
          mobStatus.segment_status != 'e-signed completed'
        "
        :block="$vuetify.breakpoint.xsOnly ? true : false"
        elevation="0"
        color="black white--text"
        rounded
        class=" mr-5"
        :loading="emailLoader"
        @click="deacticepoup()"
      >
        <span class="text-none px-8"> - DeActivate Segments</span>
      </v-btn>
        </v-col>
      </v-row>
     

    </div> -->

      <v-dialog persistent v-model="deactivepopu" width="500px">
        <v-card outlined elevation="0" class="rounded-lg">
          <v-card-title style="font-size: 18px;color:#000" class="ml-3 px-2"> Deactivating Segment Request?
            <v-spacer></v-spacer>
            <v-btn icon @click="deactivepopu = false, uncheckedFolders = ''">
              <v-icon>mdi-close</v-icon>
            </v-btn></v-card-title>
          <div class="pl-2 pr-2 pt-0 mt-0">
            <v-row no-gutters class=" pt-0 mt-0">
              <v-col v-for="category in categories" :key="category.title" cols="12" sm="6" md="3">
                <p class="subtitle-2 txt-666 font-weight-medium ml-3 mb-0">{{ category.title }}</p>
                <v-row no-gutters>
                  <v-col class="pa-2" v-for="folder in category.folders" :key="folder.COMPANY_CODE" cols="12">
                    <v-row align="center">
                      <v-col cols="12">
                        <div class="  d-flex justify-between align-center">
                          <!-- Make the chip clickable by wrapping it in a button -->
                          <button @click="toggleCheck(folder)" rounded class="rounded-lg" :style="{
        backgroundColor: !folder.checked ? '#F3EAEA' : '#fff',

        color: !folder.checked ? '#002E73' : '#000'
      }">
                            <v-chip small outlined :style="{
        backgroundColor: !folder.checked ? '#F3EAEA' : '#fff',
        border: !folder.checked ? '1px solid red' : '',

      }" v-if="folder.Exchange_ACTIVE_INACTIVE === 'A'">
                              <template #default>
                                <v-icon size="17" color="red" v-if="!folder.checked" left>mdi-close</v-icon>
                                <span :style="{

        color: !folder.checked ? 'red' : '#000'
      }" style="font-size: 12px; ">{{ folder.COMPANY_CODE }}</span>
                              </template>
                              <template #input>
                                <!-- Render the checkbox input -->
                                <input type="checkbox" :checked="folder.checked"
                                  @change="checkBoxChanged(folder, category.title)">
                              </template>
                            </v-chip>
                          </button>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <p class="mt-2  ml-5" style="font-size: 16px;color:grey">
            *Disable segments temporarily to take a break from trading
          </p>
          <div class="ml-4 mb-4 mr-4">
            <v-btn elevation="0" color="black white--text" block class="  text-none" @click="sendotpseg()" rounded
              :disabled="uncheckedFolders == ''" :loading="senotpload"><span class="">Sumbit</span></v-btn>
          </div>
        </v-card>

      </v-dialog>

      <v-dialog v-model="otpvrifysc" persistent max-width="380">
        <v-card class="rounded-lg px-2 px-md-2">
          <v-card-title class="pr-2"> Confirm OTP <v-spacer></v-spacer>
            <v-btn icon @click="otpvrifysc = false">
              <v-icon>mdi-close</v-icon>
            </v-btn></v-card-title>
          <v-card-text>
            Enter 4 digit OTP sent to mobile no. <span style="color:red">*</span>

          </v-card-text>
          <v-text-field class="pa-0 ml-7 mr-7" length="4" required type="number" hide-spin-buttons pattern="\d*"
            maxlength="4" :rules="otpRules" placeholder="00-00" v-model="verifyotpp">
            <template #prepend-inner>
              <div style="width: 30px">
                <v-img class="mt-1" max-height="16" max-width="16" src="@/assets/mobile-chat.svg" />
              </div>
            </template>
          </v-text-field>
          <!-- <v-otp-input
  length="4"
  type="number"
  v-model="verifyotpp"
  dense
  small
></v-otp-input> -->
          <p style="color:red">{{ errorwrongtext ? errorwrongtext : '' }} </p>
          <v-card-actions class="pb-4">
            <!-- <v-btn
                height="40px"
                block
                color="red darken-1 text-none"
                text
                @click="deleteBankapi"
              >
                Delete
              </v-btn> -->
            <v-btn :disabled="verifyotpp.length != 4" elevation="0" color="black white--text" rounded block
              :loading="verifyotload" @click="otpverifun()">
              <span class="text-none">Proceed</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>




    </div>
    <v-dialog v-model="confirmation" persistent max-width="380">
      <v-card class="rounded-lg px-2 px-md-2">
        <v-card-title class="pr-2">Cancel request? <v-spacer></v-spacer>
          <v-btn icon @click="confirmation = false">
            <v-icon>mdi-close</v-icon>
          </v-btn></v-card-title>
        <v-card-text>
          Are you sure you want to cancel your
          <b>"{{ this.editType }}"</b> request?
        </v-card-text>
        <v-card-actions class="pb-4">
          <!-- <v-btn
                height="40px"
                block
                color="red darken-1 text-none"
                text
                @click="deleteBankapi"
              >
                Delete
              </v-btn> -->
          <v-btn elevation="0" color="black white--text" rounded block @click="rejectEsign">
            <span class="text-none">Proceed</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="nosegmentsPop" max-width="380">
      <v-card class="rounded-lg px-2 px-md-2">
        <v-card-title class="pr-2">
          No active segments found? <v-spacer></v-spacer>
          <v-btn icon @click="nosegmentsPop = false">
            <v-icon>mdi-close</v-icon>
          </v-btn></v-card-title>
        <v-card-text>
          You have in-active segments in your account
          <b>"{{ profileData.CLIENT_ID }}"</b> <br /></v-card-text>
        <v-card-actions class="pb-0">
          <v-btn class="mb-4" height="40px" elevation="0" color="black white--text" :disabled="activateSegBtn" rounded
            block :loading="bankLoader" @click="redirectSeg">
            <span class="text-none px-8">Re-KYC</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pannamenotfetch" persistent  max-width="380">
      <v-card class="rounded-lg  px-md-2 pa-4">
        <v-card-title class="">
        Pan Name Mismatch <v-spacer></v-spacer>
          <v-btn icon @click="pannamenotfetch = false">
            <v-icon>mdi-close</v-icon>
          </v-btn></v-card-title>
        <p class="font-weight-bold ml-4 mr-4">
          PAN name doesn't match with the data registered in Zebu. To fix this, update your PAN through Re-KYC as per SEBI norms <br>
          <b>"Registered Name : "{{ profileData.Pan_Name }}""</b> <br /></p>

          <v-card-actions class="pb-0">
          <v-btn class="mb-4" height="40px" elevation="0" color="black white--text" :disabled="activateSegBtn" rounded
            block :loading="bankLoader" @click="redirectSeg">
            <span class="text-none px-8">Re-KYC</span>
          </v-btn>
        </v-card-actions>
        
      </v-card>
    </v-dialog>
    <v-overlay :value="blurLoader" class="rounded-0" style="z-index: 2000">
      <div class="text-center">
        <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
      </div>
    </v-overlay>
    <v-dialog v-model="editdialog" :transition="$vuetify.breakpoint.xsOnly
          ? 'dialog-bottom-transition'
          : 'scale-transition'
        " persistent :fullscreen="$vuetify.breakpoint.xsOnly" :max-width="addNewSeg ? '500' : '420'" min-width="390">
      <v-card class="pb-4 rounded-lg px-md-4">
        <v-snackbar style="z-index: 9999" class="pt-6 pr-md-6 snakbar-sty rounded-pill" elevation="0" text-color="white"
          transition="slide-x-reverse-transition" v-model="errorSnackbar" :timeout="4000" :value="true" color="error">
          <v-icon class="mr-2" color="white">mdi-alert-outline</v-icon>
          {{ errtext }}
          <v-icon @click="errorSnackbar = false" class="float-right" color="white">mdi-close-circle</v-icon>
        </v-snackbar>
        <div v-if="segments">
          <v-card-title style="font-size: 16px" class="px-2">
            Segment Change Request <v-spacer></v-spacer>
            <v-btn icon @click="closeBtn">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <!-- {{ bsecash }} {{ bsefno }} {{ cdnse }} {{ bsecom }} {{Segmentssies}} -->
          <v-form v-if="addNewSeg" ref="formsegment">
            <!-- <p class="mb-0 px-2" style="font-size: 14px;font-weight: 499" >Add new Segments</p> -->
            <!-- {{ bsecash }} {{ bsefno }} {{ debt }} {{ cdnse }} {{ bsecom }} -->
            <v-chip-group v-model="Segmentssies" active-class="primary--text" column multiple @change="segmentSelect3">
              <v-chip filter outlined v-model="bsecash" :disabled="bsecashDisable">
                Equity
              </v-chip>
              <v-chip filter outlined v-model="bsefno" :disabled="bsefnoDisable">
                F&O
              </v-chip>

              <v-chip filter outlined v-model="cdnse" :disabled="cdnseDisable">
                Currency
              </v-chip>
              <v-chip filter outlined v-model="bsecom" :disabled="bsecomDisable">
                Commodity
              </v-chip>
              <!-- <v-chip filter outlined v-model="mfbse" :disabled="mfdisabled">
                Mutual Funds
              </v-chip> -->
            </v-chip-group>
            <p class="mt-2" style="font-size: small">
              As per the latest guidelines by SEBI & Exchange, you need to
              upload any one of the below mentioned document to trade in
              Derivatives.
            </p>
            <v-row v-if="bankproffdocument">
              <v-col>
                <v-card color="rgba(246, 247, 247, 0.5)" @ondragover="onChange"
                  :class="dragging ? 'dropAreaDragging' : ''" @dragenter="dragging = true" @dragend="dragging = false"
                  @dragleave="dragging = false" class="fileupload elevation-0 rounded-lg text-center pa-2"
                  style="border: 1px dashed #d0d3d6">
                  <div class="innerfilup">
                    <p class="font-weight-bold fs-18 mb-1" style="color: #0037b7 !important">
                      Click here to upload
                    </p>
                    <p class="mb-0 txt-666 font-weight-regular" style="line-height: 18px">
                      You can drop your Income proof here to be uploaded
                    </p>
                  </div>
                  <input type="file" id="items" accept=".pdf" name="items[]" required multiple @change="onChange" />

                  <div v-if="panFile">
                    <p class="mb-0 mt-6">
                      <span class="txt-666 mb-0">File names</span> :
                      <b>{{ panimagedata.name }}</b>
                    </p>
                    <div class="text-right mb-2"></div>
                  </div>
                </v-card>

                <div>
                  <p class="ma-0 mt-3 pa-0" style="font-weight: 499" v-if="passwordField">
                    Password <span style="color: red">*</span>
                  </p>
                  <v-text-field hide-details v-if="passwordField" :rules="nomineeField" v-model="pdfPassword"
                    :append-icon="passwordeye ? 'mdi-eye' : 'mdi-eye-off'" :type="passwordeye ? 'text' : 'password'"
                    placeholder="PDF password" class="input-group--focused foutninput ma-0 pt-0"
                    @click:append="passwordeye = !passwordeye"></v-text-field>
                  <p class="incorrect" v-if="incorrectPass" :class="passwordField == true ? 'py-0' : ''">
                    Incorrect Password
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-card-actions class="pb-0 mt-2">
              <v-btn height="40px" elevation="0" color="black white--text" :disabled="activateSegBtn" rounded block
                :loading="bankLoader" @click="proceedtoSeg">
                <span class="text-none px-8">Submit</span>
              </v-btn>
            </v-card-actions>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script src="https://app.digio.in/sdk/v10/digio.js"></script>

<script>
const script = document.createElement("script");
script.src = "https://app.digio.in/sdk/v10/digio.js";
document.body.appendChild(script);
import api from "@/Api.js";

import axios from "axios";
export default {
  // name: "BarChart",
  components: {},
  data() {
    return {
      deactivepopu:false,
      selectedFolders: [],
      otpvrifysc:false,
      verifyotpp:'',
      errorwrongtext:'',
      // =================================================
      xsOnly: null,
      smAndDown: null,
      txt: "",
      accountNum: "",
      upiDisabled: false,
      verifyBtn: true,
      upiLoader: false,
      errtext: "",
      errorSnackbar: false,
      snackbar: false,
      checkClientVPA: [],
      verfy: true,
      // items: [],
      isHidden: true,
      client_code: null,
      name: null,
      email: null,
      mobile: null,
      acc_no: null,
      ifsc: null,
      loading: false,
      selection: 0,
      url_data: null,
      oid: null,
      amount: null,
      valid: true,
      vpa: null,
      company: null,
      company_code: [],
      amountRules: [
        (v) => !!v || "Enter Amount",
        (v) => (v && v >= 10) || "Should be above 10",
      ],
      upiRules: [
        (v) => !!v || "Enter UPI ID",
        (v) => /.+@.+/.test(v) || "Invalid UPI ID",
      ],
      submit_this_val: null,
      bankDetails: [],
      // snackbar: false,
      clietData: "",
      togg: false,
      selectionAtom: 0,
      profileData: [],
      colatral: "",
      message: "Hai da Goopal",
      secretKey: "asdfghjklzxcvbnm",
      dataToEncrypt: "Sensitive information you want to encrypt.",
      token: "",
      publicIP: "",
      statusChk: [],
      Succtext: "",
      successSnackbar: false,
      profileLoader: true,
      BankData: [],
      submitBtndisable: true,
      equty: [],
      commoditi: [],
      mfdataarray: [],
      derivat: [],
      editdialog: false,

      //asdkjbnasdl
      chha: false,
      nifity: true,
      widthdat: [50, 50, 50, 50, 80],
      chip1: true,
      chip2: true,
      chip3: true,
      chip4: true,
      linkUrl: "#",
      zebu: "https://zebuetrade.com/",
      toggleMenu: true,
      drawer: false,
      selectedItem: 0,
      tab: 0,
      mobileNumber: "",
      cardTitle: "",
      dependency: ["Self", "Spouse", "child Dependent", "parents Dependent"],
      dependencyValue: "Self",
      number: true,
      otpvalid: "",
      otpValue: "",
      dependentName: "",
      headerProps: {
        class: "custom-header",
      },
      subTitle: "",
      mobileVerification: false,
      emailVerification: false,
      emailid: "",
      emailvalid: true,
      cardLoader: false,
      addressVerification: false,
      newNomineepercent: "",
      newNomineeAddress: "",
      newNomineeDob: "",
      newNomineeRelation: "",
      newNomineeName: "",
      nomineePop: false,
      personalDetail: false,
      activePicker: null,
      date: null,
      datee: null,
      datento: null,
      dateento: null,
      datenthre: null,
      dateenthre: null,

      menuu: false,
      menunto: false,
      menuunto: false,

      menunthre: false,
      menuunthre: false,
      menu: false,
      newNomineeProof: "",
      relationNominee: [
        "Mother",
        "Father",
        "Brother",
        "Sister",
        "Daughter",
        "Wife",
        "Husband",
        "Son",
        "Grand-Son",
        "Grand-Father",
        "Grand-Daughter",
        "Grand-Mother",
      ],
      newNomineeCity: "",
      newNomineePincode: "",
      newNomineeState: "",
      newNomineeCountry: "",
      pinLoader: false,
      nomineeForm: false,
      yesorno: "no",
      nomineeYes: false,
      nomineeMobile: "",
      nomineeEmail: "",
      checkbox: true,
      newNomineePan: "",

      nomineeMobilento: "",
      nomineeEmailnto: "",
      checkboxnto: true,
      newNomineePannto: "",

      nomineeMobilenthre: "",
      nomineeEmailnthre: "",
      checkboxnthre: true,
      newNomineePannthre: "",
      newNomineeNamenthre: "",
      newNomineeRelationnthre: "",

      optionOfupdate: "",
      radioBtn: false,
      nomineesubmitBtn: false,
      submissionText: "",
      filedegio: "",
      esignBtn: false,
      newNomineeGuardian: "",
      newNomineeGuardiannto: "",
      newNomineeGuardiannthre: "",

      belowAge: false,
      belowAgento: false,
      belowAgenthre: false,

      newNomineeGuardianRelation: "",
      newNomineeGuardianRelationnto: "",
      newNomineeGuardianRelationnthre: "",
      newNomineegurdianmobile: "",
      newNomineegurdianmobilento: "",
      newNomineegurdianmobilenthre: "",

      checkboxTow: false,
      checkboxThree: false,

      newNomineeNameto: "",
      newNomineeRelationto: "",

      newNomineepercentone: 100,
      newNomineepercentnto: 1,
      newNomineepercentnthre: 1,
      max: 100,
      secnompercentmax: 0,
      thernompercentmax: 0,
      hashpan: "",

      newNomineeGuardianEmail: "",
      newNomineeGuardianAddress: "",
      newNomineeGuardianPincode: "",
      newNomineeGuardianState: "",
      newNomineeGuardianCity: "",
      newNomineeGuardianCountry: "",

      newNomineeGuardianEmailnto: "",
      newNomineeGuardianAddressnto: "",
      newNomineeGuardianPincodento: "",
      newNomineeGuardianStatento: "",
      newNomineeGuardianCitynto: "",
      newNomineeGuardianCountrynto: "",

      newNomineeGuardianEmailnthre: "",
      newNomineeGuardianAddressnthre: "",
      newNomineeGuardianPincodenthre: "",
      newNomineeGuardianStatenthre: "",
      newNomineeGuardianCitynthre: "",
      newNomineeGuardianCountrynthre: "",

      nominee2: false,
      newNomineeName2: "",
      newNomineeRelation2: "",
      newNomineeDob2: "",
      newNomineepercent2: "",

      nominee3: false,
      newNomineeName3: "",
      newNomineeRelation3: "",
      newNomineeDob3: "",
      newNomineepercent3: "",

      usernewmobile: "",
      newmobilerules: [
        (v) => !v || v.length <= 10 || "Enter a valid mobile number",
        (v) => !!v || "Mobile is required",
      ],
      requestotp: true,
      verifyotp: false,
      userOtp: "",
      editType: "",
      mobStatus: [],
      mobileDisable: false,
      usernewemail: "",
      requestotpemail: false,
      useremailOtp: "",
      verifyotpemail: false,
      usernewAdress: "",
      adresschange: false,
      proofTypes: "",
      prooftype: ["Aadhar card", "Passport", "VoterID", "Driving licence"],
      emailDisable: false,
      emailRules: [
        (v) => !/^\s/.test(v) || "E-mail cannot start with spaces",
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
        (v) => !/\s/.test(v) || "Email cannot contain spaces",
      ],
      addressRules: [
        (v) => !!v || "Address is required",
        (v) => !/^\s/.test(v) || "Address cannot start with spaces",
      ],
      IfscRules: [
        (v) => !!v || "IFSC is required",
        (v) => /^\w{11}$/.test(v) || "Must be exactly 11 digits",
      ],

      adressproffdoc: null,
      manual: false,
      addressDisable: false,
      blurLoader: false,
      emailLoader: false,
      addressLoader: false,
      mobileLoader: false,
      userEmailadress: "",
      stateAddress: "",
      countryAddress: "",
      distAddress: "",
      pincodeAddress: "",

      // BANK INIT
      bankproffdoc: null,
      bankAccountnumber: "",
      bankIfscCode: "",
      bankchange: false,

      ifscBankInfo: [],
      // setDefault:'',
      existingBanks: [],
      defaultCheckbox: false,
      confirmation: false,
      deletebank: [],
      bankLoader: false,
      annualIncomechange: false,
      selectionas: null,
      proofField: false,
      incorrectPass: false,
      passwordField: false,
      typesOfimg: [
        "Latest 6 months Bank Statement",
        "Latest ITR Copy",
        "Latest 6 months salary slip",
        "DP holding statement as on date",
        "Net worth Certificate",
        "Copy of Form 16 in case of salary income",
      ],
      imgType: null,
      typeProof: "",
      pdfPassword: "",
      passwordeye: false,
      paswordverification: true,
      annualuserOtp: "",
      annualIncomeverifyotp: false,
      items: ["Below 1L", "1L to 5L", "5L to 10L", "10L to 25L", "Above 25L"],
      itemSelected: "",
      yearlyIncomeDisable: false,
      segments: false,
      nsecash: false,
      bsecash: false,
      mfbse: false,
      bsefno: false,
      nsefno: false,
      cdnse: false,
      cdbse: false,
      // cdbse:false,
      bsecom: false,
      nsecom: false,
      icex: false,
      mcx: false,
      segOpt: false,
      addNewSeg: false,
      activateSeg: false,
      tok: "",

      nsecashDisable: false,
      bsecashDisable: false,
      mfDisable: false,

      bsefnoDisable: false,
      nsefnoDisable: false,
      cdnseDisable: false,
      cdbseDisable: false,

      bsecomDisable: false,
      mfdisabled: false,
      nsecomDisable: false,
      icexDisable: false,
      mcxDisable: false,

      activateSegBtn: false,
      addsegtype: "",
      newSegements: [],
      segmentbankproffdoc: null,
      inactiveSegments: [],
      existingSegments: [],
      allSegments: [
        "BSE_CASH",
        "MF_BSE",
        "NSE_CASH",
        "BSE_FNO",
        "NSE_FNO",
        "CD_NSE",
        "CD_BSE",
        "BSE_COM",
        "NSE_COM",
        // "ICEX",
        "MCX",
        // "CD_MCX",
      ],
      newSegments: [],
      newSegmentSelected: [],
      bankproffdocument: false,
      addsegtypeNew: "",
      mfnse: "",
      nseslbm: "",
      deactivateConfirmation: false,
      aadharstate: "",
      aadharcode: "",
      banklogo: "https://rekycbe.mynt.in/autho",
      reasons: [
        "High brokerage and charges",
        "Monthly maintenance charges",
        "Need better margins",
        "Problem with payment and withdrawal",
        "Need better order placement options",
        "App crashes",
        "Issues with back office portals",
        "Problem with customer service",
        "App is complicated to understand",
        "Faced losses",
        "No time to focus on trading",
      ],
      closureReason: "",
      deactivateForm: true,
      deactivateCanceled: false,
      itemsaaass: [
        { text: "My Account" },
        {
          text: "Profile Details",
          icon: "",
          link: "/accountpage",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Bank Accounts Linked",
          icon: "",
          link: "/bankaccount",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Set Auto Pay",
          icon: "",
          link: "/autopay",
          mdiicon: "mdi-chevron-right",
        },
        {
          text: "Margin against stocks",
          icon: "",
          link: "/noticationsettings",
          mdiicon: "mdi-chevron-right",
        },
        { text: "Manage Funds", link: "secufund" },
        {
          text: "Securities Funds ",
          icon: "",
          link: "managefund",
          mdiicon: "mdi-chevron-right",
        },
      ],
      actid: "",
      susertoken: "",
      noms: [
        { txt: "Yes, add nominee", val: "yes" },
        { txt: "No, i will do it later", val: "no" },
      ],
      bankprooftype: [
        { txt: "Saving Account", val: "Saving Account" },
        { txt: "Current Account", val: "Current Account" },
      ],
      bankproofTypes: "Saving Account",
      file: null,
      dragging: false,
      panimagedata: [],
      panimage: null,
      panFile: "",
      BankProofselect: "bs",
      customLabel: "Nominee address is same as my address",
      panel: 0,
      cardloader: false,
      dissBtn: true,
      dissBtn2: true,
      dissBtn3: true,
      activetab: "tab1",
      nosegmentsPop: false,
      newFamilyRelation: "",
      memberId: "",
      memberMobile: "",
      memberPan: "",
      validFamily: "",
      familyloader: false,
      familySubmission: false,
      endtxt: "",
      familyRequestData: "",
      familystatusloading: false,
      familyrequest: false,
      selectTableitem: [],
      mobileMember: "",
      disabled: false,
      readonly: false,
      tags: ["Equity", "F&O", "Debt", "Currency", "Commodity"],
      Segmentssies: [],
      allv: "",
      debt: "",
      debtDisable: false,
      fileide: "",
      currency: [],
      // ============================
      categories: [
        {
          title: 'Equities',
          folders: [],
        },
        {
          title: 'F&O',
          folders: [],
        },
        {
          title: 'Currency',
          folders: [],
        },
        {
          title: 'Commodities',
          folders: [],
        }
      ],
      selectedFolders: [],
      uncheckedFolders: [],
      senotpload:false,
      verifyotload:false,
      snackbarnew:false,
      snackcolor:'#000',
      mesg:'',
      accountstatus:"",
      pannamenotfetch:false,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menunto(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuunto(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menunthre(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menuunthre(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    toggleCheck(folder) {
      folder.checked = !folder.checked;
      this.checkBoxChanged(folder);
    },
    deacticepoup(){
      this.mobileEdit('segment')
     this.editdialog = false
     if(this.existingSegments.length == 0){
      this.nosegmentsPop = true;

     }else{
      this.deactivepopu = true
     }
 

    },
    checkBoxChanged(folder, categoryTitle) {
      // Update selected folders array
      if (folder.checked) {
        if (!this.selectedFolders.includes(folder.COMPANY_CODE)) {
          this.selectedFolders.push(folder.COMPANY_CODE);
        }
      } else {
        const index = this.selectedFolders.indexOf(folder.COMPANY_CODE);
        if (index !== -1) {
          this.selectedFolders.splice(index, 1);
        }
        // Add folder.COMPANY_CODE to uncheckedFolders
        if (!this.uncheckedFolders.includes(folder.COMPANY_CODE)) {
          this.uncheckedFolders.push(folder.COMPANY_CODE);
        }
      }

      // Update unchecked folders array
      this.uncheckedFolders = this.categories
        .flatMap(cat => cat.folders.filter(f => !f.checked && f.Exchange_ACTIVE_INACTIVE !== 'NOT OPEN' && f.Exchange_ACTIVE_INACTIVE !== 'I'))
        .map(f => f.COMPANY_CODE);

      // Print both arrays
      // console.log('Selected folders:', this.selectedFolders);
      // console.log('Unchecked folders:', this.uncheckedFolders);
    },
  
sendotpseg(){
  this.senotpload = true
  const axios = require('axios');
let data = JSON.stringify({
  client_id: this.profileData.CLIENT_ID,
});

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'http://192.168.5.182:5000/remove_segements_otp_req',
  headers: { 
    'Content-Type': 'application/json'
  },
  data : data
};
var axiosThis = this
axios.request(config)
.then((response) => {
axiosThis.senotpload = false

  // console.log(JSON.stringify(response.data));
  if(response.data.msg == 'otp sent'){
    axiosThis.deactivepopu = false

axiosThis.otpvrifysc = true
  }else{
    axiosThis.deactivepopu = false
  }

})
.catch((error) => {
  console.log(error);
axiosThis.senotpload = false
// console.log(error);
  axiosThis.refload = false
  

axiosThis.snackbarnew = true
axiosThis.snackcolor = 'error'
axiosThis.mesg = error

});

},
otpverifun(){
  this.verifyotload = true
  const axios = require('axios');
let data = JSON.stringify({
  client_id:this.profileData.CLIENT_ID,
  otp: this.verifyotpp
});

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'http://192.168.5.182:5000/remove_segements_otp_verify',
  headers: { 
    'Content-Type': 'application/json'
  },
  data : data
};
var axiosThis = this
axios.request(config)
.then((response) => {
  // console.log(JSON.stringify(response.data));
  axiosThis.verifyotload = false

if(response.data.stat == 'ok'){
  axiosThis.otpvrifysc = false
// axiosThis.otpvrifysc = false
axiosThis.deactivesegfinal()



}else if(response.data.msg == "otp and client id doesn't exsists" ){
  axiosThis.otpvrifysc = true
  axiosThis.verifyotpp = ''
  axiosThis.errorwrongtext = "Invaild OTP or Invaild Client ID"

}
})
.catch((error) => {
  console.log(error);
  axiosThis.otpvrifysc = false
axiosThis.verifyotload = false
axiosThis.snackbarnew = true
axiosThis.snackcolor = 'error'
axiosThis.mesg = error


});

},

deactivesegfinal(){
  this.blurLoader = true
  const axios = require('axios');
let data = JSON.stringify({
  client_id:this.profileData.CLIENT_ID,
  closing_segments: this.uncheckedFolders,
  exsisting_segments: this.existingSegments
});

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'http://192.168.5.182:5000/remove_segments',
  headers: { 
    'Content-Type': 'application/json'
  },
  data : data
};
var axiosThis = this
axios.request(config)
.then((response) => {
  // console.log(JSON.stringify(response.data));
  axiosThis.blurLoader = false

  if(response.data.stat == 'ok'){
    axiosThis.snackbarnew = true
axiosThis.snackcolor = 'sucess'
axiosThis.mesg = "Request Added"
axiosThis.uncheckedFolders = []
axiosThis.existingSegments = []
  }else{
    axiosThis.snackbarnew = true
axiosThis.snackcolor = 'error'
axiosThis.mesg = response.data
axiosThis.uncheckedFolders = []
axiosThis.existingSegments = []
  }
})
.catch((error) => {
  console.log(error);
  axiosThis.blurLoader = false
  axiosThis.snackbarnew = true
axiosThis.snackcolor = 'error'
axiosThis.mesg = error
axiosThis.uncheckedFolders = []
axiosThis.existingSegments = []

});

},






  
  
    rejectEsign() {
      let data = JSON.stringify({
        client_id: this.profileData.CLIENT_ID,
        file_id: this.fileide,
        type: this.editType,
      });

      let config = {
        method: "post",
        url: api.api_url + "/manual_cancel_request",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          this.confirmation = false;
          // console.log(JSON.stringify(response.data));
          if (response.data.msg == "canceled successfully") {
            this.Succtext = "Canceled successfully";
            this.successSnackbar = true;
            this.fetchMobileStatus();
            location.reload();
          } else {
            this.errtext = response.data.msg;
            this.errorSnackbar = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    samemembercheck() {
      if (this.memberId == this.client_code) {
        this.memberId = "";
        this.errorSnackbar = true;
        this.errtext = "Not allowed";
      }
    },

    onChange(e) {
      let file = e.target.files[0] || e.dataTransfer.files[0];
      // console.log(file["type"]);
      if (file["type"] == "application/pdf") {
        this.panFile = file;
        this.panimage = window.URL.createObjectURL(this.panFile);
        this.panimagedata["name"] = this.panFile.name;
        this.panimagedata["size"] = (this.panFile.size / 1048576).toFixed(1);
        this.passHide();
      } else {
        this.cardloader = false;
        this.errtext = "Only PDF format are supported.";
        this.errorSnackbar = true;
      }
    },
    segmentSelect() {
      // console.log("clicketr",this.newSegments,aa)
      // for(var l =0;l<this.newSegments.length;l++) {
      //   if(this.newSegments[l] == aa) {
      //     this.newSegmentSelected = "YES"
      //   } else {
      //     this.newSegmentSelected = "NO"
      //   }
      // }
      // this.newSegmentSelected = []
      if (this.bsecash == true) {
        this.newSegmentSelected.push("BSE_CASH");
      } else {
        const index = this.newSegmentSelected.indexOf("BSE_CASH");
        this.newSegmentSelected.splice(index, 1);
      }
    },

    segmentSelect3() {
      // console.log(this.Segmentssies,"newsegment",this.bsefno ,this.cdnse,this.bsecom )
      var ee = this.Segmentssies.includes(1);
      var aa = this.Segmentssies.includes(2);
      var ss = this.Segmentssies.includes(3);
      // console.log(ee,aa,ss,this.Segmentssies)
      if (ee || aa || ss) {
        this.bankproffdocument = true;
      } else {
        this.bankproffdocument = false;
      }
    },

    goSso() {
      // if( data)
      // window.location.assign(`http://192.168.5.152:8082/?url=${window.location.href}`)

      window.location.assign(
        `https://desk.mynt.in/?url=${window.location.href}`
      );
    },
    digioEsign(file, email, session) {
      var axo = this;
      this.blurLoader = true;
      axo.editdialog = false;
      // console.log(file, email,session)
      // localStorage.setItem('fileid', this.digioFile)
      var options = {
        environment: "production",
        callback: function (t) {
          // axo.statusCheckNominee(axo.client_code);
          // console.log(t);
          // console.log(axo.stata);
          axo.blurLoader = false;

          if (axo.stata != undefined) {
            if (axo.stata.app_status == "e-signed pending") {
              axo.radioBtn = true;
              axo.nomineesubmitBtn = true;
            } else {
              axo.radioBtn = false;
              axo.nomineesubmitBtn = false;
            }
          } else {
            axo.radioBtn = false;
            axo.nomineesubmitBtn = false;
          }

          if (t.message == "Signing cancelled") {
            // if(axo.editType == 'mobile_change') {
            axo.filedownloadDatabase("failure", file);
            axo.fetchMobileStatus();

            // }
            // if(axo.editType == 'email_change') {
            // axo.filedownloadDatabase("failure", file);
            // axo.fetchMobileStatus()

            // }
            // if(axo.editType == 'address_change') {
            // axo.filedownloadDatabase("failure", file);
            // axo.fetchMobileStatus()
            // }
          } else if (t.message == "Signed Successfully") {
            // axo.filedownloadDatabase("success", axo.filedegio?axo.filedegio:axo.stata.file_id);
            // if(axo.editType == 'mobile_change') {
            axo.filedownloadDatabase("success", file);
            // }
            // if(axo.editType == 'email_change') {
            // axo.filedownloadDatabase("success", file);
            // }
            // if(axo.editType == 'address_change') {
            // axo.filedownloadDatabase("success", file);
            // }
          }

          // if (t["hasOwnProperty"]("error_code")) {
          //   // document.getElementById("loading").style.display='none';
          //   //// console.log('Error Digio')
          //   //// console.log(t.message)
          // } else {
          //   //document.getElementById("result").innerHTML="API Mandate Authentication Successful"
          //   //successDigio();
          //   //// console.log(this.step, this.ekycStatus);
          //   // setTimeout(function () {
          //   //   //code goes here
          //   //   window.location.reload();
          //   // }, 4500);
          // }
        },
      };

      var digio = new Digio(options);
      digio.init(); // Call init on user action (eg.button press and proceed to asynchronous upload)
      // digio.submit(this.digioFile, this.digioMail,);
      // console.log(file,email,session)
      digio.submit(file, email, session);
      // digio.submit("DID230911100935062BG8PAZ3INK43XM", "mageshjack002@gmail.com","GWT2309111009374289K3NVXB6RHUEDO");

      // console.log(digio)
    },
    filedownloadDatabase(stat, fileid) {
      let data = JSON.stringify({
        client_id: this.client_code,
        file_id: fileid,
        response: stat,
        type: this.editType,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        // url: 'http://192.168.5.121:5555/filedownload',

        url: api.api_url + "/filedownload",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "application/json",
        },
        data: data,
      };
      let axiosThis = this;

      axios
        .request(config)
        .then((response) => {
          if (response.data.emsg == "token expired") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Expired";
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso();
              window.location.reload();
            }, 2000);
          }
          if (response.data.emsg == "invalid token") {
            axiosThis.snackbar = true;
            axiosThis.txt = "Invalid";
            // console.warn("Hs")
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(function () {
              // axiosThis.$router.push("/");
              axiosThis.goSso();

              window.location.reload();
            }, 2000);
          }
          window.location.reload();

          // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    proceedtoSeg() {
      if (this.passwordField) {
        var valid = this.$refs.formsegment.validate();
        // console.log("validate",valid)
        if (valid == true) {
          this.paswordverificationBtn();
        }
      } else {
        this.segmentChange("NO");
      }
    },
    segmentChange(pass_req) {
      this.$refs.formsegment.validate();
      var axiosThis = this;

      if (this.bsecash) {
        this.newSegmentSelected.push("NSE_CASH");
        this.newSegmentSelected.push("BSE_CASH");
      }
      if (this.mfbse) {
        this.newSegmentSelected.push("MF_BSE");
        this.newSegmentSelected.push("MF_NSE");
      }
      if (this.bsefno) {
        this.newSegmentSelected.push("NSE_FNO");
        this.newSegmentSelected.push("BSE_FNO");
      }
      if (this.cdnse) {
        this.newSegmentSelected.push("CD_NSE");
        this.newSegmentSelected.push("CD_BSE");
        // this.newSegmentSelected.push("CD_MCX");
      }
      if (this.bsecom) {
        this.newSegmentSelected.push("NSE_COM");
        this.newSegmentSelected.push("BSE_COM");
        // this.newSegmentSelected.push("ICEX");
        this.newSegmentSelected.push("MCX");
      }
      // console.log("newSegmentSelected", this.newSegmentSelected);
      // console.log("ddsd========sdsd",this.bankproffdocument,this.newSegments,this.existingSegments)
      if (this.bankproffdocument == true && this.panFile) {
        //   if(this.bsecash) {
        //   this.nseEquity = true
        //   this.bseEquity  = true
        // }
        // if (this.bsefno) {
        //   this.bseFnoseg = true
        // }
        this.bankLoader = true;

        // for (var z = 0; z < this.allSegments.length; z++) {
        //   // console.log("cdcd", this.newSegmentSelected[z]);
        //   for (var x = 0; x < this.allSegments.length; x++) {
        //     // console.log(
        //     //   "---",
        //     //   this.newSegmentSelected[z],
        //     //   this.inactiveSegments[x],
        //     //   this.newSegmentSelected[x]
        //     // );

        //     if (
        //       this.newSegmentSelected[z] != undefined &&
        //       this.newSegmentSelected[x] != undefined
        //     ) {
        //       // console.log("sss",this.newSegmentSelected[z], this.inactiveSegments[x], this.newSegments[x]);
        //       if (this.newSegmentSelected[z] == this.inactiveSegments[x]) {
        //         this.addsegtype = "YES";
        //         // console.log("sder 10", this.addsegtype);
        //       } else {
        //         // console.log("sder 1", this.addsegtype);
        //       }
        //       if (this.newSegmentSelected[z] == this.newSegments[x]) {
        //         this.addsegtypeNew = "YES";
        //         // console.log("sder 20", this.addsegtypeNew);
        //       }else {
        //         // console.log("sder 2", this.addsegtypeNew);
        //       }
        //     }
        //   }
        // }
        let addsegtype;
        let addsegtypeNew;
        // console.log('this.newSegments one',this.newSegmentSelected, this.newSegments, this.inactiveSegments);
        for (var z = 0; z < this.newSegmentSelected.length; z++) {
          addsegtype = this.inactiveSegments.indexOf(
            this.newSegmentSelected[z]
          );
          addsegtypeNew = this.newSegments.indexOf(this.newSegmentSelected[z]);
          if (
            addsegtype >= 0 &&
            this.newSegmentSelected[z] == this.inactiveSegments[addsegtype]
          ) {
            this.addsegtype = "YES";
          }
          if (
            addsegtypeNew >= 0 &&
            this.newSegmentSelected[z] == this.newSegments[addsegtypeNew]
          ) {
            this.addsegtypeNew = "YES";
          }
        }
        //
        // const FormData = require("form-data");

        const FormData = require("form-data");
        let data = new FormData();

        data.append("new_segments", JSON.stringify(this.newSegmentSelected));
        data.append("ext_segments", JSON.stringify(this.existingSegments));

        data.append("dp_code", this.profileData.CLIENT_DP_CODE);
        data.append(
          "adding_segments",
          this.newSegmentSelected.length > 0
            ? this.addsegtypeNew
              ? this.addsegtypeNew
              : "NO"
            : "NO"
        );
        data.append(
          "re_active_segments",
          this.addsegtype ? this.addsegtype : "NO"
        );

        data.append("client_id", this.profileData.CLIENT_ID);
        data.append("nse_equity", this.bsecash ? "YES" : "NO");
        data.append("bse_equity", this.bsecash ? "YES" : "NO");
        data.append("nse_equity_der", this.bsefno ? "YES" : "NO");
        data.append("bse_equity_der", this.bsefno ? "YES" : "NO");
        data.append("nse_currency_der", this.cdnse ? "YES" : "NO");
        data.append("bse_currency_der", this.cdnse ? "YES" : "NO");
        data.append("nse_commodity_der", this.bsecom ? "YES" : "NO");
        data.append("bse_commodity_der", this.bsecom ? "YES" : "NO");
        data.append("mcx_commodity_der", this.bsecom ? "YES" : "NO");
        data.append("icex_commodity_der", this.bsecom ? "YES" : "NO");
        data.append("nse_mfss", this.mfbse ? "YES" : "NO");
        data.append("bse_mfss", this.mfbse ? "YES" : "NO");
        data.append("nse_slbm", this.nseslbm ? "YES" : "NO");
        data.append("client_email", this.profileData.CLIENT_ID_MAIL);
        data.append("client_name", this.profileData.CLIENT_NAME);
        data.append("proff", this.panFile != null ? this.panFile : "");
        data.append("address", this.profileData.CL_RESI_ADD1);

        data.append("password_required", pass_req);
        data.append("password", this.pdfPassword);

        let config = {
          method: "post",
          url: api.api_url + "/add_segment",
          // url: "http://192.168.5.132:5000/add_segment",

          headers: {
            Authorization: this.tok,
            clientid: this.profileData.CLIENT_ID,
            "Content-Type": "multipart/form-data",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            axiosThis.bankLoader = false;

            // console.log(JSON.stringify(response.data));
            if (response.data.fileid && response.data.mailid) {
              axiosThis.digioEsign(
                response.data.fileid,
                response.data.mailid,
                response.data.session
              );
            }else if(response.data.msg == "pan name miss match so redirect to ekyc"){
              axiosThis.editdialog = false
              axiosThis.pannamenotfetch = true
    
            } 
            else {
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = "Error in Server, please try again later";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (this.newSegmentSelected[0] && !this.bankproffdocument) {
        this.bankLoader = true;

        // for (var z = 0; z < this.allSegments.length; z++) {
        //   // console.log("cdcd----", this.newSegmentSelected[z]);
        //   for (var x = 0; x < this.allSegments.length; x++) {
        //     console.warn(
        //       "---",
        //       this.newSegmentSelected[z],
        //       this.inactiveSegments[x],
        //       this.newSegmentSelected[x],
        //       this.newSegments[x]
        //     );
        //     if (
        //       this.newSegmentSelected[z] != undefined &&
        //       this.newSegmentSelected[x] != undefined
        //     ) {
        //       if (this.newSegmentSelected[z] == this.inactiveSegments[x]) {
        //         this.addsegtype = "YES";
        //       }
        //       if (this.newSegmentSelected[z] == this.newSegments[x]) {
        //         this.addsegtypeNew = "YES";
        //       }
        //     }
        //   }
        // }
        let addsegtype;
        let addsegtypeNew;
        // console.log('this.newSegments two',this.newSegmentSelected, this.newSegments, this.inactiveSegments);
        for (var za = 0; za < this.newSegmentSelected.length; za++) {
          addsegtype = this.inactiveSegments.indexOf(
            this.newSegmentSelected[za]
          );
          addsegtypeNew = this.newSegments.indexOf(this.newSegmentSelected[za]);
          if (
            addsegtype >= 0 &&
            this.newSegmentSelected[za] == this.inactiveSegments[addsegtype]
          ) {
            this.addsegtype = "YES";
          }
          if (
            addsegtypeNew >= 0 &&
            this.newSegmentSelected[za] == this.newSegments[addsegtypeNew]
          ) {
            this.addsegtypeNew = "YES";
          }
        }
        //

        const FormData = require("form-data");
        let data = new FormData();

        data.append("new_segments", JSON.stringify(this.newSegmentSelected));
        data.append("ext_segments", JSON.stringify(this.existingSegments));

        data.append("dp_code", this.profileData.CLIENT_DP_CODE);
        data.append(
          "adding_segments",
          this.newSegmentSelected.length > 0
            ? this.addsegtypeNew
              ? this.addsegtypeNew
              : "NO"
            : "NO"
        );
        data.append(
          "re_active_segments",
          this.addsegtype ? this.addsegtype : "NO"
        );

        data.append("client_id", this.profileData.CLIENT_ID);
        data.append("nse_equity", this.bsecash ? "YES" : "NO");
        data.append("bse_equity", this.bsecash ? "YES" : "NO");
        data.append("nse_equity_der", this.bsefno ? "YES" : "NO");
        data.append("bse_equity_der", this.bsefno ? "YES" : "NO");
        data.append("nse_currency_der", this.cdnse ? "YES" : "NO");
        data.append("bse_currency_der", this.cdnse ? "YES" : "NO");
        data.append("nse_commodity_der", this.bsecom ? "YES" : "NO");
        data.append("bse_commodity_der", this.bsecom ? "YES" : "NO");
        data.append("mcx_commodity_der", this.bsecom ? "YES" : "NO");
        data.append("icex_commodity_der", this.bsecom ? "YES" : "NO");
        data.append("nse_mfss", this.mfbse ? "YES" : "NO");
        data.append("bse_mfss", this.mfbse ? "YES" : "NO");
        data.append("nse_slbm", this.nseslbm ? "YES" : "NO");
        data.append("client_email", this.profileData.CLIENT_ID_MAIL);
        data.append("client_name", this.profileData.CLIENT_NAME);
        data.append("proff", this.panFile != null ? this.panFile : "");
        data.append("address", this.profileData.CL_RESI_ADD1);

        data.append("password_required", "NO");
        data.append("password", "");

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          // url: "http://192.168.5.132:5000/add_segment",

          url: api.api_url + "/add_segment",
          headers: {
            Authorization: this.tok,
            clientid: this.profileData.CLIENT_ID,
            "Content-Type": "multipart/form-data",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            axiosThis.bankLoader = false;

            // console.log(JSON.stringify(response.data));
            if (response.data.fileid && response.data.mailid) {
              axiosThis.digioEsign(
                response.data.fileid,
                response.data.mailid,
                response.data.session
              );
            }else if(response.data.msg == "pan name miss match so redirect to ekyc"){
              axiosThis.editdialog = false
              axiosThis.pannamenotfetch = true
     
              
            // }, 2000);
            }  else {
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = "Error in Server, please try again later";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.errorSnackbar = true;
        this.errtext = "Select any segment, please.";
      }
    },
    connectDigio() {
      var stateid = "signup" + Math.random().toString(36).substring(7);
      // window.location.href =
      //   "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=A73859D2&state=" +
      //   stateid +
      //   "";
      // for local
      window.location.href =
        "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=A987F208&state=" +
        stateid +
        "";
    },
    fetchMobileStatus() {
      var axiosThis = this;
      let data = JSON.stringify({
        client_id: this.profileData.CLIENT_ID,
      });

      let config = {
        method: "post",
        url: api.api_url + "/add_mob_email_stat",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "application/json",
        },
        data: data,
      };
      // console.log("jhasdgajb",this.client_code,this.tok)
      axios
        .request(config)
        .then((response) => {
          // console.log(response.data);

          if (response.data.emsg == "token expired") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
              if (response.data.emsg == "invalid token") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
       
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }

              if (response.data.emsg == "clientid not exist") {
                axiosThis.snackbar = true;
                axiosThis.txt = "Expired";
                // console.warn("Hs")
                localStorage.clear();
                sessionStorage.clear();
                setTimeout(function () {
                  // axiosThis.$router.push("/");
                  axiosThis.goSso();

                  window.location.reload();
                }, 2000);
              }
          
          axiosThis.blurLoader = false;
          if (response.data) {
            axiosThis.mobStatus = response.data;
          }
          if (
            axiosThis.mobStatus.mobile_status == "e-signed pending" ||
            axiosThis.mobStatus.address_status == "e-signed pending" ||
            axiosThis.mobStatus.email_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.mobileDisable = true;
            axiosThis.emailDisable = true;
            axiosThis.addressDisable = true;
          }
          if (
            axiosThis.mobStatus.address_status == "e-signed completed" ||
            axiosThis.mobStatus.address_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.addressDisable = true;
          }
          if (
            axiosThis.mobStatus.mobile_status == "e-signed completed" ||
            axiosThis.mobStatus.mobile_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.mobileDisable = true;
          }
          if (
            axiosThis.mobStatus.email_status == "e-signed completed" ||
            axiosThis.mobStatus.email_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.emailDisable = true;
          }
          if (
            axiosThis.mobStatus.income_status == "e-signed completed" ||
            axiosThis.mobStatus.income_status == "e-signed pending"
          ) {
            // document.getElementById("mobileedit").removeAttribute(":append-icon");
            axiosThis.yearlyIncomeDisable = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    digilockerAdressSubmit() {
      var axiosThis = this;

      // let params = (new URL(document.location)).searchParams;
      let token = this.aadharcode;
      let state = this.aadharstate;

      // var url_data = this.$route.params;
      // console.log(token,"poiuytrewqqqqqqasdfghjkllkmnbvcxz");

      // var addressvalid = this.$refs.formadress.validate();
      if (token && state) {
        this.editType = "address_change";
        const FormData = require("form-data");
        let data = new FormData();
        data.append("file", "");
        data.append("proff", "");
        data.append("cur_address", "");
        data.append("ext_address", this.profileData.CL_RESI_ADD1);
        data.append("dp_code", this.profileData.CLIENT_DP_CODE);
        data.append("client_id", this.client_code);
        data.append("client_name", this.name);
        data.append(
          "client_email",
          this.profileData.CLIENT_ID_MAIL.toUpperCase()
        );
        data.append("aadhar_address", "");
        data.append("adr_manual", "aadhar");
        data.append("code", token);
        data.append("state", state);

        // let config = {
        //   method: 'post',
        //   // maxBodyLength: Infinity,
        //   url: api.api_url+'/adr_chn',
        //   headers: {
        //   },
        //   data : data
        // };

        // axios.request(config)
        axios
          .post(api.api_url + "/adr_chn", data, {
            headers: {
              Authorization: this.tok,
              clientid: this.profileData.CLIENT_ID,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            axiosThis.adresschange = false;
            axiosThis.editdialog = false;
            if (response.data.fileid) {
              axiosThis.digioEsign(
                response.data.fileid,
                response.data.mailid,
                response.data.session
              );
            } else {
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = "Error in Server, please try again later";
            }
            axiosThis.$router.replace("/");
            // window.location.assign(window.location.href.split('?')[0])

            // const query = this.$route.query;
            //   delete query.code;
            //   delete query.state;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    checkSame() {
      if (this.profileData.MOBILE_NO == this.usernewmobile) {
        this.usernewmobile = "";
        this.errorSnackbar = true;
        this.errtext = "Enter your new Mobile number";
      }
    },
    ifscFetch(ifsc) {
      var axiosThis = this;
      this.bankIfscCode = this.bankIfscCode.toUpperCase();
      if (ifsc.indexOf(" ") >= 0) {
        // console.log("contains spaces");
        this.bankIfscCode = ifsc.replace(/\s+/g, "");
      }
      if (this.bankIfscCode.length >= 11) {
        let config = {
          method: "get",
          url: "https://ifsc.razorpay.com/" + ifsc,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            // console.log(response.data);
            axiosThis.ifscBankInfo = response.data;
          })
          .catch((error) => {
            axiosThis.ifscBankInfo = [];
            console.log(error);
          });
      }
    },
    incomeproof() {
      if (this.selectionas == 4) {
        this.proofField = true;
      } else {
        this.proofField = false;
        this.passwordField = false;
        this.incorrectPass = false;
      }
    },
    paswordverificationBtn() {
      // this.annualincomeOtpReq()
      this.bankLoader = true;
      this.tok = localStorage.getItem("usession");
      if (this.passwordRequired == true) {
        var axiosThis = this;
        const FormData = require("form-data");
        let data = new FormData();
        data.append("client_id", this.client_code);
        data.append("password", this.pdfPassword);
        data.append("checktype", "segment");

        data.append("proff_file", this.panFile);

        let config = {
          method: "post",
          url: api.api_url + "/pdf_password_check",
          headers: {
            Authorization: this.tok,
            clientid: this.profileData.CLIENT_ID,
            "Content-Type": "multipart/form-data",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            axiosThis.bankLoader = false;
            if (response.data["password required"] == "In-Correct") {
              axiosThis.pdfPassword = "";
              axiosThis.errorSnackbar = true;
              axiosThis.errtext = "Incorrect Password";

              axiosThis.passwordField = true;
              axiosThis.paswordverification = true;
            } else {
              axiosThis.segmentChange("YES");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        axiosThis.segmentChange("NO");
      }
    },
    passHide() {
      // let file = e.target.files[0] || e.dataTransfer.files[0]
      //           this.panFile = file;
      this.bankLoader = true;
      var axiosThis = this;
      this.passwordField = false;
      const FormData = require("form-data");
      let data = new FormData();
      data.append("client_id", this.client_code);
      data.append("proff_file", this.panFile);
      data.append("checktype", "segment");

      let config = {
        method: "post",
        url: api.api_url + "/pdf_lock",
        headers: {
          Authorization: this.tok,
          clientid: this.profileData.CLIENT_ID,
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          axiosThis.bankLoader = false;
          // console.log(response.data);
          if (response.data["password required"] == "True") {
            axiosThis.passwordField = true;
            axiosThis.passwordRequired = true;
            // axiosThis.paswordverification = true
          } else {
            axiosThis.passwordRequired = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    mobileEdit(data) {
      this.tok = localStorage.getItem("usession");
      // console.log('sasa', this.equty, this.derivat, this.mfdataarray, this.currency, this.commoditi)

      if (data == "segment") {
        this.Segmentssies = [];
        this.inactiveSegments = [];
        this.existingSegments = [];
        this.currentClick = "Segment";
        this.editType = "segment_change";
        this.bankproffdocument = false;
        // this.cardTitle = 'Email Number Verification'
        this.subTitle = "Would you like to activate segment";
        for (var a = 0; a < this.equty.length; a++) {
          const inactiveIndex = this.equty.findIndex(
            (item) =>
              item.Exchange_ACTIVE_INACTIVE === "I" ||
              item.Exchange_ACTIVE_INACTIVE === "NOT OPEN"
          );

          if (inactiveIndex !== -1) {
            // this.inactiveSegments.push(this.equty[inactiveIndex].COMPANY_CODE);
            this.bsecashDisable = false;
          } else {
            this.bsecashDisable = true;
          }

          if (this.equty[a].Exchange_ACTIVE_INACTIVE == "I") {
            this.inactiveSegments.push(this.equty[a].COMPANY_CODE);
            // console.log("12345678eqqqty", this.inactiveSegments);
            // this.bsecash = true;
            // this.bsecashDisable = false;
          } else if (
            this.equty[a].Exchange_ACTIVE_INACTIVE == "A" ||
            this.equty[a].Exchange_ACTIVE_INACTIVE == "NOT OPEN"
          ) {
            if (this.equty[a].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.equty[a].COMPANY_CODE);
              this.bsecash = true;
            }
            // if ((this.equty[a].Exchange_ACTIVE_INACTIVE == "A" || this.equty[a].Exchange_ACTIVE_INACTIVE == "NOT OPEN" ) && this.equty[a].REGISTRATION_DATE != ""){
            //   // this.bsecash = true;
            //   this.bsecashDisable = true;
            // } else {
            //   this.bsecashDisable = false;
            // }
          }

          // }
        }
        for (var b = 0; b < this.derivat.length; b++) {

         
          const inactiveIndex = this.derivat.findIndex(
            (item) =>
              item.Exchange_ACTIVE_INACTIVE === "I" ||
              item.Exchange_ACTIVE_INACTIVE === "NOT OPEN"
          );

          if (inactiveIndex !== -1) {
            // this.inactiveSegments.push(this.equty[inactiveIndex].COMPANY_CODE);
            this.bsefnoDisable = false;
          } else {
            this.bsefnoDisable = true;
          }


          if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "I") {
            this.inactiveSegments.push(this.derivat[b].COMPANY_CODE);
          } else if (
            this.derivat[b].Exchange_ACTIVE_INACTIVE == "A" ||
            this.derivat[b].Exchange_ACTIVE_INACTIVE == "NOT OPEN" ||
            this.derivat[b].Exchange_ACTIVE_INACTIVE == "I"
          ) {
            if (this.derivat[b].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.derivat[b].COMPANY_CODE);
              this.bsefno = true;
            }
            // if (
            //   (this.derivat[b].Exchange_ACTIVE_INACTIVE == "A" ||
            //     this.derivat[b].Exchange_ACTIVE_INACTIVE == "NOT OPEN") &&
            //   this.derivat[b].REGISTRATION_DATE != ""
            // ) {
            //   this.bsefnoDisable = true;
            // } else {
            //   this.bsefnoDisable = false;
            // }
          }

          // }
        }
        for (var c = 0; c < this.mfdataarray.length; c++) {

          const inactiveIndex = this.mfdataarray.findIndex(
            (item) =>
              item.Exchange_ACTIVE_INACTIVE === "I" ||
              item.Exchange_ACTIVE_INACTIVE === "NOT OPEN"
          );

          if (inactiveIndex !== -1) {
            // this.inactiveSegments.push(this.equty[inactiveIndex].COMPANY_CODE);
            this.mfdisabled = false;
          } else {
            this.mfdisabled = true;
          }

          if (this.mfdataarray[c].Exchange_ACTIVE_INACTIVE == "I") {
            this.inactiveSegments.push(this.mfdataarray[c].COMPANY_CODE);
            // this.mfdisabled = false;

            // console.log(this.commoditi[c],"bse")
          } else if (
            this.mfdataarray[c].Exchange_ACTIVE_INACTIVE == "A" ||
            this.mfdataarray[c].Exchange_ACTIVE_INACTIVE == "NOT OPEN"
          ) {
            if (this.mfdataarray[c].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.mfdataarray[c].COMPANY_CODE);
            }

            // if (
            //   (this.mfdataarray[c].Exchange_ACTIVE_INACTIVE == "A" ||
            //     this.mfdataarray[c].Exchange_ACTIVE_INACTIVE == "NOT OPEN") &&
            //   this.mfdataarray[c].REGISTRATION_DATE != ""
            // ) {
            //   this.mfdisabled = true;
            // } else {
            //   this.mfdisabled = false;
            // }
          }

          // }
        }
        for (var d = 0; d < this.currency.length; d++) {

          const inactiveIndex = this.currency.findIndex(
            (item) =>
              item.Exchange_ACTIVE_INACTIVE === "I" ||
              item.Exchange_ACTIVE_INACTIVE === "NOT OPEN"
          );

          if (inactiveIndex !== -1) {
            // this.inactiveSegments.push(this.equty[inactiveIndex].COMPANY_CODE);
            this.cdnseDisable = false;
          } else {
            this.cdnseDisable = true;
          }



          if (this.currency[d].Exchange_ACTIVE_INACTIVE == "I") {
            this.inactiveSegments.push(this.currency[d].COMPANY_CODE);
          } else if (
            this.currency[d].Exchange_ACTIVE_INACTIVE == "A" ||
            this.currency[d].Exchange_ACTIVE_INACTIVE == "NOT OPEN"
          ) {
            if (this.currency[d].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.currency[d].COMPANY_CODE);
            }
            // if (
            //   (this.currency[d].Exchange_ACTIVE_INACTIVE == "A" ||
            //     this.currency[d].Exchange_ACTIVE_INACTIVE == "NOT OPEN") &&
            //   this.currency[d].REGISTRATION_DATE != ""
            // ) {
            //   this.cdnseDisable = true;
            // } else {
            //   this.cdnseDisable = false;
            // }
          }

          // }
        }
        for (var e = 0; e < this.commoditi.length; e++) {


          const inactiveIndex = this.commoditi.findIndex(
            (item) =>
              item.Exchange_ACTIVE_INACTIVE === "I" ||
              item.Exchange_ACTIVE_INACTIVE === "NOT OPEN"
          );

          if (inactiveIndex !== -1) {
            // this.inactiveSegments.push(this.equty[inactiveIndex].COMPANY_CODE);
            this.bsecomDisable = false;
          } else {
            this.bsecomDisable = true;
          }

          if (this.commoditi[e].Exchange_ACTIVE_INACTIVE == "I") {
            this.inactiveSegments.push(this.commoditi[e].COMPANY_CODE);
            // this.bsecom = false;

            // console.log(this.commoditi[c], "bse")
          } else if (
            this.commoditi[e].Exchange_ACTIVE_INACTIVE == "A" ||
            this.commoditi[e].Exchange_ACTIVE_INACTIVE == "NOT OPEN"
          ) {
            if (this.commoditi[e].Exchange_ACTIVE_INACTIVE == "A") {
              this.existingSegments.push(this.commoditi[e].COMPANY_CODE);
            }

            // if (
            //   (this.commoditi[e].Exchange_ACTIVE_INACTIVE == "A" ||
            //     this.commoditi[e].Exchange_ACTIVE_INACTIVE == "NOT OPEN") &&
            //   this.commoditi[e].REGISTRATION_DATE != ""
            // ) {
            //   this.bsecomDisable = true;
            // } else {
            //   this.bsecomDisable = false;
            // }
          }

          // }
        }
        var array1;
        var array2;

        // for (var i = 0;i<this.allSegments.length;i++) {
        let arr = this.allSegments;
        // let remove = arr.filter((id) => id !== this.existingSegments[i].COMPANY_CODE)
        array1 = arr.filter((item) => !this.existingSegments.includes(item));
        array2 = array1.filter((item) => !this.inactiveSegments.includes(item));

        this.newSegments = array2;
        // console.log(array1, this.equty, this.existingSegments.length);
        const final_data = Array.from(this.existingSegments);

        if (final_data.length == 0) {
          this.nosegmentsPop = true;
        } else {
          this.editdialog = true;
          this.adressOptions = false;
          this.manual = false;
          this.requestotp = false;
          this.requestotpemail = false;
          this.adresschange = false;
          this.bankchange = false;
          this.annualIncomechange = false;
          this.segments = true;
          this.annualIncomeverifyotp = false;
          this.segOpt = false;
          this.addNewSeg = true;
          this.activateSeg = false;
          this.nosegmentsPop = false;
        }
        // [1,2,3,4,6]
        // }
        // if(final_data.length == 0) {
        //   console.warn('your in')
        // }
        // console.log(this.inactiveSegments, "......", (final_data), this.allSegments)
      }
      // console.log("edit mobile");
    },
    redirectSeg() {
      let encoded = window.btoa(
        `${this.profileData.PAN_NO}||${this.profileData.CLIENT_ID}`
      );
      window.location.href = "https://oa.mynt.in?rekyc=" + encoded;
    },
 
    valu(val) {
      // console.log(val, "val");
      const validat = val;
      if (validat == false) {
        this.createChartsred();
      }
    },
    encryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      var derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      var iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      var test = CryptoJS.AES.encrypt(payload, derived_key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }).toString();
      return test;
    },
    decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      // Decrypt the data using AES
      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      // console.log('Decrypted Data:', decryptedData);
      return decryptedData;
    },

    getAvailBal() {
      var axiosThis = this;
    
              axiosThis.equty = [];
              axiosThis.derivat = [];
              axiosThis.commoditi = [];
              axiosThis.mfdataarray = [];
              // console.log(response.data.str)
              var res = axiosThis.decryptionFunction(localStorage.getItem("profile_data"));
              // console.log(JSON.parse(res));
              var resp = JSON.parse(res);
              axiosThis.profileData = resp.client_data;
              axiosThis.hashpan = axiosThis.profileData.PAN_NO.slice(-3);
              axiosThis.BankData = resp.bank_data;

              axiosThis.name = axiosThis.profileData.CLIENT_NAME;
              localStorage.setItem("clinet_name", axiosThis.name);

              axiosThis.client_code = axiosThis.profileData.CLIENT_ID;

              if (axiosThis.profileData.Nominee_Name) {
                // axiosThis.multinominee = resp.
                axiosThis.nomineeYes = true;
                axiosThis.newNomineeName = axiosThis.profileData.Nominee_Name;
                axiosThis.newNomineeRelation =
                  axiosThis.profileData.Nominee_Relation;
                axiosThis.newNomineeDob = axiosThis.profileData.Nominee_DOB;
                // axiosThis.newNomineepercent = axiosThis.profileData.Nominee_Name
              }
              axiosThis.fetchMobileStatus();

              // else {
              //   axiosThis.nomineeYes = false

              // }
              axiosThis.profileData.CL_RESI_ADD1 =
                axiosThis.profileData.CL_RESI_ADD1 +
                " " +
                axiosThis.profileData.CL_RESI_ADD2 +
                " " +
                axiosThis.profileData.CL_RESI_ADD3;
              for (
                var i = 0;
                i < axiosThis.profileData.segments_data.length;
                i++
              ) {
                // console.log(axiosThis.profileData.segments_data[i].COMPANY_CODE,"dhatchu");
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                    "BSE_CASH" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                    "NSE_CASH"
                ) {
                  axiosThis.equty.push(axiosThis.profileData.segments_data[i]);
                }
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                    "MF_NSE" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                    "MF_BSE"
                ) {
                  axiosThis.mfdataarray.push(
                    axiosThis.profileData.segments_data[i]
                  );
                }
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                    "NSE_FNO" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                    "BSE_FNO"
                ) {
                  axiosThis.derivat.push(
                    axiosThis.profileData.segments_data[i]
                  );
                }
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                    "CD_BSE" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                    "CD_NSE" 
                  //   ||
                  // axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                  //   "CD_MCX"
                ) {
                  axiosThis.currency.push(
                    axiosThis.profileData.segments_data[i]
                  );
                }
                if (
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                    "BSE_COM" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                    "NSE_COM" ||
                  axiosThis.profileData.segments_data[i].COMPANY_CODE ==
                    "MCX" 
                  //   ||
                  // axiosThis.profileData.segments_data[i].COMPANY_CODE == "ICEX"
                ) {
                  axiosThis.commoditi.push(
                    axiosThis.profileData.segments_data[i]
                  );
                }
              }
              this.categories.forEach(category => {
      if (category.title === 'Equities') {
        category.folders = this.equty;
      } else if (category.title === 'F&O') {
        category.folders = this.derivat;
      } else if (category.title === 'Currency') {
        category.folders = this.currency;
      } else if (category.title === 'Commodities') {
        category.folders = this.commoditi;
      }
      // Apply previous conditions
      category.folders.forEach(folder => {
        if (folder.Exchange_ACTIVE_INACTIVE === 'A') {
          folder.checked = true; // Set checkbox to true if status is ACTIVE
          this.selectedFolders.push(folder.COMPANY_CODE); // Add to selected folders array
        }
      });
    });
              axiosThis.digilockerAdressSubmit();
          
    },
    chipp() {
      this.chha = !this.chha;
    },
    getCardColor(index) {
      // Custom logic to determine the card color based on the index or other conditions
      if (index % 2 === 0) {
        return "#ECF9EF"; // Example: Blue color for even index cards
      } else {
        return "#FAEAEA"; // Example: Green color for odd index cards
      }
    },
    closeBtn() {
      this.editdialog = false;
      this.verifyotp = false;
      this.requestotp = true;
      this.userOtp = "";
      this.usernewmobile = "";
      (this.usernewemail = ""),
        (this.requestotpemail = false),
        (this.useremailOtp = ""),
        (this.verifyotpemail = false),
        (this.stateAddress = ""),
        (this.countryAddress = ""),
        (this.distAddress = ""),
        (this.pincodeAddress = "");
      (this.usernewAdress = ""),
        (this.adressproffdoc = null),
        (this.bankIfscCode = ""),
        (this.bankAccountnumber = ""),
        (this.bankproofTypes = ""),
        (this.bankproffdoc = null),
        (this.defaultCheckbox = false);
    },
  },
  computed: {
    orderbookHeaders() {
      return [
        { text: "Member ID", value: "memberid", sortable: false },
        { text: "Datetime", value: "req_link_time" },
        { text: "Status", value: "status", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    mobileNumberRules() {
      return [(value) => /^\d{10}$/.test(value) || "Enter a valid number"];
    },
    otpRules() {
      return [
        (value) => !!value || "OTP Required",
        (value) => /^\d{4}$/.test(value) || "Must be exactly 4 digits",
      ];
    },
    nomineeField() {
      return [(value) => !!value || "Required field"];
    },
    emailNumberRules() {
      return [
        (value) => !!value || "email id is required",
        (value) => /.+@.+\..+/.test(value) || "Email must be valid",
      ];
    },
    depedselectRules() {
      return [(value) => !!value || "Dependent name is required"];
    },
  },
  mounted() {
    // this.blurLoader = true;
    let params = new URL(document.location).searchParams;
    var cod = params.get("code");
    var stat = params.get("state");
    var vendorAcc = params.get("sAccountId");
    var vendorToken = params.get("sToken");
    this.accountstatus = localStorage.getItem("clientStatus")

    // console.log("jiji", vendorAcc, vendorToken);

    // console.log("////",cod,stat)
    if (cod && stat) {
      this.aadharstate = stat;
      this.aadharcode = cod;
    }
    // let decoded = decodeURIComponent(window.location.search);
    var actid = params.get("uid");
    var token = params.get("token");
    this.susertoken = localStorage.getItem("usession");
    // console.log(this.susertoken,actid,window.location.search)
    this.actid = localStorage.getItem("userid");
    if (typeof actid == "string" && typeof token == "string") {
      localStorage.setItem("usession", token);
      localStorage.setItem("userid", actid);
      this.susertoken = localStorage.getItem("usession");
      this.actid = localStorage.getItem("userid");
      window.location.assign(window.location.href.split("?")[0]);
    }
    if (vendorAcc == null && vendorToken == null) {
      // console.warn("nully")
      if (this.susertoken && this.actid) {
        var id = this.actid;
        this.tok = this.susertoken;
        // console.log("ifififififififif", this.tok);
        this.getAvailBal(id);
      } else {
        this.goSso();
      }
    }
  },
};
const $ = document.querySelector.bind(document);
const $$ = document.querySelectorAll.bind(document);

let activeLink = null;

window.addEventListener("scroll", () => {
  $$(".section").forEach((section) => {
    const { y } = section.getBoundingClientRect();

    if (y <= 0) {
      if (activeLink != null) {
        activeLink.classList.remove("active");
      }

      activeLink = $(`.nav-link[href~="#${section.id}"]`);
      activeLink.classList.add("active");
    }
  });
});
</script>
<style scoped>
.v-dialog {
  border-radius: 4px;
  margin: 24px;
  /* overflow-y: auto; */
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  outline: none;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
</style>