// export const apiurll = "http://192.168.5.121:9003"
export const apiurlipo = "https://v3.mynt.in/ipo"
// export const apiurlipo = "http://192.168.5.179:5010"
export const apiurl = "https://rekycbe.mynt.in/report"
// export const apiurl = "http://192.168.5.163:9003"

// export const apiurlotherPort = 'http://192.168.5.178:5891'
export const apiurlotherPort = 'https://rekycbe.mynt.in/portfolio/'

export const apidailypnl = "https://profile.mynt.in"
// export const apidailypnl = "http://192.168.5.163:9003"
// export const pledgeurl = "https://rekycbe.mynt.in/pledge"`
export const pledgeurlapi = "https://rekycbe.mynt.in/pledge/"
export const beapi = "https://be.zebull.in/api/"

// export const pledgeurlapi = "http://192.168.5.102:9000/"

// for profile

export default{ 
    api_url : 'https://rekycbe.mynt.in',
    // api_url : 'https://rekycuat.mynt.in',

// ooo 
    }
 export const apiurlForProfile = "https://rekycbe.mynt.in"