import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import AOS from 'aos';
import 'aos/dist/aos.css';
Vue.config.productionTip = false
AOS.init({
  offset: 0, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 300, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  anchorPlacement: 'top-bottom', //
});
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')


