<template>
  <v-app>
    <v-container class="pa-0 pa-sm-3 pa-md-3 mt-n5">
      <v-alert v-if="alert" dense outlined type="success" style="position: fixed; top: 8; right: 0; z-index: 3">
        <strong>{{ msg }}</strong>
      </v-alert>
      <v-alert v-if="alert1" dense outlined type="error" style="position: fixed; top: 8; right: 0; z-index: 3">
        <strong>{{ msg }}</strong>
      </v-alert>
      <div class=" pa-6 pa-sm-4 pa-md-4 d-none d-md-block">
        <v-col cols="12" class="pl-7">
          <v-card class="mt-7 elevation-0">
            <v-toolbar class="mt-3 mb-1 px-1 toolbar-contant" elevation="0" color="white">
              <v-list class="" style="background-color: transparent">
                <v-list-item class="px-0 pt-0">
                  <v-list-item-content class="pt-0">
                    <v-list-item-title class="text-start" style="
                        color: #000000;
                        font-size: 20px;
                        font-weight: 559;
                        line-height: 19px;
                        letter-spacing: -1px;
                      ">
                      Verified P&L
                    </v-list-item-title>

                    <v-list-item-subtitle class="text-start pt-1" style="
                        color: #666666;
                        font-size: 12px;
                        font-weight: 469;
                        line-height: 14px;
                      "> Proof of success-see and share your triumphant journey
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <!-- <v-spacer></v-spacer> -->
              <!-- <v-list class="mr-7 pt-0" style="background-color: transparent;">
                                <v-list-item class="pl-0 pa-0" style="min-height: 0px;">

                                    <v-list-item-content class="pl-2 pa-0  d-none d-md-block">
                                        <v-list-item-title v-if="downsum" class="text-start"
                                            style="color: #000000;font-size: 16px;font-weight:559;line-height:16px;">
                                            {{ dessertsoriginal.length }}
                                            Symbols
                                        </v-list-item-title>
                                        <v-list-item-title v-if="!downsum" class="text-start"
                                            style="color: #000000;font-size: 16px;font-weight:559;line-height:16px;">
                                            {{ desserts23.length }}
                                            Symbols
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list> -->
              <!-- <v-menu v-if="downsum && dessertsoriginal.length > 0" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="elevation-0 rounded-pill mt-n2 mr-2" v-bind="attrs" v-on="on">
                                        <v-icon size="20">mdi mdi-download</v-icon>
                                    </v-btn>
                                </template>
<v-list>
  <v-list-item v-for="(item, i) in itemsdwl" :key="i" @click="() => { }">
    <v-list-item-title @click="downloadsum(item.title)">{{ item.title
      }}</v-list-item-title>
  </v-list-item>
</v-list>
</v-menu> -->
              <v-menu class="" v-model="menu23" left :close-on-content-click="false" transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field style="max-width: 205px;" class="mt-4 mr-2" solo rounded flat v-model="fromdisplay"
                    label="From date" dense color="black" background-color="#F1F3F8" prepend-inner-icon="mdi-calendar"
                    readonly v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-card class="pa-2">
                  <div>
                    <v-date-picker range :min="minval" :max="maxval" color="black" v-model="fromtodate"
                      @input="datemaxvalue('click')"></v-date-picker>
                    <!-- <v-date-picker  range color="black" v-model="fromdate" @change="gettradedata()"></v-date-picker> -->
                  </div>
                  <p v-if="fromdate != '' && fromdate != [] && (highlightyears != 'cur' || highlightyears == '')"
                    class="ml-2 caption mb-1 "><span class="red--text">*</span>Your are in {{
                      Number(maxval.split("-")[0]) -
                    1}}-{{ maxval.split("-")[0] }} Financial
                    year
                    <v-tooltip color="black" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span @click="reset" class="caption blue--text font-weight-bold" style="cursor: pointer"
                          v-bind="attrs" v-on="on">
                          Change
                        </span>
                      </template>
                      <span>Click here to move the date forward for Financial year</span>
                    </v-tooltip>
                  </p>

                  <v-divider></v-divider>
                  <div class="d-flex mt-n2">
                    <!-- <p class="mb-0 mx-1 pa-4 body-2 font-weight-black" style="cursor: pointer;" @click="gettradedataextra(7)">Last 7 days</p> -->
                    <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"  @click="gettradedataextra(30)">Last 30 days</p> -->
                    <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                      :class="{ 'blue--text': highlightyears == 'lseven', 'black--text': highlightyears != 'lseven' }"
                      @click="gettradedata('lseven')">Last Seven Days</p>
                    <p class="mb-0 pl-0 ml-auto pa-4 body-2 font-weight-black" v-if="highlightyears == ''"
                      style="cursor: pointer;" @click="reset()"><v-icon>mdi-reload</v-icon></p>
                    <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                      :class="{ 'blue--text': highlightyears == 'lm', 'black--text': highlightyears != 'lm' }"
                      @click="gettradedata(-1, 'ly')">Last Month</p> -->

                    <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                      :class="{ 'blue--text': highlightyears == yearsarray[0], 'black--text': highlightyears != yearsarray[0] }"
                      @click="gettradedata(-2, yearsarray[0])">{{ yearsarray[0] }}</p>
                    <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                      :class="{ 'blue--text': highlightyears == yearsarray[1], 'black--text': highlightyears != yearsarray[1] }"
                      @click="gettradedata(-3, yearsarray[1])">{{ yearsarray[1] }}</p>
                    <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" style="cursor: pointer;"
                      :class="{ 'blue--text': highlightyears == yearsarray[2], 'black--text': highlightyears != yearsarray[2] }"
                      @click="gettradedata(-4, yearsarray[2])">{{ yearsarray[2] }}</p> -->
                    <!-- <p class="mb-0 pl-0  pa-4 body-2 font-weight-black" @click="mountedfun(30)">Last </p> -->
                  </div>
                </v-card>

              </v-menu>


              <!-- dense prepend-inner-icon="mdi-filter-variant" -->
              <!-- <v-select v-if="downsum"  :readonly="segloader" :loading="segloader" :items="filterr" @change="filterseg(filterdata_unpl)" dense
                                v-model="filterdata_unpl" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                                hide-details class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 21px; overflow: hidden;">
                            </v-select> -->
              <!-- <v-select v-if="!downsum" :items="filterr" @change="filterseg1(filterdata_unpl1)" dense
                                v-model="filterdata_unpl1" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                                hide-details class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 21px">
                            </v-select> -->
              <!-- <v-btn v-if="downsum" @click="downloadsum()" class="elevation-0 rounded-pill mt-n2 mr-2"><v-icon
                                    size="20">mdi mdi-content-save</v-icon></v-btn>
                            <v-btn v-if="!downsum" @click="downloaddet()"
                                class="elevation-0 rounded-pill mt-n2 mr-2"><v-icon size="20">mdi
                                    mdi-content-save</v-icon></v-btn> -->
              <v-select :readonly="segloader" :loading="segloader" :items="filterr" @change="filterseg(filterdata_unpl)"
                dense v-model="filterdata_unpl" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                hide-details class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 21px; overflow: hidden">
              </v-select>
              <v-text-field style="width: 2%" class="tophundraedmutual d-none d-md-block mt-4 mr-5" height="36px"
                background-color="#F1F3F8" label="Search" v-model="search" solo rounded text flat dense>
                <template v-slot:prepend-inner>
                  <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="18px" />
                </template>
              </v-text-field>
            </v-toolbar>

            <!-- <v-btn @click="takeScreenshot">take</v-btn> -->
            <div id="target-element">
              <div class="display-flex row d-none d-md-block pa-8">
                <v-row>
                  <v-col>
                    <v-card class="ml-3 pl-3 py-1 pr-2" outlined height="90px">
                      <p class="pt-1 mb-3" style="
                        font-weight: 450;
                        font-size: 16px;
                        color: #000000;
                        line-height: 16px;
                        letter-spacing: -0.4px;
                      ">
                        Realised P&L
                      </p>
                      <v-divider class="mt-2" style="border: solid px #000000" width="30px" color="#000000"></v-divider>
                      <div class="display-flex row pt-6 pl-2">
                        <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                          <p class="mb-0" v-if="disrealised_pnl && disrealised_pnl < 0" style="
                            color: red;
                            font-size: 13px;
                            font-weight: 469;
                            line-height: 12px;
                            letter-spacing: 0.9px;
                          ">
                            {{ disrealised_pnl.toFixed(2) }}
                          </p>
                          <p class="mb-0" v-if="disrealised_pnl && disrealised_pnl > 0" style="
                            color: green;
                            font-size: 13px;
                            font-weight: 469;
                            line-height: 12px;
                            letter-spacing: 0.9px;
                          ">
                            {{ disrealised_pnl.toFixed(2) }}
                          </p>
                          <p class="mb-0" v-if="disrealised_pnl && disrealised_pnl == 0" style="
                            color: black;
                            font-size: 13px;
                            font-weight: 469;
                            line-height: 12px;
                            letter-spacing: 0.9px;
                          ">
                            {{ disrealised_pnl.toFixed(2) }}
                          </p>
                        </v-card>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card class="ml-3 pl-3 py-1 pr-2" outlined height="90px">
                      <p class="pt-1 mb-3" style="
                        font-weight: 450;
                        font-size: 16px;
                        color: #000000;
                        line-height: 16px;
                        letter-spacing: -0.4px;
                      ">
                        Unrealised P&L
                      </p>
                      <v-divider class="mt-2" style="border: solid px #000000" width="30px" color="#000000"></v-divider>
                      <div class="display-flex row pt-6 pl-2">
                        <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                          <p class="mb-0" v-if="disunrealised_pnl && disunrealised_pnl < 0" style="
                            color: red;
                            font-size: 13px;
                            font-weight: 469;
                            line-height: 12px;
                            letter-spacing: 0.9px;
                          ">
                            {{ disunrealised_pnl.toFixed(2) }}
                          </p>
                          <p class="mb-0" v-else-if="disunrealised_pnl && disunrealised_pnl > 0" style="
                            color: green;
                            font-size: 13px;
                            font-weight: 469;
                            line-height: 12px;
                            letter-spacing: 0.9px;
                          ">
                            {{ disunrealised_pnl.toFixed(2) }}
                          </p>

                          <p class="mb-0" v-else-if="disunrealised_pnl && disunrealised_pnl == 0
                          " style="
                            color: black;
                            font-size: 13px;
                            font-weight: 469;
                            line-height: 12px;
                            letter-spacing: 0.9px;
                          ">
                            {{ disunrealised_pnl.toFixed(2) }}
                          </p>
                        </v-card>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card class="ml-3 pl-3 py-1 pr-2" outlined height="90px">
                      <p class="pt-1 mb-3" style="
                        font-weight: 450;
                        font-size: 16px;
                        color: #000000;
                        line-height: 16px;
                        letter-spacing: -0.4px;
                      ">
                        Total P&L
                      </p>
                      <v-progress-linear style="width: 30px" v-if="segloader" indeterminate
                        color="blue"></v-progress-linear>
                      <v-divider v-else-if="!segloader" class="mt-2" style="border: solid px #000000" width="30px"
                        color="#000000"></v-divider>
                      <div class="display-flex row pt-6 pl-2">
                        <v-card class="py-1 px-2 elevation-0 ml-1" color="#F1F3F8">
                          <p class="mb-0" v-if="segval && segval < 0" style="
                            color: red;
                            font-size: 13px;
                            font-weight: 469;
                            line-height: 12px;
                            letter-spacing: 0.9px;
                          ">
                            {{ segval.toFixed(2) }}
                          </p>
                          <p class="mb-0" v-else-if="segval && segval > 0" style="
                            color: green;
                            font-size: 13px;
                            font-weight: 469;
                            line-height: 12px;
                            letter-spacing: 0.9px;
                          ">
                            {{ segval.toFixed(2) }}
                          </p>
                          <p class="mb-0" v-else-if="segval && segval == 0" style="
                            color: black;
                            font-size: 13px;
                            font-weight: 469;
                            line-height: 12px;
                            letter-spacing: 0.9px;
                          ">
                            {{ segval.toFixed(2) }}
                          </p>
                        </v-card>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col>
                    <!-- <v-card class="ml-3 pl-3 py-1 pr-2" outlined height="90px"> -->
                    <div v-if="!loader && desserts.length > 0">
                      <p class="mt-2 mb-3" style="
                        font-weight: 450;
                        font-size: 14px;
                        color: #4b4b4b;
                        line-height: 16px;
                        letter-spacing: -0.4px;
                      ">
                        <v-icon size="16" color="green" class="pt-n2">mdi mdi-check-circle</v-icon>
                        P&L verified by Zebu
                        <!-- <v-icon color="blue" class="mt-n1">mdi mdi-menu-right</v-icon> -->
                      </p>
                      <!-- <p class="mb-0" style="
                        font-weight: 450;
                        font-size: 11px;
                        color: rgb(0, 0, 0);
                        line-height: 16px;
                        letter-spacing: -0.4px; ">
                                          Your P&L is a verified tab here to share your success on
                                          your Twitter account
                                      </p> -->

                      <v-row>
                        <v-col cols="7" class="pa-0 pl-3">

                          <!-- <label class="switch ">
                            <input @change="switchbtn" type="checkbox" style="display: none" class="ml-2">
                            <span class="slider round"></span>
                          </label> -->
                          <p class="mt-2 mb-0 " style="font-size: 12px">Share to Everyone</p>
                          <div class="d-flex mt-n1">
                            <v-switch color="black" @change="switchbtn" class="mt-1" v-model="agreeforshare" inset
                              dense></v-switch>
                            <v-btn class="mt-2 px-3" disabled rounded x-small v-if="!agreeforshare">
                              <v-icon class="" size="16">mdi mdi-share-variant</v-icon>
                            </v-btn>
                            <v-btn dark class="mt-2 px-3" rounded x-small v-else @click="shareto">
                              <v-icon color="white" class="" size="16">mdi mdi-share-variant</v-icon>
                            </v-btn>

                          </div>
                        </v-col>

                      </v-row>

                    </div>
                    <!-- </v-card> -->
                  </v-col>
                </v-row>
              </div>
              <div class="mt-2 mt-sm-0 mt-md-0">
                <div class="mb-n4 pa-2">
                  <!-- <v-row class="pt-0">
                                    <v-col lg="4" sm="12" md="12" cols="12">
                                        <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
                                            <v-tab @click="downsum = true">
                                                P&L
                                            </v-tab>
                                            <v-tab @click="downsum = false">
                                                Tax p&l
                                            </v-tab>
                                        </v-tabs>
                                    </v-col>
                                </v-row> -->
                  <!-- {{ loader }} -->

                  <!-- {{ dialog }} -->

                  <!-- <v-tabs-items v-model="tab"> -->
                  <!-- <v-tab-item> -->
                  <v-dialog v-model="dialogcopytext" width="500">


                    <v-card>
                      <v-list-item-content class=" pt-5 pl-5">
                        <v-list-item-title class="text-start"
                          style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">Grab
                          your URL
                        </v-list-item-title>

                        <v-list-item-subtitle class="text-start pt-1"
                          style="color: #666666;font-size: 11px;font-weight:469;line-height:14px"> Click to share your
                          triumphant
                          journey.
                        </v-list-item-subtitle>
                        <v-btn absolute right top icon color="black" @click="dialogcopytext = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-list-item-content>
                      <v-divider></v-divider>

                      <div class="px-6 mt-3 mb-2">
                        <p class="mb-1 caption">Share this link Or copy link</p>

                      </div>
                      <!-- <p class="mb-1 px-6 caption">Or copy link</p> -->
                      <div class="d-flex">

                        <v-text-field readonly label="Regular" solo rounded flat dense background-color="#F1F3F8"
                          v-model="copytext" style="width: 60px" class="  ml-6" height="36px"></v-text-field>
                        <v-btn @click="copyTextclick" class="ml-2  text-capitalize white--text" solo text rounded flat
                          dense style="background-color: black;"><v-icon size="18">mdi mdi-content-copy</v-icon></v-btn>
                        <v-btn @click="shareTweet" class="mr-5 ml-2 text-capitalize white--text" solo text rounded flat
                          dense style="background-color: black;"><img src="@/assets/xmark.svg" width="12px" class="ml-1"
                            alt="Portfolio icon" /></v-btn>


                      </div>

                    </v-card>

                  </v-dialog>

                  <v-data-table :search="search" hide-default-footer :loading="loader" height="495px" mobile-breakpoint
                    fixed-header disable-sort :headers="Overviewheaders" :items="desserts" :items-per-page="itemperpage"
                    style="border: 1px solid #efeef3; cursor: pointer" class="elevation-0 rounded-lg">
                    <template v-slot:[`item.SCRIP_NAME1`]="{ item }">
                      <span class="font-weight-bold subtitle-2 mt-2">
                        {{ item.SCRIP_SYMBOL }}</span>
                      <v-tooltip v-if="item.OPEN_QUANTITY != ''" v-model="item.show" top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip v-bind="attrs" v-on="on" color="blue" class="ml-2" x-small outlined>{{
                            item.OPEN_QUANTITY }}</v-chip>
                        </template>
                        <span>Open Quantity!</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:[`item.NOT_PROFIT`]="{ item }">
                      <span v-if="item.NOT_PROFIT > 0" style="color: green" class="font-weight-regular">{{
                        item.NOT_PROFIT }}</span>
                      <span v-else-if="item.NOT_PROFIT < 0" style="color: red" class="font-weight-regular">
                        {{ item.NOT_PROFIT }}</span>
                      <span v-else-if="item.NOT_PROFIT == 0" style="color: black" class="font-weight-regular">
                        0</span>
                    </template>
                    <template v-slot:[`item.NET_QUANTITY`]="{ item }">
                      <span class="font-weight-regular" style="text-align: right">
                        {{ item.NET_QUANTITY }}</span>
                    </template>
                    <template v-slot:[`item.NET_RATE`]="{ item }">
                      <span class="font-weight-regular" style="text-align: right">
                        {{ item.NET_RATE }}</span>
                    </template>
                    <template v-slot:[`item.unrpnl`]="{ item }">
                      <span v-if="item.unrealized_pnl" class="font-weight-regular" style="text-align: right">
                        {{ (item.unrealized_pnl).toFixed(2) }}</span>
                      <span v-else class="font-weight-regular" style="text-align: right">
                        0</span>
                    </template>
                    <template v-slot:[`item.rpnl`]="{ item }">
                      <span v-if="item.realized_pnl" class="font-weight-regular" style="text-align: right">
                        {{ (item.realized_pnl).toFixed(2) }}</span>
                      <span v-else class="font-weight-regular" style="text-align: right">
                        0</span>
                    </template>
                    <template v-slot:no-data>
                      <v-col class="mx-auto pa-15 mt-5">
                        <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                        <p class="font-weight-bold black--text">
                          No data available
                        </p>
                      </v-col>
                    </template>
                    <!-- <template v-slot:footer>
                                                <v-divider></v-divider>
                                                <div class="text-center" v-if="more">
                                                    <v-btn text color="primary" dark class="ma-2 text-none"
                                                        @click="morebtn()">
                                                        <span class=""
                                                            style="color: #0037B7;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">Show
                                                            more</span> <v-icon color="black"> mdi-chevron-down</v-icon>
                                                    </v-btn>
                                                </div>
                                                <div class="text-center" v-if="less">
                                                    <v-btn flat text color="primary" dark class="ma-2 text-none"
                                                        @click="lessbtn()">
                                                        <span class=""
                                                            style="color: #0037B7;font-size: 14px;font-weight:559;line-height:16px;letter-spacing:0px">
                                                            Show Less</span> <v-icon color="black">
                                                            mdi-chevron-up</v-icon>
                                                    </v-btn>
                                                </div>
                                            </template> -->
                  </v-data-table>

                  <!-- </v-tab-item> -->
                  <!-- <v-tab-item> -->
                  <!-- <p>Tax p and l datas</p> -->
                  <!-- </v-tab-item> -->
                  <!-- </v-tabs-items> -->
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </div>
      <div class="d-md-none">
        <v-toolbar class="mt-6 mb-1 px-2 toolbar-contant" elevation="0" color="white">
          <v-list class="pb-0" style="background-color: transparent">
            <v-list-item class="pt-0 px-0">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path
                      d="M11 47H2C1.73478 47 1.48043 46.8946 1.29289 46.7071C1.10536 46.5196 1 46.2652 1 46V28C1 27.7348 1.10536 27.4804 1.29289 27.2929C1.48043 27.1054 1.73478 27 2 27H11C11.2652 27 11.5196 27.1054 11.7071 27.2929C11.8946 27.4804 12 27.7348 12 28V46C12 46.2652 11.8946 46.5196 11.7071 46.7071C11.5196 46.8946 11.2652 47 11 47Z"
                      fill="#C8C8C8" />
                    <path
                      d="M46 47H37C36.7348 47 36.4804 46.8946 36.2929 46.7071C36.1054 46.5196 36 46.2652 36 46V28C36 27.7348 36.1054 27.4804 36.2929 27.2929C36.4804 27.1054 36.7348 27 37 27H46C46.2652 27 46.5196 27.1054 46.7071 27.2929C46.8946 27.4804 47 27.7348 47 28V46C47 46.2652 46.8946 46.5196 46.7071 46.7071C46.5196 46.8946 46.2652 47 46 47Z"
                      fill="#C8C8C8" />
                    <path
                      d="M27 2V4H21V2C21 1.73478 20.8946 1.48043 20.7071 1.29289C20.5196 1.10536 20.2652 1 20 1C19.7348 1 19.4804 1.10536 19.2929 1.29289C19.1054 1.48043 19 1.73478 19 2V11H21V6H27V11H29V2C29 1.73478 28.8946 1.48043 28.7071 1.29289C28.5196 1.10536 28.2652 1 28 1C27.7348 1 27.4804 1.10536 27.2929 1.29289C27.1054 1.48043 27 1.73478 27 2Z"
                      fill="#363636" />
                    <path
                      d="M38 47H10V10C10 9.73478 10.1054 9.48043 10.2929 9.29289C10.4804 9.10536 10.7348 9 11 9H37C37.2652 9 37.5196 9.10536 37.7071 9.29289C37.8946 9.48043 38 9.73478 38 10V47Z"
                      fill="#E3E3E3" />
                    <path
                      d="M20.5 23H16.5C16.3674 23 16.2402 22.9473 16.1464 22.8536C16.0527 22.7598 16 22.6326 16 22.5V20.5C16 20.3674 16.0527 20.2402 16.1464 20.1464C16.2402 20.0527 16.3674 20 16.5 20H20.5C20.6326 20 20.7598 20.0527 20.8536 20.1464C20.9473 20.2402 21 20.3674 21 20.5V22.5C21 22.6326 20.9473 22.7598 20.8536 22.8536C20.7598 22.9473 20.6326 23 20.5 23Z"
                      fill="#43A6DD" />
                    <path
                      d="M20.5 17H16.5C16.3674 17 16.2402 16.9473 16.1464 16.8536C16.0527 16.7598 16 16.6326 16 16.5V14.5C16 14.3674 16.0527 14.2402 16.1464 14.1464C16.2402 14.0527 16.3674 14 16.5 14H20.5C20.6326 14 20.7598 14.0527 20.8536 14.1464C20.9473 14.2402 21 14.3674 21 14.5V16.5C21 16.6326 20.9473 16.7598 20.8536 16.8536C20.7598 16.9473 20.6326 17 20.5 17Z"
                      fill="#43A6DD" />
                    <path
                      d="M20.5 29H16.5C16.3674 29 16.2402 28.9473 16.1464 28.8536C16.0527 28.7598 16 28.6326 16 28.5V26.5C16 26.3674 16.0527 26.2402 16.1464 26.1464C16.2402 26.0527 16.3674 26 16.5 26H20.5C20.6326 26 20.7598 26.0527 20.8536 26.1464C20.9473 26.2402 21 26.3674 21 26.5V28.5C21 28.6326 20.9473 28.7598 20.8536 28.8536C20.7598 28.9473 20.6326 29 20.5 29Z"
                      fill="#43A6DD" />
                    <path
                      d="M20.5 35H16.5C16.3674 35 16.2402 34.9473 16.1464 34.8536C16.0527 34.7598 16 34.6326 16 34.5V32.5C16 32.3674 16.0527 32.2402 16.1464 32.1464C16.2402 32.0527 16.3674 32 16.5 32H20.5C20.6326 32 20.7598 32.0527 20.8536 32.1464C20.9473 32.2402 21 32.3674 21 32.5V34.5C21 34.6326 20.9473 34.7598 20.8536 34.8536C20.7598 34.9473 20.6326 35 20.5 35Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 23H27.5C27.3674 23 27.2402 22.9473 27.1464 22.8536C27.0527 22.7598 27 22.6326 27 22.5V20.5C27 20.3674 27.0527 20.2402 27.1464 20.1464C27.2402 20.0527 27.3674 20 27.5 20H31.5C31.6326 20 31.7598 20.0527 31.8536 20.1464C31.9473 20.2402 32 20.3674 32 20.5V22.5C32 22.6326 31.9473 22.7598 31.8536 22.8536C31.7598 22.9473 31.6326 23 31.5 23Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 17H27.5C27.3674 17 27.2402 16.9473 27.1464 16.8536C27.0527 16.7598 27 16.6326 27 16.5V14.5C27 14.3674 27.0527 14.2402 27.1464 14.1464C27.2402 14.0527 27.3674 14 27.5 14H31.5C31.6326 14 31.7598 14.0527 31.8536 14.1464C31.9473 14.2402 32 14.3674 32 14.5V16.5C32 16.6326 31.9473 16.7598 31.8536 16.8536C31.7598 16.9473 31.6326 17 31.5 17Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 29H27.5C27.3674 29 27.2402 28.9473 27.1464 28.8536C27.0527 28.7598 27 28.6326 27 28.5V26.5C27 26.3674 27.0527 26.2402 27.1464 26.1464C27.2402 26.0527 27.3674 26 27.5 26H31.5C31.6326 26 31.7598 26.0527 31.8536 26.1464C31.9473 26.2402 32 26.3674 32 26.5V28.5C32 28.6326 31.9473 28.7598 31.8536 28.8536C31.7598 28.9473 31.6326 29 31.5 29Z"
                      fill="#43A6DD" />
                    <path
                      d="M31.5 35H27.5C27.3674 35 27.2402 34.9473 27.1464 34.8536C27.0527 34.7598 27 34.6326 27 34.5V32.5C27 32.3674 27.0527 32.2402 27.1464 32.1464C27.2402 32.0527 27.3674 32 27.5 32H31.5C31.6326 32 31.7598 32.0527 31.8536 32.1464C31.9473 32.2402 32 32.3674 32 32.5V34.5C32 34.6326 31.9473 34.7598 31.8536 34.8536C31.7598 34.9473 31.6326 35 31.5 35Z"
                      fill="#43A6DD" />
                    <path
                      d="M26 40H22C21.7348 40 21.4804 40.1054 21.2929 40.2929C21.1054 40.4804 21 40.7348 21 41V47H27V41C27 40.7348 26.8946 40.4804 26.7071 40.2929C26.5196 40.1054 26.2652 40 26 40Z"
                      fill="#363636" />
                  </svg> -->
              <v-list-item-content class="pt-0">
                <v-list-item-title class="text-start" style="
                    color: #000000;
                    font-size: 20px;
                    font-weight: 559;
                    line-height: 16px;
                    letter-spacing: -1px;
                  ">
                  Verified P&L
                </v-list-item-title>

                <v-list-item-subtitle class="text-start pt-1" style="
                    color: #666666;
                    font-size: 12px;
                    font-weight: 469;
                    line-height: 14px;
                  ">Proof of success-see and share your triumphant journey
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-spacer></v-spacer>

          <v-list class="pt-0" style="background-color: transparent">
            <v-list-item class="pl-0 pa-0" style="min-height: 0px">
              <!-- <img alt="" class="shrink" :src="require('@/assets/zebu-stocks/savetaxfund.svg')" width="16px" -->
              <!-- height="16px" /> -->
            </v-list-item>
            <!-- <v-list-item-content class="pa-0 pt-1">
                  <v-list-item-title class=" " style="color: #43A833;font-size: 12px;font-weight:559;line-height:16px;">2
                    recommended</v-list-item-title>
                </v-list-item-content> -->
          </v-list>
        </v-toolbar>
        <v-divider class="mx-2"></v-divider>
        <v-card color="#eeeeee" outlined class="mx-2 mt-4 d-md-none elevation-0 rounded-lg pa-6">
          <!-- <div class="text-center pa-4">
            <p class="caption" style="
                color: #696969;
                letter-spacing: 0.16em;
                text-transform: uppercase;
              ">
              Realised P&L
            </p>
            <p v-if="disrealised_pnl && disrealised_pnl < 0" class="headline ctnbtltext-center"
              style="line-height: 28px; color: #e12626">
              {{ disrealised_pnl.toFixed(2) }}
            </p>
            <p v-else-if="disrealised_pnl && disrealised_pnl > 0" class="headline ctnbtl text-center"
              style="line-height: 28px; color: #34a853">
              {{ disrealised_pnl.toFixed(2) }}
            </p>
            <p v-else class="headline ctnbtl text-center" style="line-height: 28px; color: #505359">
              0
            </p> 
          </div> -->
          <v-card outlined class="elevation-0 rounded-lg pa-4 pb-0">
            <v-row no-gutters>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="
                        color: #696969;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Realised P&L
                    </p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="disrealised_pnl < 0 && disrealised_pnl" class="ctnbtl body-1 text-right"
                      style="line-height: 28px; color: #e12626">
                      {{ disrealised_pnl.toFixed(2) }}
                    </p>
                    <p v-else-if="disrealised_pnl > 0 && disrealised_pnl" class="ctnbtl body-1 text-right"
                      style="line-height: 28px; color: #34a853">
                      {{ disrealised_pnl.toFixed(2) }}
                    </p>
                    <p v-else class="ctnbtl body-1 text-right" style="line-height: 28px; color: #505359">
                      0
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="
                        color: #696969;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Unrealised P&L
                    </p>
                  </v-col>
                  <v-col cols="7">
                    <p v-if="disunrealised_pnl < 0 && disunrealised_pnl" class="ctnbtl body-1 text-right"
                      style="line-height: 28px; color: #e12626">
                      {{ disunrealised_pnl.toFixed(2) }}
                    </p>
                    <p v-else-if="disunrealised_pnl > 0 && disunrealised_pnl" class="ctnbtl body-1 text-right"
                      style="line-height: 28px; color: #34a853">
                      {{ disunrealised_pnl.toFixed(2) }}
                    </p>
                    <p v-else class="ctnbtl body-1 text-right" style="line-height: 28px; color: #505359">
                      0
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row no-gutters>
                  <v-col cols="5">
                    <p class="caption" style="
                        color: #696969;
                        letter-spacing: 0.16em;
                        text-transform: uppercase;
                      ">
                      Total P&L
                    </p>
                  </v-col>
                  <v-col cols="7">
                    <span v-if="segloader" class="d-flex">
                      <v-progress-linear class="ml-auto" style="width: 30px" indeterminate
                        color="blue"></v-progress-linear>
                    </span>
                    <p v-else-if="segval < 0 && segval" class="ctnbtl body-1 text-right"
                      style="line-height: 28px; color: #e12626">
                      {{ segval.toFixed(2) }}
                    </p>
                    <p v-else-if="segval > 0 && segval" class="ctnbtl body-1 text-right"
                      style="line-height: 28px; color: #34a853">
                      {{ segval.toFixed(2) }}
                    </p>
                    <p v-else class="ctnbtl body-1 text-right" style="line-height: 28px; color: #505359">
                      0
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
        <div class="text-center">
          <v-bottom-sheet v-model="sheet" inset>
            <v-sheet class="text-center" height="200px">
              <!-- <v-btn class="mt-n6" icon text color="error" @click="sheet = !sheet">
                                <v-icon size="40">mdi mdi-close-circle</v-icon>
                            </v-btn> -->
              <!-- {{ radios }}/ -->
              <p class="mb-0 pt-3 title font-weight-medium">Summary P&L</p>
              <v-card class="elevation-0 ma-8 mt-0">
                <v-radio-group class="mt-0" v-model="radios" mandatory>
                  <v-radio color="black" label="Download Pdf" value="Download Pdf"></v-radio>
                  <v-radio color="black" label="Download Excel" value="Download Excel"></v-radio>
                </v-radio-group>
                <v-btn @click="downloadsum(radios)" class="white--text elevation-0" color="black" block rounded flat
                  solo>
                  {{ radios }}</v-btn>
              </v-card>
            </v-sheet>
          </v-bottom-sheet>
        </div>
        <div class="text-center">
          <v-bottom-sheet v-model="sheet1" inset>
            <v-sheet class="text-center" height="200px">
              <!-- <v-btn class="mt-n6" icon text color="error" @click="sheet1 = !sheet1">
                                <v-icon size="40">mdi mdi-close-circle</v-icon>
                            </v-btn> -->
              <!-- {{ radios }}/ -->
              <p class="mb-0 pt-3 title font-weight-medium">Detailed P&L</p>

              <v-card class="elevation-0 ma-8 mt-0">
                <v-radio-group class="mt-0" v-model="radios1" mandatory>
                  <v-radio color="black" label="Download Pdf" value="Download Pdf"></v-radio>
                  <v-radio color="black" label="Download Excel" value="Download Excel"></v-radio>
                </v-radio-group>
                <v-btn @click="downloaddet(radios1)" class="white--text elevation-0" color="black" block rounded flat
                  solo>
                  {{ radios1 }}</v-btn>
              </v-card>
            </v-sheet>
          </v-bottom-sheet>
          <v-bottom-sheet v-model="sheetforshare" inset>
            <v-sheet class="text-center" height="200px">
              <v-card class="elevation-0">
                <v-list-item-content class=" pt-5 pl-5">
                  <v-list-item-title class="text-start"
                    style="color: #000000;font-size: 18px;font-weight:559;line-height:24px;letter-spacing:-1px">Grab
                    your URL
                  </v-list-item-title>

                  <v-list-item-subtitle class="text-start pt-1"
                    style="color: #666666;font-size: 11px;font-weight:469;line-height:14px"> Click to share your
                    triumphant
                    journey.
                  </v-list-item-subtitle>

                </v-list-item-content>
                <v-divider></v-divider>

                <div class="px-6 mt-3 mb-2">
                  <p class="mb-1 caption">Share this link Or copy link</p>

                </div>
                <!-- <p class="mb-1 px-6 caption">Or copy link</p> -->
                <div class="d-flex">

                  <v-text-field readonly label="Regular" solo rounded flat dense background-color="#F1F3F8"
                    v-model="copytext" style="width: 60px" class="  ml-6" height="36px"></v-text-field>
                  <v-btn @click="copyTextclick" class="ml-2  text-capitalize white--text" solo text rounded flat dense
                    style="background-color: black;"><v-icon size="18">mdi mdi-content-copy</v-icon></v-btn>
                  <v-btn @click="shareTweet" class="mr-5 ml-2 text-capitalize white--text" solo text rounded flat dense
                    style="background-color: black;"><img src="@/assets/xmark.svg" width="12px" class="ml-1"
                      alt="Portfolio icon" /></v-btn>


                </div>

              </v-card>

            </v-sheet>
          </v-bottom-sheet>
        </div>
        <v-divider class="mt-4 mb-2"></v-divider>
        <div v-if="filteredDesserts.length > 0" class="d-flex row mt-2 ml-5">
          <p class=" mb-3 mt-8" style="
                        font-weight: 450;
                        font-size: 14px;
                        color: #4b4b4b;
                        line-height: 16px;
                        letter-spacing: -0.4px;
                      ">
            <v-icon size="16" color="green" class="pt-n2">mdi mdi-check-circle</v-icon>
            P&L verified by Zebu
            <!-- <v-icon color="blue" class="mt-n1">mdi mdi-menu-right</v-icon> -->
          </p>
          <div class="ml-auto">
            <p class="mt-2 mr-7 mb-0 " style="font-size: 12px">Share to Everyone</p>
            <div class="d-flex">
              <v-switch color="black" @change="switchbtn" class="mt-1" v-model="agreeforshare" inset dense></v-switch>
              <v-btn class="mt-2 px-3" disabled rounded x-small v-if="!agreeforshare">
                <v-icon class="" size="16">mdi mdi-share-variant</v-icon>
              </v-btn>
              <v-btn dark class="mt-2 px-3" rounded x-small v-else @click="sharetomobile">
                <v-icon color="white" class="" size="16">mdi mdi-share-variant</v-icon>
              </v-btn>

            </div>
          </div>
        </div>
        <v-divider class="mt-4 mb-2"></v-divider>

        <v-divider class="mt-4 mb-2"></v-divider>
        <div class="d-flex row">
          <p v-if="summary" class="text-start mt-3 mb-3 ml-2 px-4" style="
              color: #696969;
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
            ">
            No of symbols
            <span class="pa-1 rounded-lg" style="color: #000000">({{ dessertsoriginal.length }})</span>
          </p>
          <p v-if="!summary" class="text-start mt-3 mb-3 ml-2 px-4" style="
              color: #696969;
              font-size: 12px;
              font-weight: 500;
              line-height: 16px;
            ">
            No of symbols
            <span class="pa-1 rounded-lg" style="color: #000000">({{ desserts23.length }})</span>
          </p>
          <!-- <v-btn v-if="summary" @click="sheet = true" class="ml-auto mr-6" flat solo icon>
            <v-icon>mdi mdi-download</v-icon></v-btn>
          <v-btn v-if="!summary" @click="sheet1 = true" class="ml-auto mr-6" flat solo icon>
            <v-icon>mdi mdi-download</v-icon></v-btn> -->
        </div>
        <v-divider class="mb-2 mt-2"></v-divider>
        <v-row class="px-5 pa-0 mt-3">
          <v-col class="pa-0 px-2">

            <v-text-field style="width: 100%" class="tophundraedmutual" height="36px" background-color="#F1F3F8"
              label="Search" v-model="searchde" solo rounded flat dense>
              <template v-slot:prepend-inner>
                <img alt="" class="shrink" :src="require('@/assets/searchicon.svg')" width="20px" height="18px" />
              </template>
            </v-text-field>
          </v-col>
          <v-col>
            <!-- <v-select :items="filterr" :disabled="segloader" @change="filterseg(filterdata_unpl)" dense v-model="filterdata_unpl"
                            placeholder="filter" solo rounded flat background-color="#F1F3F8" hide-details
                            class="mr-2 mt-n3 fildrop" style="max-width: 100%; font-size: 21px">
                        </v-select> -->
            <!-- <v-select v-if="!summary" :items="filterr" @change="filterseg1(filterdata_unpl1)" dense
                            v-model="filterdata_unpl1" placeholder="filter" solo rounded flat background-color="#F1F3F8"
                            hide-details class="mr-2 mt-n3 fildrop" style="max-width: 100%; font-size: 21px">
                        </v-select> -->
            <v-select :readonly="segloader" :loading="segloader" :items="filterr" @change="filterseg(filterdata_unpl)"
              dense v-model="filterdata_unpl" placeholder="filter" solo rounded flat background-color="#F1F3F8"
              hide-details class="mr-2 mt-n2 fildrop" style="max-width: 140px; font-size: 21px; overflow: hidden">
            </v-select>
          </v-col>
        </v-row>
        <v-menu class="" :offset-x="offset" left v-model="menu2" :close-on-content-click="false"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field style="max-width: 88%" class="my-2 mx-4" solo rounded flat v-model="fromshow"
              label="From date" dense color="black" background-color="#F1F3F8" prepend-inner-icon="mdi-calendar"
              readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <!-- <v-date-picker color="black" v-model="fromdate"></v-date-picker> -->
          <v-date-picker color="black" v-model="todate" @change="gettradedata()" :allowed-dates="allowedDates"
            :max="maxval"></v-date-picker>
        </v-menu>
        <v-row class="pt-0 mt-n9">
          <v-col lg="4" sm="4" md="12" cols="12">
            <!-- <v-tabs v-model="tab" background-color="transparent" color="basil" grow> -->
            <!-- <v-tab>
                               
                            </v-tab> -->
            <!-- <v-tab @click="detailed()">
                                Tax P&L
                            </v-tab> -->
            <!-- </v-tabs> -->
          </v-col>
        </v-row>
        <!-- {{ loader }} -->
        <v-dialog fullscreen transition="dialog-bottom-transition" v-model="dialogmobile">
          <v-sheet class="text-center" style="height: 100vh">
            <v-list-item-content class="pt-5 pl-5">
              <v-list-item-title class="text-start" style="
                  color: #000000;
                  font-size: 18px;
                  font-weight: 559;
                  line-height: 24px;
                  letter-spacing: -1px;
                ">
                Detailed P&L</v-list-item-title>

              <v-list-item-subtitle class="text-start pt-1" style="
                  color: #666666;
                  font-size: 11px;
                  font-weight: 469;
                  line-height: 14px;
                ">
                {{ scriptname }}
              </v-list-item-subtitle>
              <v-btn absolute right top icon color="black" @click="dialogmobile = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-content>
            <v-divider></v-divider>

            <v-card color="#eeeeee" outlined class="d-md-none elevation-0 rounded-lg pa-6 mt-3 px-3" height="90%"
              style="overflow: scroll">
              <div class="text-center pa-4">
                <p class="caption" style="
                    color: #696969;
                    letter-spacing: 0.16em;
                    text-transform: uppercase;
                  ">
                  Total
                </p>
                <p v-if="finaltotal < 0" class="ctnbtl headline mb-0" style="line-height: 28px; color: red">
                  {{ finaltotal.toFixed(2) }}
                </p>
                <p v-if="finaltotal > 0" class="ctnbtl headline mb-0" style="line-height: 28px; color: green">
                  {{ finaltotal }}
                </p>
                <p v-if="finaltotal == 0" class="ctnbtl headline mb-0" style="line-height: 28px; color: rgb(0, 0, 0)">
                  {{ finaltotal }}
                </p>
                <p v-if="finaltotal == ''" class="ctnbtl headline mb-0" style="line-height: 28px">
                  -
                </p>
              </div>
              <v-card v-for="item in detailedpoparray.data" :key="item.index" style="border-top: 5px #f1f3f8 solid"
                class="elevation-0 px-4" width="100%">
                <span class="d-flex row mt-3 mb-3 px-4">
                  <p class="pb-0 mb-0" style="color: #000000; font-size: 15px; font-weight: 459">
                    {{ item.TRADE_DATE }}
                  </p>
                  <p v-if="item.NETAMT > 0" class="mb-0 ml-auto body-2 font-weight-regular" style="color: green">
                    {{ item.NETAMT }}
                  </p>
                  <p v-if="item.NETAMT < 0" class="mb-0 ml-auto body-2 font-weight-regular" style="color: red">
                    {{ item.NETAMT }}
                  </p>
                  <p v-if="item.NETAMT == 0" class="mb-0 ml-auto body-2 font-weight-regular" style="color: #000000">
                    {{ item.NETAMT }}
                  </p>
                </span>

                <v-divider class="mb-2"></v-divider>
                <v-row class="px-2">
                  <v-col>
                    <p class="mb-1 body-2 font-weight-regular text-start" style="color: #666666">
                      Net Quantity:
                      <span class="mb-0 body-2 font-weight-regular text-start" style="color: #000000">{{ item.NETQTY
                        }}</span>
                    </p>
                    <p class="mb-1 body-2 font-weight-regular text-start" style="color: #666666">
                      Buy Quantity:
                      <span class="mb-0 body-2 font-weight-regular text-start" style="color: #000000">{{ item.BQTY
                        }}</span>
                    </p>
                    <p class="mb-3 body-2 font-weight-regular text-start" style="color: #666666">
                      Buy Rate :
                      <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{
                        item.BRATE }}</span>
                    </p>
                  </v-col>
                  <v-col class="text-end">
                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666">
                      Net Rate:
                      <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{
                        item.NRATE.toFixed(2) }}
                      </span>
                    </p>
                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666">
                      Sale Quantity:
                      <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{
                        item.SQTY }}
                      </span>
                    </p>
                    <p class="mb-3 body-2 font-weight-regular" style="color: #666666">
                      Sale Rate :
                      <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{
                        item.SRATE.toFixed(2) }}
                      </span>
                    </p>
                  </v-col>
                </v-row>
              </v-card>
              <v-card v-if="loaderpopup" style="
                  border-top: 5px #f1f3f8 solid;
                  border-bottom: 5px #f1f3f8 solid;
                " class="elevation-0 px-4" width="100%">
                <v-row class="px-2">
                  <v-col class="mx-auto text-center my-4">
                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                  </v-col>
                </v-row>
              </v-card>
              <v-card v-if="detailedpoparray.data == 0 && loaderpopup == false" style="
                  border-top: 5px #f1f3f8 solid;
                  border-bottom: 5px #f1f3f8 solid;
                " class="elevation-0 px-4" width="100%">
                <v-row class="px-2">
                  <v-col class="mx-auto text-center my-4">
                    <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                    <p class="font-weight-bold black--text">
                      No data available
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>
          </v-sheet>
        </v-dialog>
        <!-- <v-tabs-items v-model="tab"> -->
        <!-- <v-tab-item> -->
        <v-card v-for="item in filteredDesserts" :key="item.index" style="border-top: 5px #f1f3f8 solid"
          class="elevation-0 px-4 pt-2" width="100%">
          <!-- <v-chip
              v-if="item.open_QUANTITY > 0"
              color="blue"
              class="ml-2"
              x-small
              outlined
              >{{ item.open_QUANTITY }}</v-chip
            > -->
          <span class="font-weight-bold">
            {{ item.SCRIP_SYMBOL
            }}
            <v-tooltip v-if="item.OPEN_QUANTITY != ''" v-model="item.show1" top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on" color="blue" class="ml-2" x-small outlined>{{
                  item.OPEN_QUANTITY }}</v-chip>
              </template>
              <span>Open Quantity!</span>
            </v-tooltip>

            <!-- {{ item.COMPANY_CODE }} -->
            <!-- <span class="caption font-weight-medium">({{
desserts2[item.SCRIP_SYMBOL].length }})</span> -->
          </span>
          <v-divider class="mb-2 mt-2"></v-divider>
          <v-row class="px-2">
            <v-col>
              <p class="mb-1 body-2 font-weight-regular" style="color: #666666">
                P & L
                <span v-if="item.realized_pnl + item.unrealized_pnl > 0" class="mb-0 body-2 font-weight-regular"
                  style="color: green">
                  {{ (item.realized_pnl + item.unrealized_pnl).toFixed(2) }}
                </span>
                <span v-if="item.realized_pnl + item.unrealized_pnl < 0" class="mb-0 body-2 font-weight-regular"
                  style="color: red">
                  {{ (item.realized_pnl + item.unrealized_pnl).toFixed(2) }}
                </span>
                <span v-if="item.realized_pnl + item.unrealized_pnl == 0" class="mb-0 body-2 font-weight-regular"
                  style="color: #000000">
                  {{ (item.realized_pnl + item.unrealized_pnl).toFixed(2) }}
                </span>
              </p>


              <span class="mb-1 body-2 font-weight-regular" style="color: #666666">
                Realised :
                <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{ (item.realized_pnl).toFixed(2)
                  }}</span>
              </span>
              <p class="mb-3 body-2 font-weight-regular" style="color: #666666">
                Unrealised :
                <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{ (item.unrealized_pnl).toFixed(2)
                  }}</span>
              </p>
            </v-col>
            <v-col class="text-end">
              <p class="mb-1 body-2 font-weight-regular" style="color: #666666">
                Qty :
                <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{ item.NET_QUANTITY
                  }}
                </span>
              </p>


              <p class="mb-1 body-2 font-weight-regular" style="color: #666666">
                Avg :
                <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{ item.NET_RATE
                  }}
                </span>
              </p>
              <p class="mb-3 body-2 font-weight-regular" style="color: #666666">
                Close Price :
                <span class="mb-0 body-2 font-weight-regular" style="color: #000000">{{ item.CLOSING_PRICE
                  }}</span>
              </p>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-if="filteredDesserts.length == 0 && loader == false" style="
            border-top: 5px #f1f3f8 solid;
            border-bottom: 5px #f1f3f8 solid;
          " class="elevation-0 px-4" width="100%">
          <v-row class="px-2">
            <v-col class="mx-auto text-center my-4">
              <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
              <p class="font-weight-bold black--text">No data available</p>
            </v-col>
          </v-row>
        </v-card>

        <v-card v-if="loader" style="
            border-top: 5px #f1f3f8 solid;
            border-bottom: 5px #f1f3f8 solid;
          " class="elevation-0 px-4" width="100%">
          <v-row class="px-2">
            <v-col class="mx-auto text-center my-10">
              <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
        <!-- </v-tab-item> -->
        <!-- <v-tab-item>
                        <v-card v-for="item in filteredDesserts1 " :key="item.index" style="border-top: 5px #F1F3F8 solid;"
                            class="elevation-0  px-4" width="100%">

                            <p v-if="item.COMPANY_CODE == 'NSE_FNO'" class="text-start pa-2 pb-0 mb-0"
                                style="color: #000000;font-size: 15px;font-weight:459;">{{ item.SCRIP_SYMBOL }}
                            </p>
                            <p v-else-if="item.COMPANY_CODE == 'BSE_CASH' || item.COMPANY_CODE == 'NSE_CASH'"
                                class="text-start pb-0 pa-2 mb-0" style="color: #000000;font-size: 15px;font-weight:459;">
                                {{ item.SCRIP_SYMBOL.split(' ').slice(1).join(' ') }}
                            </p>
                            <span class="font-weight-regular pt-0 caption text-start pa-2 mb-0"> {{ item.TRADE_DATE
                            }}</span>
                            <v-divider class="mb-2"></v-divider>

                            <v-row class="px-2">
                                <v-col>
                                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Net Amount:
                                    </p>
                                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Buy Qty: <span
                                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.BQTY
                                            }}</span>
                                    </p>
                                    <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Buy Rate : <span
                                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                (item.BRATE.toFixed(2)) }}</span>
                                    </p>


                                </v-col>
                                <v-col class="text-end">
                                    <p v-if="item.NETAMT > 0" class="mb-0 body-2 font-weight-regular" style="color: green;">
                                        {{ item.NETAMT }}</p>
                                    <p v-if="item.NETAMT < 0" class="mb-0 body-2 font-weight-regular" style="color: red;">{{
                                        item.NETAMT }}</p>
                                    <p v-if="item.NETAMT == 0" class="mb-0 body-2 font-weight-regular"
                                        style="color: #000000;">
                                        {{ item.NETAMT }}</p>

                                    <p class="mb-1 body-2 font-weight-regular" style="color: #666666;">Sell Qty: <span
                                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{ item.SQTY }}

                                        </span>
                                    </p>
                                    <p class="mb-3 body-2 font-weight-regular" style="color: #666666;">Sell Rate : <span
                                            class="mb-0 body-2 font-weight-regular" style="color: #000000;">{{
                                                (item.SRATE).toFixed(2) }}
                                        </span>
                                    </p>


                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card v-if="filteredDesserts1.length == 0 && loader == false"
                            style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                            class="elevation-0  px-4" width="100%">
                            <v-row class="px-2">
                                <v-col class="mx-auto text-center my-4">
                                    <img alt="" class="shrink" src="../../../assets/nodata.svg" width="15%" height="" />
                                    <p class="font-weight-bold black--text">No data available</p>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card v-if="loader" style="border-top: 5px #F1F3F8 solid; border-bottom: 5px #F1F3F8 solid ;"
                            class="elevation-0  px-4" width="100%">
                            <v-row class="px-2">
                                <v-col class="mx-auto text-center my-4">
                                    <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items> -->
      </div>
    </v-container>
  </v-app>
</template>

<script>
// import CalendarProfitlose from '@/views/heatmap calendars/CalendarProfitlose.vue';
import { apiurl } from "../../../Api.js";
import { apidailypnl } from "../../../Api.js";
import exportFromJSON from "export-from-json";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
// import html2canvas from 'html2canvas';


export default {
  data() {
    return {
      copytext: '',
      dialogmobile: false,
      username: '',
      segval: 0,
      summary: true,
      radios: null,
      radios1: null,
      inject: {
        theme: {
          default: { isDark: false },
        },
      },
      itemss: [],
      search: "",
      searchde: "",
      yearsarray: [],
      expanded: [],
      more: true,
      less: false,
      more1: true,
      less1: false,
      itemperpage: 8,
      // msg: "",

      // alert: false,

      sheet: false,
      sheet1: false,
      sheetforshare: false,
      itemperpage1: 8,
      loader: true,
      headerval: [],
      dialog: false,
      dialogcopytext: false,
      detailedpoparray: [],

      yearindex: "",
      fromdate: [],
      search1: "",

      itemperpage1inner: 0,
      maxminyear: '',
      // tab: null,
      // yearfromdown: ["2018", "2019", "2020", "2021", "2022", "2023"],
      desserts: [],
      dessertsoriginal: [],
      scriptname: "",
      dessertspop: [],
      dessertspopexpense: [],

      desserts2: [],
      uniqueCode: '',
      sortBy: "SCRIP_NAME",
      offset: "",
      segfrom: "",
      dates: [""],
      filterr: [
        "All",
        "Equities",
        "Future & Options",
        "Commodities",
        "Currencies",
      ],
      filterdata_unpl: "All",
      orderformobile: false,
      filterdata_unpl1: "All",
      itemsdwl: [
        {
          title: "Download Excel",
        },
        {
          title: "Download Pdf",
        },
      ],
      show: false,
      show1: false,
      alert: false,
      alert1: false,
      msg: '',
      Overviewheaders2: [
        {
          text: "Trade Date",
          value: "TRADE_DATE",
          width: "10%",
          class: "headerfont",
        },

        // { text: "Symbol", value: "SCRIP_NAME", width: "20%", class: "headerfont" },

        {
          text: "Buy Qty",
          value: "BQTY",
          align: "end",
          class: "headerfont",
          width: "10%",
        },
        {
          text: "Buy Rate",
          value: "BRATE",
          align: "end",
          class: "headerfont",
          width: "10%",
        },
        // { text: 'Buy Amt', value: 'BAMT', align: "end" },
        {
          text: "Sell Qty",
          value: "SQTY",
          align: "end",
          class: "headerfont",
          width: "10%",
        },
        {
          text: "Sell Rate",
          value: "SRATE",
          align: "end",
          class: "headerfont",
          width: "10%",
        },
        // { text: 'Sale Amt', value: 'SAMT', align: "end" },
        {
          text: "Net Qty",
          value: "NETQTY",
          align: "end",
          class: "headerfont",
          width: "10%",
        },
        {
          text: "Net Rate",
          value: "NRATE",
          align: "end",
          class: "headerfont",
          width: "10%",
        },
        // { text: 'NET Amt', value: 'NETAMT', align: "end" },
        // { text: 'Closing Price', value: 'CLSPR', align: "end", class: "headerfont" },
        {
          text: "P & L",
          value: "NETAMT",
          align: "end",
          class: "headerfont",
          width: "10%",
        },
      ],
      finaltotal: 0,

      Overviewheaders: [
        { text: "Symbol", value: "SCRIP_NAME1", class: "headerfont" },
        // { text: "com", value: "Sort_No", class: "headerfont", },
        // { text: "comcode", value: "COMPANY_CODE", class: "headerfont", },
        // { text: '', value: 'data-table-expand', class: "headerfont" },
        // { text: "Trading Quantity", value: "TRADING_QUANTITY" , align: 'end'},
        // { text: "Trading Amt", value: "TRADING_AMOUNT" , align: 'end'},
        {
          text: "Qty",
          value: "NET_QUANTITY",
          align: "end",
          class: "headerfont",
        },
        { text: "Avg", value: "NET_RATE", align: "end", class: "headerfont" },
        // { text: "BAmt", value: "BUY_AMOUNT", align: "end", class: "headerfont" },
        {
          text: "Close Price",
          value: "CLOSING_PRICE",
          align: "end",
          class: "headerfont",
        },
        {
          text: "Unrealized P&L",
          value: "unrpnl",
          align: "end",
          class: "headerfont",
        },
        // { text: "SAmt", value: "SALE_AMOUNT", align: "end", class: "headerfont" },
        {
          text: "Realized P&L",
          value: "rpnl",
          align: "end",
          class: "headerfont",
        },
        // { text: "Net Rate", value: "", align: "end", class: "headerfont" },
        // { text: "Close price", value: "", align: "end", class: "headerfont" },
        // { text: "NAmt", value: "NET_AMOUNT", align: "end", class: "headerfont" },
        // { text: "Closing Price", value: "CLOSING_PRICE", align: "end", class: "headerfont" },
        {
          text: "P & L",
          value: "NOT_PROFIT",
          align: "end",
          class: "headerfont",
        },
      ],
      tab: null,
      items: ["Summary", "Detailed"],
      desserts23: [],
      sortKey: "Sort_No",
      detpop: [],
      actid: "",
      susertoken: "",
      loaderpopup: false,
      keyyy: "",
      downsum: true,
      disrealised_pnl: "",
      disunrealised_pnl: "",
      year: "",
      dessertstaxdis: [],
      menu2: false,
      menu23: false,
      todate: "",
      fromtodate: [],
      highlightyears: '',
      segloader: false,
      fromshow: "",
      fromdisplay: '',
      sortOrder: 1, // 1 for ascending, -1 for descending
      originalrea: 0,
      originalunrea: 0,
      maxval: '',
      switchval: '',
      agreeforshare: false,
      shareresdata: [],
      text1: 'kgghkhkhh',
      postalready: null,
      postalreadystats: '',
      postalreadyucode: '',
      switch1: true,
      switch2: false,
    };
  },
  // components: { CalendarProfitlose },
  computed: {
    sortedItems() {
      return this.dessertsoriginal.slice().sort((a, b) => {
        const modifier = this.sortOrder;
        return modifier * (a[this.sortKey] > b[this.sortKey] ? 1 : -1);
      });
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    filteredDesserts() {
      const searchTerm = this.search.toLowerCase();
      return this.desserts.filter((item) =>
        item.SCRIP_SYMBOL.toLowerCase().includes(searchTerm)
      );
    },
    filteredDesserts1() {
      const searchTerm = this.searchde.toLowerCase();
      return this.desserts23.filter((item) =>
        item.SCRIP_SYMBOL.toLowerCase().includes(searchTerm)
      );
    },
  },
  created() {
    // Perform default sort on component creation
    this.performDefaultSort();
  },
  methods: {
    yearlist() {
      var today = new Date();
      let sevthdate = new Date(today);

      let mmsevthdate = String(sevthdate.getMonth() + 1).padStart(2, '0')

      let yyyysevthdate = sevthdate.getFullYear();
      if (mmsevthdate < 4) {
        var yearmount = yyyysevthdate - 1
      } else {
        yearmount = yyyysevthdate
      }
      var startYear = yearmount - 4;
      this.yearis = yearmount;

      for (let year = yearmount; year >= startYear; year--) {
        // let yeararraytem = []

        this.yearsarray.push(year)
      }
      this.yearsarray = this.yearsarray.slice(2, 5)
      // console.log(this.yearsarray)
    },
    copyTextclick() {
      navigator.clipboard.writeText(this.copytext);
      this.dialogcopytext = false
      this.sheetforshare = false
      this.alert = true,
        this.msg = 'URL Copied'

      setTimeout(() => {
        this.alert = false,
          this.msg = ''

      }, 3000);
    },
    datemaxvalue(item) {
      let valuefordate = this.fromtodate
      // console.log(this.fromtodate);
      this.minval = valuefordate[0]
      this.highlightyears = ''

      this.fromshow = valuefordate[0].split("-")[2] + "/" + valuefordate[0].split("-")[1] + "/" + valuefordate[0].split("-")[0]
      this.toshow = valuefordate[1].split("-")[2] + "/" + valuefordate[1].split("-")[1] + "/" + valuefordate[1].split("-")[0]
      this.fromdisplay = this.fromshow + '_to_' + this.toshow
      // console.log(valuefordate.length);
      //    if (this.fromdate.length < 1) {
      //         this.fromdate = valuefordate
      //         console.log(this.fromdate.length,this.fromdate)

      //    }else{
      //         this.fromdate = []
      //         this.fromdate = valuefordate
      // console.log(valuefordate[0].split("-")[0])

      //    }

      // this.maxval = `${Number(valuefordate.split("-")[0]) + 1}-03-31`
      // console.log(valuefordate,this.maxval,typeof(Number(valuefordate.split("-")[0])))
      // console.log(valuefordate[0])
      // console.log(Number(valuefordate[1]))
      // if (condition) {

      // }
      // if (Number(valuefordate[0].split("-")[1]) > 3) {
      //   this.maxval = `${Number(valuefordate[0].split("-")[0]) + 1}-03-31`
      //   // console.log(this.maxval,"huhu");
      //   // if (item != 'mounted') {
      //   //     this.todate = `${Number(valuefordate.split("-")[0]) + 1}-03-31`
      //   // }
      //   // console.log(this.maxval,"1")
      // }
      // else {
      //   this.maxval = `${Number(valuefordate[0].split("-")[0])}-03-31`
      //   // console.log(this.maxval,"2")
      //   // console.log(this.maxval,"huhu");

      //   // if (item != 'mounted') {
      //   //         this.todate = `${Number(valuefordate.split("-")[0])}-03-31`
      //   // }
      // }

      if (item == 'click' && valuefordate.length > 1) {
        this.gettradedata()
        this.highlightyears = ''
      }
    },
    switchbtn() {
      // alert('jhvh')
      // this.agreeforshare = !this.agreeforshare
      //console.log(this.agreeforshare);
      if (this.agreeforshare == true) {
        if (this.postalready == false) {
          // console.log('cond 1');
          // this.postalreadyucode = ''
          const axios = require('axios');
          let data = JSON.stringify({
            "cc": this.actid,
            "date": this.fromshow,
            "sharing": this.agreeforshare,
            "response": this.shareresdata,
            "Unique_Code": "",
            "uname": this.username

          });
          let axiosthis = this
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            // url: 'http://192.168.5.198:5050/sharenew',
            url: `${apiurl}/sharenew`,

            headers: {
              'Content-Type': 'application/json',
              'clientid': this.actid,
              'Authorization': this.susertoken
            },
            data: data
          };

          axios.request(config)
            .then((response) => {
              // //console.log(JSON.stringify(response.data));
              let res = response.data.data.uq_code ? response.data.data : []
              // console.log(response.data.data[0].uq_code);

              let val = res.uq_code
              //console.log(val);
              axiosthis.postalreadyucode = val
              axiosthis.uniqueCode = val
              axiosthis.postalready = true
              // https://reports-cf9df.web.app/Profit_Loss?uid=${actid}&token=${susertoken}"

              axiosthis.copytext = `${apidailypnl}/dailypnl?ucode=${axiosthis.postalreadyucode}`
              // axiosthis.copytext = `http://localhost:8081/dailypnl?ucode=${axiosthis.postalreadyucode}`
              // axiosthis.copytext = `http://192.168.5.183:8081/dailypnl?ucode=${axiosthis.postalreadyucode}`
            })
            .catch((error) => {
              console.log(error);
              if (error.message == 'Network Error') {
                this.msg = error.message
                axiosthis.loader = false
                axiosthis.alert1 = true
                this.agreeforshare = false
                setTimeout(() => {
                  axiosthis.alert1 = false
                  this.msg = ''
                }, 5000);
                // alert(error.message)
              }
            });
        } else {
          // console.log('cond 2');

          const axios = require('axios');
          let data = JSON.stringify({
            "cc": '',
            "date": '',
            "sharing": this.agreeforshare,
            "response": '',
            "Unique_Code": this.postalreadyucode,
            "uname": ''

          });
          let axiosthis = this
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            // url: 'http://192.168.5.198:5050/sharenew',
            url: `${apiurl}/sharenew`,

            headers: {
              'Content-Type': 'application/json',
              'clientid': this.actid,
              'Authorization': this.susertoken
            },
            data: data
          };

          axios.request(config)
            .then((response) => {
              // //console.log(JSON.stringify(response.data));
              let res = response.data.data.uq_code ? response.data.data : []

              let val = res.uq_code
              //console.log(val);
              axiosthis.postalreadyucode = val
              axiosthis.uniqueCode = val
              // axiosthis.uniqueCode = val
              // // https://reports-cf9df.web.app/Profit_Loss?uid=${actid}&token=${susertoken}"

              // axiosthis.copytext = `http://localhost:8080/dailypnl?ucode=${axiosthis.uniqueCode}`
              // // axiosthis.copytext = `https://report.mynt.in/dailypnl?ucode=${axiosthis.uniqueCode}`
            })
            .catch((error) => {
              console.log(error);
              if (error.message == 'Network Error') {
                this.msg = error.message
                axiosthis.loader = false
                axiosthis.alert1 = true
                this.agreeforshare = false
                setTimeout(() => {
                  axiosthis.alert1 = false
                  this.msg = ''
                }, 5000);
                // alert(error.message)
              }
            });
        }


      } else if (this.agreeforshare == false) {
        // console.log('cond 3');

        const axios = require('axios');
        let data = JSON.stringify({
          "cc": '',
          "date": '',
          "sharing": this.agreeforshare,
          "response": '',
          "Unique_Code": this.postalreadyucode,
          "uname": ''


        });
        let axiosthis = this
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          // url: 'http://192.168.5.198:5050/sharenew',
          url: `${apiurl}/sharenew`,

          headers: {
            'Content-Type': 'application/json',
            'clientid': this.actid,
            'Authorization': this.susertoken
          },
          data: data
        };

        axios.request(config)
          .then((response) => {
            // //console.log(JSON.stringify(response.data));
            let res = response.data.data.uq_code ? response.data.data : []

            let val = res.uq_code
            //console.log(val);
            axiosthis.postalreadyucode = val
            axiosthis.uniqueCode = val
            // // https://reports-cf9df.web.app/Profit_Loss?uid=${actid}&token=${susertoken}"

            // axiosthis.copytext = `http://localhost:8080/dailypnl?ucode=${axiosthis.uniqueCode}`
            // // axiosthis.copytext = `https://report.mynt.in/dailypnl?ucode=${axiosthis.uniqueCode}`
          })
          .catch((error) => {
            console.log(error);
            if (error.message == 'Network Error') {
              this.msg = error.message
              axiosthis.loader = false
              axiosthis.alert1 = true
              this.agreeforshare = false
              setTimeout(() => {
                axiosthis.alert1 = false
                this.msg = ''
              }, 5000);
              // alert(error.message)
            }
          });

      }
    },
    // async takeScreenshot() {
    //   // Replace these values with the coordinates and dimensions of the area you want to capture
    //   const x = 300;
    //   const y = 0;
    //   const width = 800;
    //   const height = 600;

    //   // Scroll to the specified position
    //   window.scrollTo(x, y);

    //   // Wait for a short time to ensure content is loaded
    //   await this.$nextTick();

    //   // Use html2canvas to capture the screenshot
    //   const canvas = await html2canvas(document.body, {
    //     x,
    //     y,
    //     width,
    //     height,
    //   });

    //   // Get the data URL of the screenshot
    //   const screenshotDataUrl = canvas.toDataURL('image/png');

    //   // Print the image URL in base64 to the //console
    //   //console.log('Screenshot URL:', screenshotDataUrl);
    // },
    // takeScreenshot() {
    //     // Get the element you want to capture (replace 'target-element' with the actual ID or class)
    //     const targetElement = document.getElementById('target-element');

    //     // Use html2canvas to capture the screenshot
    //     html2canvas(targetElement)
    //         .then((canvas) => {
    //             // Convert the canvas to a base64 data URL
    //             const dataUrl = canvas.toDataURL('image/png');

    //             // Print the image URL in base64 to the //console
    //             //console.log('Screenshot URL:', dataUrl);
    //         })
    //         .catch((error) => {
    //             //console.error('Error capturing screenshot:', error);
    //         });
    // },
    //   takeScreenshot() {
    //   // Replace these values with the coordinates and dimensions of the area you want to capture
    //   const x = 0;
    //   const y = 0;
    //   const width = 800;
    //   const height = 600;

    //   // Scroll to the specified position
    //   window.scrollTo(x, y);

    //   // Use the browser's built-in screenshot functionality
    //   window.scrollTo(x, y);
    //   const screenshotDataUrl = window.screenshot.capture({ format: 'png', width, height });

    //   // Print the image URL in base64 to the //console
    //   //console.log('Screenshot URL:', screenshotDataUrl);
    // },
    // takeScreenshot() {
    //   // Replace 'target-element' with the actual ID or class of the element you want to capture
    //   const targetElement = document.getElementById('target-element');

    //   // Use html2canvas to capture the screenshot
    //   html2canvas(targetElement, {
    //     // Add options if needed
    //   })
    //     .then((canvas) => {
    //       // Convert the canvas to a base64 data URL
    //       const dataUrl = canvas.toDataURL('image/png');

    //       // Print the image URL in base64 to the //console
    //       //console.log('Screenshot URL:', dataUrl);
    //     })
    //     .catch((error) => {
    //       //console.error('Error capturing screenshot:', error);
    //     });
    // },
    shareto() {
      // alert('kjghkjh')
      this.dialogcopytext = true
    },
    sharetomobile() {
      // alert('kjghkjh')
      this.sheetforshare = true
    },
    allowedDates: val => {
      var dayOfWeek = new Date(val).getDay();
      return dayOfWeek !== 6 && dayOfWeek !== 0; // Disable Saturdays (6) and Sundays (0)
    },
    shareTweet() {
      //console.log("Custom action: Tweet button clicked!");

      //   const twitterIntentUrl = "https://twitter.com/intent/tweet?text=custom+share+text&hashtags=example,demo&via=twitterdev&related=twitterapi,twitter";
      //   window.open(twitterIntentUrl, '_blank');
      const tweetUrl = "https://twitter.com/intent/tweet";
      // let urlfortweet = 'https://report.mynt.in/dailypnl?ucode=' + this.postalreadyucode
      let urlfortweet = 'http://profile.mynt.in/dailypnl?ucode=' + this.postalreadyucode
      const tweetParams = {
        // url : `https://report.mynt.in/dailypnl?ucode=${axiosthis.uniqueCode}`
        // url: "https://zebuetrade.com/",
        text: "Excited about my recent trading triumph using Zebu—profits surging! Skillful moves on the Zebu app have significantly boosted my success",
        hashtags: "Traders",
        via: "zebuetrade",
        related: "twitterapi,twitter",
        // url : `http://localhost:8080/dailypnl?ucode=${this.uniqueCode}`,
        url: urlfortweet,

        media:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Instagram_logo_2022.svg/1200px-Instagram_logo_2022.svg.png",
      };

      const queryString = Object.keys(tweetParams)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(tweetParams[key])
        )
        .join("&");

      window.open(`${tweetUrl}?${queryString}`, "_blank");
    },
    performDefaultSort() {
      this.sortItems();
    },
    sortItems() {
      this.dessertsoriginal.sort((a, b) => {
        const modifier = this.sortOrder;
        return modifier * (a[this.sortKey] > b[this.sortKey] ? 1 : -1);
      });
    },
    gettradedata(item) {
      // console.log();

      this.postalreadyucode = ''
      this.menu2 = false;
      this.menu23 = false;
      this.disrealised_pnl = 0;
      this.disunrealised_pnl = 0;
      this.disrealised_pnl = 0;
      this.segval = 0;
      this.headerval.expense_amt = 0;
      this.agreeforshare = false
      this.postalready = false

      //   this.eqloader = true
      //   this.dessertsexpand = []
      //   this.dessertsexpandderivative = []
      //   this.dessertsexpandcommodity = []
      //   this.dessertsexpandcurrency = []

      this.disabletabs = true;
      this.dessertsoriginal = [];
      this.desserts = [];
      this.filterdata_unpl1 = "All";
      this.filterdata_unpl = "All";
      // if (this.todate != "") {
      this.loader = true;
      if (item == 'lseven') {
        this.fromtodate = []
        let today = new Date();
        let yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 7);
        let dd = yesterday.getDate();
        let mm = yesterday.getMonth() + 1; // January is 0!
        let yyyy = yesterday.getFullYear();
        // Pad single digit day and month with leading zero 
        let fromapi = dd + "/" + mm + "/" + yyyy;
        // console.log(fromapi, "jkjkjkjkjkjkjkjkk"); // Output: "31/03/2024" (if today is 01/04/2024)
        let todatapi = new Date(today);
        todatapi.setDate(today.getDate());
        let dd1 = todatapi.getDate();
        let mm1 = todatapi.getMonth() + 1; // January is 0!
        let yyyy1 = todatapi.getFullYear();

        let today1 = dd1 + "/" + mm1 + "/" + yyyy1;
        // let fromapi =  ''
        // let today1 =  ''
        this.fromtodate.push(yyyy + "-" + mm.toString().padStart(2, '0') + "-" + dd.toString().padStart(2, '0'));
        this.fromtodate.push(yyyy1 + "-" + mm1.toString().padStart(2, '0') + "-" + dd1.toString().padStart(2, '0'));



        
        this.toshow = today1;
        this.fromshow = fromapi;
        this.fromdisplay = this.fromshow + '_to_' + this.toshow
        this.maxval = yyyy1 + '-' + mm1 + '-' + dd1
        // this.highlightyears = item
      }

      // this.equitiesdata()
      // this.derivativesdat()
      const axios = require("axios");
      let data = JSON.stringify({
        cc: this.actid,
        from: this.fromshow,
        to: this.toshow,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        // url: 'http://192.168.5.198:5050/getdailypnl',
        url: `${apiurl}/getdailypnl`,

        headers: {
          "Content-Type": "application/json",
          'clientid': this.actid,
          'Authorization': this.susertoken
        },
        data: data,
      };
      let axiosthis = this;
      axios
        .request(config)
        .then((response) => {
          // //console.log(JSON.stringify(response.data));
          if (response.data.msg != "No Data Available") {
            axiosthis.loader = false;
            axiosthis.shareresdata = response.data
            axiosthis.desserts = response.data["trades"];
            axiosthis.dessertspop = response.data["trades"];
            axiosthis.dessertsoriginal = axiosthis.desserts;
            axiosthis.itemperpage = axiosthis.desserts.length;
            axiosthis.disrealised_pnl = response.data.realized_pnl
            axiosthis.disunrealised_pnl = response.data.unrealized_pnl
            axiosthis.originalrea = response.data.realized_pnl
            axiosthis.originalunrea = response.data.unrealized_pnl
            for (let i = 0; i < axiosthis.desserts.length; i++) {
              axiosthis.segval += axiosthis.desserts[i].NOT_PROFIT;
            }
          } else if (response.data.msg == "No Data Available") {
            axiosthis.loader = false;
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.message == "Network Error") {
            this.msg = error.message;
            axiosthis.loader = false;
            axiosthis.alert1 = true;
            setTimeout(() => {
              axiosthis.alert1 = false;
            }, 5000);
            // alert(error.message)
          }
        });

      // this.equitiesdata()
      // this.derivativesdat()
      let data1 = JSON.stringify({
        "cc": this.actid,
        "date": this.fromshow
      });

      let config1 = {
        method: 'post',
        maxBodyLength: Infinity,
        // url: 'http://192.168.5.198:5050/getclientsharelist',
        url: `${apiurl}/getclientsharelist`,

        headers: {
          'Content-Type': 'application/json',
          'clientid': this.actid,
          'Authorization': this.susertoken
        },
        data: data1
      };
      axiosthis.this = this
      axios.request(config1)
        .then((response) => {
          //console.log(JSON.stringify(response.data));
          let postval = response.data.msg
          let res = response.data.data.length > 0 ? response.data.data[0] : []
          //console.log(res);
          axiosthis.postalreadyucode = res.uq_code
          axiosthis.postalreadystats = res.sharing
          axiosthis.copytext = `${apidailypnl}/dailypnl?ucode=${axiosthis.postalreadyucode}`

          // axiosthis.copytext = `http://localhost:8081/dailypnl?ucode=${axiosthis.postalreadyucode}`
          // axiosthis.copytext = `http://192.168.5.183:8081/dailypnl?ucode=${axiosthis.postalreadyucode}`



          axiosthis.uniqueCode = axiosthis.postalreadyucode
          if (axiosthis.postalreadystats == 'True') {
            axiosthis.agreeforshare = true
          } else {
            axiosthis.agreeforshare = false
          }
          //console.log(axiosthis.postalreadystats, axiosthis.postalreadyucode);
          if (postval == 'No Data Available') {
            axiosthis.postalready = false
            axiosthis.agreeforshare = false

          } else {
            axiosthis.postalready = true
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // }
    },


    filterseg1(filterdata_unpl1) {
      var data = this.dessertspop;
      // var data1 = this.dessertspop
      // var headrea = this.disrealised_pnl;
      // var headrea1 = this.disunrealised_pnl;
      // var totalseg = this.segval
      const seg = filterdata_unpl1;
      if (seg == "Equities") {
        var addval = 0;
        var unaddval = 0;
        var total = 0
        this.desserts = [];
        for (let i = 0; i < data.length; i++) {
          if (
            data[i].COMPANY_CODE == "BSE_CASH" ||
            data[i].COMPANY_CODE == "NSE_CASH" ||
            data[i].COMPANY_CODE == "MF_BSE" ||
            data[i].COMPANY_CODE == "MF_NSE" ||
            data[i].COMPANY_CODE == "NSE_SLBM" ||
            data[i].COMPANY_CODE == "NSE_SPT"
          ) {
            this.desserts.push(data[i]);
          }
        }
        //console.log("test", this.desserts)

        for (let y = 0; y < this.desserts.length; y++) {
          if (this.desserts[y].pnl_type == 'Realized') {

            addval += this.desserts[y].NOT_PROFIT;
          }
        }
        //console.log(addval)
        this.disrealised_pnl = addval;

        for (let y = 0; y < this.desserts.length; y++) {
          if (this.desserts[y].pnl_type == 'UnRealized') {
            unaddval += this.desserts[y].NOT_PROFIT;
          }
        }
        //console.log(unaddval)

        this.disunrealised_pnl = unaddval;
        total = this.desserts.reduce((sum, item) => sum + Number(item.NOT_PROFIT), 0);

        //console.log(total)

        this.segval = total;


      } else if (seg == "Future & Options") {
        this.desserts = [];
        let addval = 0;
        let unaddval = 0;

        // const data1 = this.dessertsoriginal
        for (let i = 0; i < data.length; i++) {
          if (
            data[i].COMPANY_CODE == "NSE_FNO" ||
            data[i].COMPANY_CODE == "BSE_FNO"
          ) {
            this.desserts.push(data[i]);
          }
        }
        //console.log("test", this.desserts);
        for (let y = 0; y < this.desserts.length; y++) {
          if (this.desserts[y].pnl_type == 'Realized') {
            addval += this.desserts[y].NOT_PROFIT;
          }
        }
        this.disrealised_pnl = addval;
        for (let y = 0; y < this.desserts.length; y++) {
          if (this.desserts[y].pnl_type == 'UnRealized') {
            unaddval += this.desserts[y].NOT_PROFIT;
          }
        }
        this.disunrealised_pnl = unaddval;
        //console.log(this.segval);
        const total = this.desserts.reduce((sum, item) => sum + Number(item.NOT_PROFIT), 0);


        this.segval = total;
        //console.log(this.segval);

        //console.log("test", this.disrealised_pnl, this.disunrealised_pnl, this.segval);

      } else if (seg == "Commodities") {
        this.desserts = [];
        let addval = 0;
        let unaddval = 0;

        // const data1 = this.dessertsoriginal
        for (let i = 0; i < data.length; i++) {
          if (
            data[i].COMPANY_CODE == "MCX" ||
            data[i].COMPANY_CODE == "NCDEX" ||
            data[i].COMPANY_CODE == "NSE_COM" ||
            data[i].COMPANY_CODE == "BSE_COM"
          ) {
            this.desserts.push(data[i]);
          }
        }
        //console.log("test", this.desserts);
        for (let y = 0; y < this.desserts.length; y++) {
          if (this.desserts[y].pnl_type == 'Realized') {

            addval += this.desserts[y].NOT_PROFIT;
          }
        }

        this.disrealised_pnl = addval;
        for (let y = 0; y < this.desserts.length; y++) {
          if (this.desserts[y].pnl_type == 'UnRealized') {
            unaddval += this.desserts[y].NOT_PROFIT;
          }
        }
        this.disunrealised_pnl = unaddval;
        const total = this.desserts.reduce((sum, item) => sum + Number(item.NOT_PROFIT), 0);

        this.segval = total;
        //console.log("test", this.disrealised_pnl, this.disunrealised_pnl, this.segval);

        ////console.log(this.dessertsoriginal);
        // for (let y = 0; y < this.dessertsoriginal.length; y++) {
        //   if (this.dessertsoriginal[y].NET_QUANTITY == 0) {
        //     addval += this.dessertsoriginal[y].NOT_PROFIT;
        //   }
        // }
        // this.disrealised_pnl = addval;
        // for (let y = 0; y < this.dessertsoriginal.length; y++) {
        //   if (this.dessertsoriginal[y].NET_QUANTITY != 0) {
        //     unaddval += this.dessertsoriginal[y].NOT_PROFIT;
        //   }
        // }
        // this.disunrealised_pnl = unaddval;
        // //console.log(this.disrealised_pnl, this.disunrealised_pnl);

        // // // ////console.log(this.dessertsoriginal, "this.dessertsoriginal");
      } else if (seg == "Currencies") {
        this.desserts = [];
        let addval = 0;
        let unaddval = 0;
        let total = 0
        // const data1 = this.dessertsoriginal
        //console.log("test", this.desserts);

        for (let i = 0; i < data.length; i++) {
          if (
            data[i].COMPANY_CODE == "CD_NSE" ||
            data[i].COMPANY_CODE == "CD_MCX" ||
            data[i].COMPANY_CODE == "CD_USE" ||
            data[i].COMPANY_CODE == "CD_BSE"
          ) {
            this.desserts.push(data[i]);
          }
        }
        for (let y = 0; y < this.desserts.length; y++) {
          if (this.desserts[y].pnl_type == 'Realized') {

            addval += this.desserts[y].NOT_PROFIT;
          }
        }

        this.disrealised_pnl = addval;
        for (let y = 0; y < this.desserts.length; y++) {
          if (this.desserts[y].pnl_type == 'UnRealized') {
            unaddval += this.desserts[y].NOT_PROFIT;
          }
        }
        this.disunrealised_pnl = unaddval;
        total = this.desserts.reduce((sum, item) => sum + Number(item.NOT_PROFIT), 0);

        this.segval = total;
        //console.log("test", this.disrealised_pnl, this.disunrealised_pnl, this.segval);

        // for (let y = 0; y < this.dessertsoriginal.length; y++) {
        //   if (this.dessertsoriginal[y].NET_QUANTITY == 0) {
        //     addval += this.dessertsoriginal[y].NOT_PROFIT;
        //   }
        // }
        // this.disrealised_pnl = addval;
        // for (let y = 0; y < this.dessertsoriginal.length; y++) {
        //   if (this.dessertsoriginal[y].NET_QUANTITY != 0) {
        //     unaddval += this.dessertsoriginal[y].NOT_PROFIT;
        //   }
        // }
        // this.disunrealised_pnl = unaddval;
        // //console.log(this.disrealised_pnl, this.disunrealised_pnl);

        // // // ////console.log(this.dessertsoriginal, "this.dessertsoriginal");
      } else if (seg == "All") {
        this.desserts = data;

        this.disrealised_pnl = this.originalrea;
        this.disunrealised_pnl = this.originalunrea
        const total = data.reduce((sum, item) => sum + Number(item.NOT_PROFIT), 0);
        this.segval = total;
      }

    },
    filterseg(filterdata_unpl) {
      var data = this.dessertspop;
      // var data1 = this.dessertspop
      // var headrea = this.disrealised_pnl;
      // var headrea1 = this.disunrealised_pnl;
      // var totalseg = this.segval
      const seg = filterdata_unpl;
      if (seg == "Equities") {
        var addval = 0;
        var unaddval = 0;
        var total = 0
        this.desserts = [];
        for (let i = 0; i < data.length; i++) {
          if (
            data[i].COMPANY_CODE == "BSE_CASH" ||
            data[i].COMPANY_CODE == "NSE_CASH" ||
            data[i].COMPANY_CODE == "MF_BSE" ||
            data[i].COMPANY_CODE == "MF_NSE" ||
            data[i].COMPANY_CODE == "NSE_SLBM" ||
            data[i].COMPANY_CODE == "NSE_SPT"
          ) {
            this.desserts.push(data[i]);
          }
        }
        //console.log("test", this.desserts)

        for (let y = 0; y < this.desserts.length; y++) {

          addval += this.desserts[y].realized_pnl;
        }
        //console.log(addval)
        this.disrealised_pnl = addval;

        for (let y = 0; y < this.desserts.length; y++) {
          unaddval += this.desserts[y].unrealized_pnl;
        }
        //console.log(unaddval)

        this.disunrealised_pnl = unaddval;
        total = this.desserts.reduce((sum, item) => sum + Number(item.NOT_PROFIT), 0);

        //console.log(total)

        this.segval = this.disunrealised_pnl + this.disrealised_pnl;


      } else if (seg == "Future & Options") {
        this.desserts = [];
        let addval = 0;
        let unaddval = 0;

        // const data1 = this.dessertsoriginal
        for (let i = 0; i < data.length; i++) {
          if (
            data[i].COMPANY_CODE == "NSE_FNO" ||
            data[i].COMPANY_CODE == "BSE_FNO"
          ) {
            this.desserts.push(data[i]);
          }
        }
        //console.log("test", this.desserts);
        for (let y = 0; y < this.desserts.length; y++) {

          addval += this.desserts[y].realized_pnl;
        }
        //console.log(addval)
        this.disrealised_pnl = addval;

        for (let y = 0; y < this.desserts.length; y++) {
          unaddval += this.desserts[y].unrealized_pnl;
        }
        //console.log(unaddval)

        this.disunrealised_pnl = unaddval;
        total = this.desserts.reduce((sum, item) => sum + Number(item.NOT_PROFIT), 0);

        //console.log(total)

        this.segval = this.disunrealised_pnl + this.disrealised_pnl;
        //console.log(this.segval);

        //console.log("test", this.disrealised_pnl, this.disunrealised_pnl, this.segval);

      } else if (seg == "Commodities") {
        this.desserts = [];
        let addval = 0;
        let unaddval = 0;

        // const data1 = this.dessertsoriginal
        for (let i = 0; i < data.length; i++) {
          if (
            data[i].COMPANY_CODE == "MCX" ||
            data[i].COMPANY_CODE == "NCDEX" ||
            data[i].COMPANY_CODE == "NSE_COM" ||
            data[i].COMPANY_CODE == "BSE_COM"
          ) {
            this.desserts.push(data[i]);
          }
        }
        //console.log("test", this.desserts);
        for (let y = 0; y < this.desserts.length; y++) {

          addval += this.desserts[y].realized_pnl;
        }
        //console.log(addval)
        this.disrealised_pnl = addval;

        for (let y = 0; y < this.desserts.length; y++) {
          unaddval += this.desserts[y].unrealized_pnl;
        }
        //console.log(unaddval)

        this.disunrealised_pnl = unaddval;
        total = this.disunrealised_pnl + this.disrealised_pnl;

        //console.log(total)

        this.segval = total
        //console.log("test", this.disrealised_pnl, this.disunrealised_pnl, this.segval);

        ////console.log(this.dessertsoriginal);
        // for (let y = 0; y < this.dessertsoriginal.length; y++) {
        //   if (this.dessertsoriginal[y].NET_QUANTITY == 0) {
        //     addval += this.dessertsoriginal[y].NOT_PROFIT;
        //   }
        // }
        // this.disrealised_pnl = addval;
        // for (let y = 0; y < this.dessertsoriginal.length; y++) {
        //   if (this.dessertsoriginal[y].NET_QUANTITY != 0) {
        //     unaddval += this.dessertsoriginal[y].NOT_PROFIT;
        //   }
        // }
        // this.disunrealised_pnl = unaddval;
        // //console.log(this.disrealised_pnl, this.disunrealised_pnl);

        // // // ////console.log(this.dessertsoriginal, "this.dessertsoriginal");
      } else if (seg == "Currencies") {
        this.desserts = [];
        let addval = 0;
        let unaddval = 0;
        let total = 0
        // const data1 = this.dessertsoriginal
        //console.log("test", this.desserts);

        for (let i = 0; i < data.length; i++) {
          if (
            data[i].COMPANY_CODE == "CD_NSE" ||
            data[i].COMPANY_CODE == "CD_MCX" ||
            data[i].COMPANY_CODE == "CD_USE" ||
            data[i].COMPANY_CODE == "CD_BSE"
          ) {
            this.desserts.push(data[i]);
          }
        }
        for (let y = 0; y < this.desserts.length; y++) {

          addval += this.desserts[y].realized_pnl;
        }
        //console.log(addval)
        this.disrealised_pnl = addval;

        for (let y = 0; y < this.desserts.length; y++) {
          unaddval += this.desserts[y].unrealized_pnl;
        }
        //console.log(unaddval)

        this.disunrealised_pnl = unaddval;
        total = this.disunrealised_pnl + this.disrealised_pnl;

        //console.log(total)

        this.segval = total
        //console.log("test", this.disrealised_pnl, this.disunrealised_pnl, this.segval);

        // for (let y = 0; y < this.dessertsoriginal.length; y++) {
        //   if (this.dessertsoriginal[y].NET_QUANTITY == 0) {
        //     addval += this.dessertsoriginal[y].NOT_PROFIT;
        //   }
        // }
        // this.disrealised_pnl = addval;
        // for (let y = 0; y < this.dessertsoriginal.length; y++) {
        //   if (this.dessertsoriginal[y].NET_QUANTITY != 0) {
        //     unaddval += this.dessertsoriginal[y].NOT_PROFIT;
        //   }
        // }
        // this.disunrealised_pnl = unaddval;
        // //console.log(this.disrealised_pnl, this.disunrealised_pnl);

        // // // ////console.log(this.dessertsoriginal, "this.dessertsoriginal");
      } else if (seg == "All") {
        this.desserts = data;

        this.disrealised_pnl = this.originalrea;
        this.disunrealised_pnl = this.originalunrea
        const total = this.disunrealised_pnl + this.disrealised_pnl
        this.segval = total;
      }

    },
    downloaddet(item) {
      this.sheet1 = false;
      this.radios1 = null;
      let data = item;
      // // // ////console.log("dsdsdsdsds", data);

      if (data == "Download Excel") {
        // const data = this.desserts23;
        // let jsonObj = JSON.parse(data);
        // data.forEach(function (v) { delete v.ACCOUNTCODE }, { delete v.ACCOUNTNAME });
        let dada = this.desserts23;
        // let jsonObj = JSON.parse(data);
        // data.forEach(function (v) { delete v.ACCOUNTCODE }, { delete v.ACCOUNTNAME });
        //                 dada.forEach(function (v) {
        //                     // delete v.SCRIP_SYMBOL

        //                     delete v.MARKET
        // ;
        //                     // delete v.NSE_SCRIPCODE;
        //                     delete v.EXCHANGE
        // ;
        //                     delete v.COMPANY_CODE
        //                     delete v.CLOSING_PRICE
        //                 });
        const exportType = exportFromJSON.types.csv;
        const filename = `${"Detailed_"}${"Profit and Loss_"}${this.year}`;
        if (dada)
          exportFromJSON({
            data: dada,
            filename: filename,
            exportType: exportType,
          });
      } else if (data == "Download Pdf") {
        let rows = [];
        let columnHeader = [
          "Trade date",
          "Symbol",
          "Buy Qty",
          "Buy Rate",
          "Sell Qty",
          "Sell Rate",
          "Net Qty",
          "Net Rate",
          "P&L",
        ];
        let pdfName = `${"Detailed_Profit and loss_"}${this.yearis}`;
        // doc.text(`Ledger_${this.yearis}`, 10, 10);
        // doc.text('Zebu', 10 ,10)
        this.desserts23.forEach((element) => {
          var temp = [
            element.TRADE_DATE,
            element.SCRIP_NAME,
            element.BQTY,
            element.BRATE,
            element.SQTY,
            element.SRATE,
            element.NETQTY,
            element.NRATE,
            element.PRO,
          ];
          rows.push(temp);
        });
        var doc = new jsPDF();
        autoTable(doc, { head: [columnHeader], body: rows });
        doc.save(pdfName + ".pdf");
      }
    },
    downloadsum(item) {
      this.sheet = false;
      this.radios = null;

      let data = item;
      // // ////console.log("dsdsdsdsds", data);

      if (data == "Download Excel") {
        let dada = this.dessertsoriginal;
        // let jsonObj = JSON.parse(data);
        // data.forEach(function (v) { delete v.ACCOUNTCODE }, { delete v.ACCOUNTNAME });
        // vgb
        const exportType = exportFromJSON.types.csv;
        const filename = `${"Summary_"}${"Profit and Loss_"}${this.year}`;
        // // ////console.log("dada",this.year,dada)
        if (dada) {
          // // ////console.log("inside",dada);
          exportFromJSON({
            data: dada,
            fileName: filename,
            exportType: exportType,
          });
        }
      } else if (data == "Download Pdf") {
        let rows = [];
        let columnHeader = [
          "Symbol",
          "Buy Qty",
          "Buy Rate",
          "Sell Qty",
          "Sell Rate",
          "Net Qty",
          "Net Rate",
          "P & L",
        ];
        let pdfName = `${"Summary_Profit and loss_"}${this.yearis}`;
        // doc.text(`Ledger_${this.yearis}`, 10, 10);
        // doc.text('Zebu', 10 ,10)
        this.dessertsoriginal.forEach((element) => {
          var temp = [
            element.SCRIP_SYMBOL,
            element.BUY_QUANTITY,
            element.BUY_RATE,
            element.SALE_QUANTITY,
            element.SALE_RATE,
            element.NET_QUANTITY,
            element.NET_RATE,
            element.NOT_PROFIT,
          ];
          rows.push(temp);
        });
        var doc = new jsPDF();
        autoTable(doc, { head: [columnHeader], body: rows });
        doc.save(pdfName + ".pdf");
      }
    },
    detailed() {
      this.summary = false;
    },
    summary1() {
      this.summary = true;
    },
    lessbtn() {
      this.more = true;
      this.less = false;
      this.itemperpage = 8;
    },
    morebtn() {
      this.more = false;
      this.less = true;
      this.itemperpage = this.dessertsoriginal.length;
    },
    lessbtn1() {
      this.more1 = true;
      this.less1 = false;
      this.itemperpage1 = 8;
    },
    morebtn1() {
      this.more1 = false;
      this.less1 = true;
      this.itemperpage1 = this.desserts23.length;
    },
    mainmountedfunction() {
      this.yearlist()
      const axios = require("axios");
      let data = JSON.stringify({
        cc: this.actid,
        from: this.fromshow,
        to: this.toshow,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        // url: 'http://192.168.5.198:5050/getdailypnl',
        url: `${apiurl}/getdailypnl`,

        headers: {
          "Content-Type": "application/json",
          'clientid': this.actid,
          'Authorization': this.susertoken
        },
        data: data,
      };
      let axiosthis = this;
      axios
        .request(config)
        .then((response) => {

          // //console.log(JSON.stringify(response.data));
          if (response.data.msg != "No Data Available") {
            axiosthis.loader = false;
            axiosthis.shareresdata = response.data
            axiosthis.desserts = response.data["trades"];
            axiosthis.dessertspop = response.data["trades"];

            axiosthis.dessertsoriginal = axiosthis.desserts;
            axiosthis.itemperpage = axiosthis.desserts.length;

            axiosthis.disrealised_pnl = response.data.realized_pnl
            axiosthis.disunrealised_pnl =
              response.data.unrealized_pnl
            axiosthis.originalrea =
              response.data.realized_pnl
            axiosthis.originalunrea =
              response.data.unrealized_pnl
            for (let i = 0; i < axiosthis.desserts.length; i++) {
              axiosthis.segval += axiosthis.desserts[i].NOT_PROFIT;
            }
          } else if (response.data.msg == "No Data Available") {
            axiosthis.loader = false;
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.message == "Network Error") {
            this.msg = error.message;
            axiosthis.loader = false;
            axiosthis.alert1 = true;
            setTimeout(() => {
              axiosthis.alert1 = false;
            }, 5000);
            // alert(error.message)
          }
        });
      // const axios = require('axios');
      // const axios = require('axios');
      let data1 = JSON.stringify({
        "cc": this.actid,
        "date": this.fromshow
      });

      let config1 = {
        method: 'post',
        maxBodyLength: Infinity,
        // url: 'http://192.168.5.198:5050/getclientsharelist',
        url: `${apiurl}/getclientsharelist`,

        headers: {
          'Content-Type': 'application/json',
          'clientid': this.actid,
          'Authorization': this.susertoken
        },
        data: data1
      };

      axios.request(config1)
        .then((response) => {
          //console.log(JSON.stringify(response.data));
          let postval = response.data.msg
          let res = response.data.data.length > 0 ? response.data.data[0] : []
          //console.log(res);
          axiosthis.postalreadyucode = res.uq_code
          axiosthis.postalreadystats = res.sharing
          axiosthis.copytext = `${apidailypnl}/dailypnl?ucode=${axiosthis.postalreadyucode}`

          axiosthis.uniqueCode = axiosthis.postalreadyucode
          if (axiosthis.postalreadystats == 'True') {
            axiosthis.agreeforshare = true
          } else {
            axiosthis.agreeforshare = false
          }
          //console.log(axiosthis.postalreadystats, axiosthis.postalreadyucode);
          if (postval == 'No Data Available') {
            axiosthis.postalready = false
            axiosthis.agreeforshare = false

          } else {
            axiosthis.postalready = true
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    reset() {
      this.minval = this.maxminyear + '-' + '04' + '-' + '01'
      this.fromtodate = []
    },
  },

  mounted() {
    // Example usage
    // var someDate = "2023-12-26"; // Replace this with the date you want to check
    // var isDateAllowed = this.allowedDates(someDate);

    // if (isDateAllowed) {
    //   //console.log("The date is allowed!");
    // } else {
    //   //console.log("The date is not allowed!");
    // }
    // Example URL with query parameters
    // const urlString = "http://www.example.com/path?param1=value1&param2=value2";

    // Create a URL object
    // const url = new URL(urlString);

    // Get the value of a specific parameter
    // const paramValue = url.searchParams.get("param1");

    // //console.log(paramValue,"cfcfcfcfcfc");

    // //console.log(this.allowedDates);
    // var urlparams = new URL(window.location.href);
    //console.log(urlparams, "hghghg");
    this.highlightyears = 'lseven'

    this.postalreadyucode = ''
    this.menu2 = false;
    this.menu23 = false;
    this.disrealised_pnl = 0;
    this.disunrealised_pnl = 0;
    this.disrealised_pnl = 0;
    this.segval = 0;
    this.headerval.expense_amt = 0;
    this.agreeforshare = false
    this.postalready = false

    //   this.eqloader = true
    //   this.dessertsexpand = []
    //   this.dessertsexpandderivative = []
    //   this.dessertsexpandcommodity = []
    //   this.dessertsexpandcurrency = []

    this.disabletabs = true;
    this.dessertsoriginal = [];
    this.desserts = [];
    this.filterdata_unpl1 = "All";
    this.filterdata_unpl = "All";
    this.susertoken = localStorage.getItem("usession");
    this.actid = localStorage.getItem("userid");
    this.username = localStorage.getItem("cname");
    this.loader = true;




    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 7);
    let dd = yesterday.getDate();
    let mm = yesterday.getMonth() + 1; // January is 0!
    let yyyy = yesterday.getFullYear();
    // Pad single digit day and month with leading zero 
    let fromapi = dd + "/" + mm + "/" + yyyy;
    // console.log(fromapi, "jkjkjkjkjkjkjkjkk"); // Output: "31/03/2024" (if today is 01/04/2024)
    let todatapi = new Date(today);
    todatapi.setDate(today.getDate());
    let dd1 = todatapi.getDate();
    let mm1 = todatapi.getMonth() + 1; // January is 0!
    let yyyy1 = todatapi.getFullYear();

    let today1 = dd1 + "/" + mm1 + "/" + yyyy1;
    // let fromapi =  ''
    // let today1 =  ''
    this.fromtodate.push(yyyy + "-" + mm.toString().padStart(2, '0') + "-" + dd.toString().padStart(2, '0'));
    this.fromtodate.push(yyyy1 + "-" + mm1.toString().padStart(2, '0') + "-" + dd1.toString().padStart(2, '0'));



    this.maxval = yyyy + '-' + mm + '-' + dd
    this.toshow = today1;
    this.fromshow = fromapi;
    this.segfrom = fromapi;

    this.filterdata_unpl1 = "All";
    this.filterdata_unpl = "All";
    this.postalreadyucode = ''
    // console.log(this.toshow, this.fromshow);
    this.fromdisplay = this.fromshow + '_' + 'to' + '_' + this.toshow
    // this.toshow = today1
    // this.fromshow = this.fromshow + '-' + this.toshow
    var isDateAllowed = this.allowedDates(this.maxval);
    // this.mainmountedfunction()
    // console.log(isDateAllowed,date);
    if (isDateAllowed) {
      // console.log('date yes ok');
      this.mainmountedfunction()
      this.fromdisplay = this.fromshow + '_' + 'to' + '_' + this.toshow

    } else {

      let today = new Date();
      let yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 8);
      let dd = yesterday.getDate();
      let mm = yesterday.getMonth() + 1; // January is 0!
      let yyyy = yesterday.getFullYear();
      // Pad single digit day and month with leading zero 
      let fromapi = dd + "/" + mm + "/" + yyyy;
      // console.log(fromapi, "jkjkjkjkjkjkjkjkk"); // Output: "31/03/2024" (if today is 01/04/2024)
      let todatapi = new Date(today);
      todatapi.setDate(today.getDate());

      let dd1 = todatapi.getDate();
      let mm1 = todatapi.getMonth() + 1; // January is 0!
      let yyyy1 = todatapi.getFullYear();

      let today1 = dd1 + "/" + mm1 + "/" + yyyy1;
      // let fromapi =  ''
      // let today1 =  ''
      this.maxval = yyyy + '-' + mm + '-' + dd
      this.toshow = today1;
      this.fromshow = fromapi;
      this.segfrom = fromapi;

      this.filterdata_unpl1 = "All";
      this.filterdata_unpl = "All";
      this.postalreadyucode = ''
      // console.log(this.toshow, this.fromshow);

      this.fromdisplay = this.fromshow + '_' + 'to' + '_' + this.toshow
      this.fromtodate.push(yyyy + "-" + mm.toString().padStart(2, '0') + "-" + dd.toString().padStart(2, '0'));
      this.fromtodate.push(yyyy1 + "-" + mm1.toString().padStart(2, '0') + "-" + dd1.toString().padStart(2, '0'));




      let isDateAllowed = this.allowedDates(this.maxval);
      if (isDateAllowed) {
        // console.log('date yes 2 ok');
        this.fromdisplay = this.fromshow + '_' + 'to' + '_' + this.toshow

        this.mainmountedfunction()
      } else {
        let today = new Date();
        let yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 10);
        let dd = yesterday.getDate();
        let mm = yesterday.getMonth() + 1; // January is 0!
        let yyyy = yesterday.getFullYear();
        // Pad single digit day and month with leading zero 
        let fromapi = dd + "/" + mm + "/" + yyyy;
        // console.log(fromapi, "jkjkjkjkjkjkjkjkk"); // Output: "31/03/2024" (if today is 01/04/2024)
        let todatapi = new Date(today);
        todatapi.setDate(today.getDate());

        let dd1 = todatapi.getDate();
        let mm1 = todatapi.getMonth() + 1; // January is 0!
        let yyyy1 = todatapi.getFullYear();

        let today1 = dd1 + "/" + mm1 + "/" + yyyy1;
        // let fromapi =  ''
        // let today1 =  ''
        this.maxval = yyyy + '-' + mm + '-' + dd
        this.toshow = today1;
        this.fromshow = fromapi;
        this.segfrom = fromapi;

        this.filterdata_unpl1 = "All";
        this.filterdata_unpl = "All";
        this.postalreadyucode = ''
        // console.log(this.toshow, this.fromshow);

        this.fromdisplay = this.fromshow + '_' + 'to' + '_' + this.toshow

        this.fromtodate.push(yyyy + "-" + mm.toString().padStart(2, '0') + "-" + dd.toString().padStart(2, '0'));
        this.fromtodate.push(yyyy1 + "-" + mm1.toString().padStart(2, '0') + "-" + dd1.toString().padStart(2, '0'));
        // console.log('date yes 3 ok');
        this.mainmountedfunction()
        // let isDateAllowed = this.allowedDates(this.maxval);
      }
    }
    this.minval = yyyy + '-' + '04' + '-' + '01'
    this.maxminyear = yyyy
    // console.log(this.maxval);
    this.maxval = this.fromtodate[1]
    // this.minval = this.fromtodate[0] 

  },
};
</script>

<style lang="scss">
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
}



.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}


input:checked+.slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.basil {
  background-color: #fffbe6 !important;
}

.basil--text {
  color: #020202 !important;
}

.v-tab {
  text-transform: none !important;
}

.v-tabs--grow>.v-tabs-bar .v-tab {
  /* flex: 1 0 auto; */
  max-width: none !important;
}
</style>