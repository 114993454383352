<template>
    <div id="app" class="mt-13 ml-16">
        <div class="d-flex">
            <p class="ml-4 title">News Ai</p>
            <!-- <p class="ml-auto mr-10">dfghj</p> -->
            <!-- <v-select  class="ml-auto mr-8" :items="['Time']" filled label="Filled style" dense></v-select> -->
            <v-menu open-on-hover bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ml-auto mr-8" icon dark v-bind="attrs" v-on="on">
                        <v-icon color="black">mdi mdi-sort</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="sortchange()">
                        <v-list-item-title style="cursor: pointer;">Time</v-list-item-title>

                    </v-list-item>
                    <v-list-item @click="sortchange()">
                        <v-list-item-title style="cursor: pointer;">Score</v-list-item-title>

                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
        <v-divider class=" mx-2"></v-divider>
        <v-card class="mt-8 elevation-0 pa-8" style="height: 100%; width: 98%; background-color: #E5EBEC;" rounded
            outlined>
            <!-- {{MainData}} -->
            <v-expansion-panels>
                <v-expansion-panel style="border-radius: 20px;" class="mb-4" v-for="(data, index) in sortedMainData"
                    :key="index">
                    <v-expansion-panel-header>
                        <v-card data-aos="fade-left" class="elevation-0" style="height: 100px; border-radius: 10px">
                            <v-row>
                                <v-col cols="1">
                                    <img class="" :src="'https://images.unsplash.com/photo-1518770660439-4636190af475'"
                                        alt="Image" style="border-radius: 10px;" width="125px" height="95px" />

                                </v-col>
                                <v-col>
                                    <div class="ml-8 mt-4">
                                        <div class="row">
                                            <div>
                                                <p class="caption mt-3 mb-0 grey--text">
                                                    {{ data.time.split(",")[0] }} {{ data.time.split(",")[1] }}
                                                </p>
                                                <p style="font-size: 11px;" class="mb-1 grey--text">
                                                    {{ data.time.split(",")[2] }}
                                                </p>
                                            </div>

                                            <!-- Loop through sectors and display v-chips directly in template -->
                                            <div>
                                                <v-chip
                                                    v-for="(item, chipIndex) in data.sector.split(',').map(s => s.trim())"
                                                    :key="chipIndex" small class="mt-4 ml-3" color="blue" outlined>
                                                    {{ item }}
                                                </v-chip>
                                            </div>
                                            <p :class="{
                                                'red--text': Number(data.score) < 0,
                                                'green--text': Number(data.score) > 0,
                                                'black--text': Number(data.score) === 0
                                            }" class="mb-0 mt-4 mr-10 font-weight-bold ml-auto body-2">
                                                {{ data.score }}
                                            </p>
                                        </div>
                                        <p style="margin-left: -10px;" class="body-2 mt-4 black--text font-weight-bold">
                                            {{ data.title }}
                                        </p>
                                    </div>
                                </v-col>
                            </v-row>
                            <!-- <div class="d-flex pa-1 "  > -->
                            <!-- <img class="" :src="'https://images.unsplash.com/photo-1518770660439-4636190af475'" alt="Image" style="border-radius: 10px;" width="125px" height="70%" /> -->

                            <!-- </div> -->
                        </v-card>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-divider></v-divider>
                        <p data-aos="fade-left" class="body-2 mt-6 ml-6 black--text  ">
                            <span class="mb-1 grey--text body-2 pr-2">
                                Summary :
                            </span> {{ data.summary }}
                        </p>
                        <p data-aos="fade-left" class="body-2 mt-6 ml-6 black--text   ">
                            <span class="mb-1 green--text body-2 pr-2">
                                Positive Impact :
                            </span> {{ data.positive_impact }}
                        </p>
                        <p data-aos="fade-left" class="body-2 mt-6 ml-6 black--text  ">
                            <span class="mb-1 red--text body-2 pr-2">
                                Negative Impact :
                            </span> {{ data.negative_impact }}
                        </p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

        </v-card>

        <!-- <header style="margin-bottom: -50px;">
            <div>
                <h1>New AI</h1>
                <p>Scroll horizontally to stack the cards. Scroll back to unstack them.</p>
            </div>
        </header> -->
        <!-- <main>
         
            <div id="cards-container">
                <ul id="cards">
                    <li v-for="(card, index) in MainData.news" :key="index" :id="`card-${index + 1}`" class="card">
                        <div class="card-content">
                            <div>
                                <span class="caption" style="color: #666666;">{{ card.published_time }}</span>
                                
                               <p class="mb-0 " style="font-size: 32px;">{{ card.title }}</p>
                               <p class="headline mb-0">Summary :</p>

                                <p style="color: #666666;"  class="body-1 mb-0">{{ card.summary }}</p>
                                <v-divider></v-divider>
                                <p class="headline mb-0">Impact :</p>
                                <p style="color: #666666;" class="body-1 mb-0">{{ card.impact }}</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </main> -->
    </div>
</template>

<script>
// import { number } from 'echarts';

// AOS.init();

export default {
    data() {
        return {
            News_data: [
                {
                    "news_image": "https://images.unsplash.com/photo-1518770660439-4636190af475",
                    "news_msg": "Global markets rally as central banks signal easing inflation concerns.",
                    "news_type": "Market"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1581092330842-a6f842ab30b7",
                    "news_msg": "Bitcoin surpasses $107,000 amid 'relentless' ETF demand.",
                    "news_type": "Crypto"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1556740749-887f6717d7e4",
                    "news_msg": "Tech stocks soar after positive quarterly earnings reports.",
                    "news_type": "Stocks"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1518770660439-4636190af475",
                    "news_msg": "Oil prices decline amid fears of oversupply in global markets.",
                    "news_type": "Commodities"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1517248135467-4c7edcad34c4",
                    "news_msg": "Federal Reserve keeps interest rates unchanged, eyes 2025 for cuts.",
                    "news_type": "Policy"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1518770660439-4636190af475",
                    "news_msg": "USD weakens against major currencies following economic slowdown fears.",
                    "news_type": "Forex"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1556740749-887f6717d7e4",
                    "news_msg": "Fintech startup raises $200M in Series C funding to expand operations.",
                    "news_type": "Startup"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1517248135467-4c7edcad34c4",
                    "news_msg": "Gold prices hit a 5-month high as investors seek safe havens.",
                    "news_type": "Commodities"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1581092330842-a6f842ab30b7",
                    "news_msg": "Upcoming IPO of a major tech company expected to raise $2 billion.",
                    "news_type": "IPO"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1518770660439-4636190af475",
                    "news_msg": "Retail sector reports mixed earnings amid holiday shopping season.",
                    "news_type": "Earnings"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1581092330842-a6f842ab30b7",
                    "news_msg": "New European regulations on crypto assets set to take effect in January.",
                    "news_type": "Regulation"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1556740749-887f6717d7e4",
                    "news_msg": "Two major banks announce merger to create a $1 trillion institution.",
                    "news_type": "Merger"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1517248135467-4c7edcad34c4",
                    "news_msg": "AI investments surge as companies race to integrate generative AI solutions.",
                    "news_type": "Technology"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1518770660439-4636190af475",
                    "news_msg": "Global economic growth forecast downgraded amid geopolitical tensions.",
                    "news_type": "Economy"
                },
                {
                    "news_image": "https://images.unsplash.com/photo-1581092330842-a6f842ab30b7",
                    "news_msg": "Green energy stocks rise as governments increase renewable energy subsidies.",
                    "news_type": "Renewable"
                }
            ],

            MainData: [],
            cards: [
                {
                    title: "Card One",
                    description:
                        "This is the content of card one. Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                    image: "https://assets.codepen.io/210284/flower-9.jpg",
                },
                {
                    title: "Card Two",
                    description:
                        "This is the content of card two. Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                    image: "https://assets.codepen.io/210284/flower-8.jpg",
                },
                {
                    title: "Card Three",
                    description:
                        "This is the content of card three. Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                    image: "https://assets.codepen.io/210284/flower-7.jpg",
                },
                {
                    title: "Card Four",
                    description:
                        "This is the content of card four. Lorem ipsum dolor sit amet consectetur adipisicing elit.",
                    image: "https://assets.codepen.io/210284/flower-6.jpg",
                },
            ],
            sortOrder: "asc",
        };
    },
    methods: {

        mountedData() {
            const axios = require('axios');

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'http://192.168.5.163:9001/getNews',
                headers: {}
            };
            let axiosthis = this
            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    axiosthis.MainData = response.data


                })
                .catch((error) => {
                    console.log(error);
                });

        },
        // sortchange() {
        //     console.log("lklklklk");

        // },

        sortchange() {
            // Toggle between ascending and descending
            this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
        },
    },
    computed: {
        sortedMainData() {
            // Create a copy of the MainData array and sort it
            return [...this.MainData].sort((a, b) => {
                const dateA = new Date(a.time);
                const dateB = new Date(b.time);

                if (this.sortOrder === "asc") {
                    return dateA - dateB; // Ascending order
                } else {
                    return dateB - dateA; // Descending order
                }
            });
        },
    },
    mounted() {
        this.mountedData()
    }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;700&family=DM+Mono:wght@400;500&display=swap");

:root {
    --card-width: 40vw;
    --card-margin: 4vw;
    --card-left-offset: 1em;
    --numcards: 4;
    --outline-width: 0px;
}

body {
    background: #131212;
    color: beige;
    text-align: center;
    padding-bottom: 20vh;
    overflow-x: hidden;
}

header,
main {
    width: 80vw;
    margin: 0 auto;
}

header {
    height: 35vh;
    display: grid;
    place-items: center;
    margin-bottom: 80px;
}

#cards-container {
    overflow-x: auto;
    /* white-space: nowrap; */
}

#cards {
    list-style: none;
    display: flex;
    gap: var(--card-margin);
    padding-bottom: calc(var(--numcards) * var(--card-left-offset));
    margin-bottom: var(--card-margin);
}

.card {
    position: sticky;
    left: 0;
    padding-left: calc(var(--index) * var(--card-left-offset));
}

.card-content {
    box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.1), 0 1em 2em rgba(0, 0, 0, 0.1);
    background: #fffaf2;
    color: #131212;
    border-radius: 2rem;
    overflow: hidden;
    display: grid;
    grid-template-areas: "text img";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    align-items: stretch;
    padding: 1.4em;
    /* width: var(--card-width); */
}

.card-content>div {
    grid-area: text;
    width: 700px;
    height: 400px;
    /* min-width: 800px; */
    /* place-self: center; */
    /* text-align: left; */
    display: grid;
    gap: 1em;
    place-items: start;
}





h1 {
    font-weight: 300;
    font-size: 3rem;
    margin-bottom: 1rem;
    font-family: "Cormorant Garamond", serif;
}

h2 {
    font-weight: bold;
    font-size: 2.5rem;
    font-family: "Cormorant Garamond", serif;
    margin: 0;
}

p {
    font-weight: 300;
    line-height: 1.42;
    font-size: 1.1rem;
}

/* Animation */
@supports (animation-timeline: works) {
    @scroll-timeline cards-element-scrolls-in-body {
        source: selector(body);
        scroll-offsets: selector(#cards) start 1, selector(#cards) start 0;
        start: selector(#cards) start 1;
        end: selector(#cards) start 0;
        time-range: 4s;
    }

    .card {
        --index0: calc(var(--index) - 1);
        --reverse-index: calc(var(--numcards) - var(--index0));
        --reverse-index0: calc(var(--reverse-index) - 1);
    }

    .card-content {
        transform-origin: 0% 50%;
        will-change: transform;

        --duration: calc(var(--reverse-index0) * 1s);
        --delay: calc(var(--index0) * 1s);

        animation: var(--duration) linear scale var(--delay) forwards;
        animation-timeline: cards-element-scrolls-in-body;
    }

    @keyframes scale {
        to {
            transform: scale(calc(1.1 - calc(0.1 * var(--reverse-index))));
        }
    }
}
</style>