<template>
  <div class="mt-10 ml-15">
    <h1>Create a New Post</h1>


    <div v-if="response">
      <h2>Response:</h2>
      <pre>{{ response }}</pre>
    </div>
  </div>
</template>

<script>
const axios = require("axios");

const postData1 = async (endpoint, data, headers) => {
  const API_BASE_URL = 'http://192.168.5.163:9003/'; // Update API URL
  try {
    const response = await axios.post(`${API_BASE_URL}/${endpoint}`, data, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.error('Error sending data:', error);
    throw error;
  }
};

export default {
  data() {
    return {
      title: '',
      body: '',
      response: null,
      susertoken: '',
      actid: '',
    };
  },
  methods: {
    async createPost(cliend, session) {
      // Define your postData objects and headers
      const postDataObj2 = {
        "cc": cliend,
        "from": "01/04/2024",
        "to": "31/12/2024",
        "segment": "NSE_CASH,BSE_CASH,NSE_FNO,BSE_FNO,MCX,NCDEX,NSE_COM,BSE_COM,CD_NSE,CD_BSE,CD_MCX,CD_USE",
        "withopen": "Y"
      };

      const headers = {
        'Content-Type': 'application/json',
        'clientid': cliend,
        'Authorization': session,
      };

      try {
        // Fetch all necessary data
        this.response = await postData1('getpnl', postDataObj2, headers);
        const transactions = this.response.transactions;

        let eqnotional = 0, fnonotional = 0, comnotional = 0, curnotional = 0, allnotional = 0;

        // Process transactions once the response is fetched
        transactions.forEach(element => {
          allnotional += element.NOT_PROFIT;
          if (['BSE_CASH', 'NSE_CASH', 'MF_BSE', 'MF_NSE', 'NSE_SLBM', 'NSE_SPT'].includes(element.company_code)) {
            eqnotional += element.NOT_PROFIT;
          } else if (['NSE_FNO', 'BSE_FNO'].includes(element.company_code)) {
            fnonotional += element.NOT_PROFIT;
          } else if (['MCX', 'NCDEX', 'NSE_COM', 'BSE_COM'].includes(element.company_code)) {
            comnotional += element.NOT_PROFIT;
          } else if (['CD_NSE', 'CD_MCX', 'CD_USE', 'CD_BSE'].includes(element.company_code)) {
            curnotional += element.NOT_PROFIT;
          }
        });

        // Wait for all journal data responses
        const alldata = await postData1('getJournal', postDataObj2, headers);
        const eqdata = await postData1('getJournal', { "cc": cliend, "from": "01/04/2024", "to": "31/12/2024", "segment": "NSE_CASH,BSE_CASH" }, headers);
        const fnodata = await postData1('getJournal', { "cc": cliend, "from": "01/04/2024", "to": "31/12/2024", "segment": "NSE_FNO,BSE_FNO" }, headers);
        const comdata = await postData1('getJournal', { "cc": cliend, "from": "01/04/2024", "to": "31/12/2024", "segment": "MCX,NCDEX,NSE_COM,BSE_COM" }, headers);
        const curdata = await postData1('getJournal', { "cc": cliend, "from": "01/04/2024", "to": "31/12/2024", "segment": "CD_NSE,CD_BSE,CD_MCX,CD_USE" }, headers);
        var allRealishedPnl = 0;
        var allunRealishedPnl = 0;
        // Wait for all journal data and process them
        for (let i = 0; i < alldata.Data.length; i++) {
          const element = alldata.Data[i];
          allRealishedPnl += Number(element.realisedpnl);
          allunRealishedPnl += Number(element.unrealisedpnl);
        }
        console.log("client id is : ", cliend)
        console.log("All", allRealishedPnl + allunRealishedPnl, "|||||", allnotional, "Difference |||| ", (allRealishedPnl + allunRealishedPnl) - allnotional);

        var eqRealishedPnl = 0;
        var equnRealishedPnl = 0;
        // Wait for all journal data and process them
        for (let i = 0; i < eqdata.Data.length; i++) {
          const element = eqdata.Data[i];
          eqRealishedPnl += Number(element.realisedpnl);
          equnRealishedPnl += Number(element.unrealisedpnl);
        }
        // console.log("EQ", eqRealishedPnl + equnRealishedPnl, "|||||", eqnotional, "Difference |||||", (eqRealishedPnl + equnRealishedPnl) - eqnotional);
        if ((eqRealishedPnl + equnRealishedPnl) - eqnotional < 100) {
          console.log("EQ", " true")
        }


        var fnoRealishedPnl = 0;
        var fnounRealishedPnl = 0;
        // Wait for all journal data and process them
        for (let i = 0; i < fnodata.Data.length; i++) {
          const element = fnodata.Data[i];
          fnoRealishedPnl += Number(element.realisedpnl);
          fnounRealishedPnl += Number(element.unrealisedpnl);
        }
        // console.log("Fno", fnoRealishedPnl + fnounRealishedPnl, "|||||", fnonotional, "Difference |||||", (fnoRealishedPnl + fnounRealishedPnl) - fnonotional);
        if ((fnoRealishedPnl + fnounRealishedPnl) - fnonotional < 100) {
          console.log("fno", " true")
        }

        var comRealishedPnl = 0;
        var comunRealishedPnl = 0;
        // Wait for all journal data and process them
        for (let i = 0; i < comdata.Data.length; i++) {
          const element = comdata.Data[i];
          comRealishedPnl += Number(element.realisedpnl);
          comunRealishedPnl += Number(element.unrealisedpnl);
        }
        // console.log("com", comRealishedPnl + comunRealishedPnl, "|||||", comnotional, "Difference ||||", (comRealishedPnl + comunRealishedPnl) - comnotional);

        if ((comRealishedPnl + comunRealishedPnl) - comnotional < 100) {
          console.log("com", " true")
        }

        var curRealishedPnl = 0;
        var curunRealishedPnl = 0;
        // Wait for all journal data and process them
        for (let i = 0; i < curdata.Data.length; i++) {
          const element = curdata.Data[i];
          curRealishedPnl += Number(element.realisedpnl);
          curunRealishedPnl += Number(element.unrealisedpnl);
        }
        // console.log("cur", curRealishedPnl + curunRealishedPnl, "|||||", curnotional, "Difference |||||", (curRealishedPnl + curunRealishedPnl) - curnotional);
        // const eqRealishedPnl = this.calculatePnl(eqdata);
        // const fnoRealishedPnl = this.calculatePnl(fnodata);
        // const comRealishedPnl = this.calculatePnl(comdata);
        // const curRealishedPnl = this.calculatePnl(curdata);
        if ( (curRealishedPnl + curunRealishedPnl) - curnotional < 100) {
          console.log("cur", " true")
        }
        // Logging the results
        // console.log('Notionals:', allnotional, fnonotional, comnotional, curnotional, eqnotional);

      } catch (error) {
        console.error('Error while creating post:', error);
        this.response = 'An error occurred while fetching data.';
      }
    },
    sessionget(client) {
      const axios = require('axios');
      let data = JSON.stringify({
        "clientid": client,
        "key": "6B8A276C46ABAC7B77962FD433A74"
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://rekycbe.mynt.in/autho/CreateToken',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          var cliend = response.data.clientid
          var session = response.data.token
          this.createPost(cliend, session)
        })
        .catch((error) => {
          console.log(error);
        });

    }
    // Helper function to calculate realised and unrealised PnL


  },

  mounted() {
    this.susertoken = localStorage.getItem("usession");
    this.actid = localStorage.getItem("userid");
    var arr = [
      "B1D268",
      "CS1D1",
      "CSS1B177",
      "CZ2439",
      "EA325",
      "EA55",
      "GH232",
      "TM10842",
      "TN455K6",
      "Z45753",
      "Z45873",
      "Z46277",
      "Z46828",
      "Z50700",
      "Z52629",
      "Z54789",
      "Z55102",
      "Z56473"
    ]
    for (let i = 0; i < arr.length; i++) {
      this.sessionget(arr[i])
    }

  }
};
</script>