<template>
  <div>
    <div v-if="load">
      <!-- d-none d-md-block -->
      <div >
        <div class=" mt-md-0 " v-if="!appbarhide">
        <SideMenubar />

        </div>
        <v-main class="mt-2">
          <div>
            <router-view />
          </div>

        </v-main>
      </div>
      <!-- d-lg-none d-md-none -->
      <!-- <div class="">
                <div style="position: absolute; top: 50%; right: 30%">
                    <p>Only visible in large device</p>
                </div>
            </div> -->
            <v-card v-if="promopopup" width="100%" style="position: relative;height:100vh;"
            class="d-none d-md-flex elevation-0 mx-auto ml-sm-4">
            <div style="position: fixed !important; bottom:0; z-index:6; right:0px">
              <v-icon style="position: absolute; top:10px; right:10px;" size="24" @click="setBannerPop()"
                 color="#fff">mdi-close-circle</v-icon>
              <a href="https://zebuetrade.com/apps" target="_blank">
                <img src="https://zebuetrade-v2.web.app/img/apppromo.2a53c601.png" width="300px" alt="apppromo">
              </a>
            </div>
            
          </v-card>
    </div>
    <div v-else>
      <!-- <div class="ma-auto" style="position: absolute; top:45% ; right: 40%">
                <v-progress-circular :size="80" color="primary" indeterminate></v-progress-circular>
            </div> -->
      <v-overlay class="rounded-0" style="z-index:2000">
        <div class="text-center">
          <v-progress-circular :size="60" :width="4" color="#0037B7" indeterminate></v-progress-circular>
        </div>
      </v-overlay>
    </div>


  </div>
</template>

<script>
import SideMenubar from "../navdraw/SideMenubar.vue";
import axios from "axios";

export default {
  data() {
    return {
      promopopup: false,

      load: false,
      blurLoader: false,
      appbarhide : false,
    }
  },
  created() {

    var url = new URL(window.location.href); ''
    var sacc = url.searchParams.get("sAccountId");
    var status = url.searchParams.get("status");
    var ucode = url.searchParams.get("ucode");
    var stok = url.searchParams.get("sToken");
    var apphide = url.searchParams.get("scr");
    // console.log("url",url.pathname)
    const array1 = ['/ledger','/holding','/positions','/pnl','/calenderpnl','/taxpnl','/tradebook','/pdfdownload'];
    const array2 = ['/profile','/bank','/deposltory','/segment','/mtf','/annualincome','/nominee','/family','/closure','/formdownload'];
    let pathurl = url.pathname
    let Reports = array1.includes(pathurl)
    let Profile = array2.includes(pathurl)
    localStorage.setItem("reports", Reports);
    localStorage.setItem("profile", Profile);

    // console.log(Reports,Profile);
    
    if (apphide == 'app') {
      localStorage.setItem("apphide", true);


      this.appbarhide = true
    }
    if (ucode) {
      this.load = true
    }
    else if (typeof sacc == 'string' && typeof stok == 'string') {
      let datao = JSON.stringify({
        "token": stok,
        "LoginId": sacc
      });
      let configo = {
        method: 'post',
        url: 'https://rekycbe.mynt.in/autho/kambala_auth',
        headers: {
          'Content-Type': 'application/json'
        },
        data: datao
      };

      let axiosThis = this;
      axios
        .request(configo)
        .then((response) => {
          if (response.data.clientid && response.data.token) {
            localStorage.setItem("usession", response.data.token);
            localStorage.setItem("userid", response.data.clientid);
            axiosThis.token = response.data.token;
            axiosThis.client_code = response.data.clientid;
            axiosThis.redirectpages();
          } else {
            localStorage.clear();
            axiosThis.goSso();
          }
          window.location.assign(window.location.href.split("?")[0]);

          // setTimeout(() => {
          // window.location.assign(window.location.href.split('?')[0])
          // }, 3000);
        })
        .catch((error) => {
          console.log(error);
          axiosThis.goSso();
        });

  } else if(typeof status == 'string'){
    var errorCode = url.searchParams.get("errorCode");
    var sessionid = url.searchParams.get("sessionid");
    var userid = url.searchParams.get("userid");
    var txnid = url.searchParams.get("txnid");
    var srcref = url.searchParams.get("srcref");
    console.log(status,errorCode,sessionid,userid,txnid,srcref);
    
    const axios = require('axios');
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://rekycbe.mynt.in/portfolio/responsegetservice?status=${status}&errorCode=${errorCode}&sessionid=${sessionid}&userid=${userid}&txnid=${txnid}&srcref=${srcref}`,
      headers: { }
    };
     
    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      if (response.data.msg) {
        window.location.assign(`https://profile.mynt.in/holdingdetail?response=${response.data.msg}`)
      }

    })
    .catch((error) => {
      console.log(error);
    });

    
    
  }
    else {
      var actid = url.searchParams.get("uid");
      var token = url.searchParams.get("token");
      this.token = localStorage.getItem("usession");
      this.client_code = localStorage.getItem("userid");
      // this.token = 'ad5aaf26fd75b2769ebc2e5e4df57a14a125b0804d79eaf602d4151f248bef6c';
      // this.client_code = 'BL241';
      if (typeof actid == 'string' && typeof token == 'string') {
        localStorage.setItem("usession", token);
        localStorage.setItem("userid", actid);
        this.token = localStorage.getItem("usession");
        this.client_code = localStorage.getItem("userid");
        window.location.assign(window.location.href.split('?')[0])
      }

      let data = JSON.stringify({
        clientid: this.client_code,
        token: this.token,
      });

      let config = {
        method: "post",
        url: "https://rekycbe.mynt.in/autho/validate_session",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      let axiosThis = this;
      axios.request(config)
        .then((response) => {
          if (response.data.msg == "valid token") {
            // 
            axiosThis.redirectpages();

          } else {
            axiosThis.goSso();
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.goSso();
        });
    }




  },
  methods: {
    setBannerPop() {
      localStorage.setItem('banner', new Date().getHours())
      this.promopopup = false
    },
    //     handleKeyDown(event) {
    //   // Check if the Ctrl key (or Command key on Mac) is pressed along with 'C'
    //         if ((event.metaKey || event.ctrlKey) && event.key === 'c') {
    //     event.preventDefault();
    //     alert('Copying is not allowed on this website.');
    //   }
    // },

    cdslloder() {
      // console.log(this.blurLoader);
      this.blurLoader = true;
      // console.log(this.blurLoader);
    },
    goSso() {
      window.location.assign(`https://desk.mynt.in/?url=${window.location.href}`)
    },
    getname() {
      const axios = require('axios');
      let data = JSON.stringify({
        "string": "CCoDuKPQR/7gkcombTy5GnYkHsELdwYd+uaW0UxA5BE="
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://rekycbe.mynt.in/profile',
        headers: {
          'clientid': 'ZP00285',
          'Authorization': 'd07806aaf86d56ac919c3a1c25d6962169e0b72d4e584678251e5b1e4d2621fe',
          'Content-Type': 'application/json'
        },
        data: data
      };
      let axiosthis = this
      axios.request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data,'dada'));
          axiosthis.get = response.data
          // console.log("axiosthis.get", axiosthis.get);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    redirectpages() {
      this.actid = localStorage.getItem("userid");
      this.susertoken = localStorage.getItem("usession");
      // this.actid = 'BL241';
      // this.susertoken = 'ad5aaf26fd75b2769ebc2e5e4df57a14a125b0804d79eaf602d4151f248bef6c';
      // this.susertoken = localStorage.getItem("usession");
      // console.log('mosaaaaaaaaaaaaa', this.susertoken, this.actid);
      var dall = JSON.stringify({
        client_id: this.actid,
      });        // this.actid = localStorage.getItem("");
      let date1 = this.encryptionFunction(dall)
      // console.log(date1, "1saaaaaaaaaaaaa");
      const axios = require('axios');
      let data = JSON.stringify({
        "string": date1
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://rekycbe.mynt.in/profile',
        headers: {
          'clientid': this.actid,
          'Authorization': this.susertoken,
          'Content-Type': 'application/json'
        },
        data: data
      };
      let axiosthis = this
      axios.request(config)
        .then((response) => {
          localStorage.setItem("profile_data", response.data.str);
              // if(response.data.emsg  == 'invalid token'){
              // localStorage.setItem("profile_data", response.data.emsg);
          // }
          this.namedate = response.data.str
          let conval = this.decryptionFunction(this.namedate)
          let con1 = JSON.parse(conval)
          // console.log(con1,'dada');
          let con = con1.client_data.CLIENT_NAME
          let ddpi = con1.client_data.DDPI
          let poa = con1.client_data.POA
          let mtf1 = con1.client_data.MTFCl
          let mtf2 = con1.client_data.MTFClAuto
          const isClosedClient = con1.client_data.segments_data.some(
        (segment) =>
          segment.inactivetype_DESC === "Close Client-Close" || 
          segment.inactivetype_DESC === "close-Close"
      );
          if (isClosedClient) {
            localStorage.setItem('clientStatus', 'client_close');
          } else {
            localStorage.setItem('clientStatus', 'not_close');
          }
          // let ddpi = 'N'
          // let poa = 'N'
          // // let ddpi = 'Y'
          // // let poa = 'Y'
          // let nap = this.encryptionFunction(con1.client_data.PAN_NO)
          // let ddpi = con.client_data.DDPI
          // let poa = con.client_data.POA
          // console.log(con1,"dadad");
          // localStorage.setItem("nap", nap);
          localStorage.setItem("cname", con);
          localStorage.setItem("ddpi", ddpi);
          localStorage.setItem("poa", poa);
          localStorage.setItem("mtf1", mtf1);
          localStorage.setItem("mtf2", mtf2);
          axiosthis.load = true

        })
        .catch((error) => {
          console.log(error);
        });

    },
    encryptionFunction(payld) {
      var CryptoJS = require("crypto-js");
      const payload = payld;
      var derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      var iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      var test = CryptoJS.AES.encrypt(payload, derived_key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }).toString();
      return test;
    },
    decryptionFunction(payld) {
      var CryptoJS = require("crypto-js");

      const payload = payld;
      const derived_key = CryptoJS.enc.Base64.parse(btoa("N#j2L^8pq9Fb$d@1")); //YXNkZmdoamtsenhjdmJubQ==
      const iv = CryptoJS.enc.Utf8.parse("3790514682037125");
      const encryptedData = payload;

      // Decrypt the data using AES
      const decrypted = CryptoJS.AES.decrypt(encryptedData, derived_key, {
        iv,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

      // // console.log('Decrypted Data:', decryptedData);
      return decryptedData;
    },
  },
  components: { SideMenubar },
  mounted() {
    let b = localStorage.getItem('banner')
    if (b != new Date().getHours()) {
      this.promopopup = true;
    }

    this.appbarhide = localStorage.getItem("apphide");

    // const axios = require('axios');
    // let data = JSON.stringify({
    //   "clientid": "ze1a40",
    //   "key": "6B8A276C46ABAC7B77962FD433A74"
    // });

    // let config = {
    //   method: 'post',
    //   maxBodyLength: Infinity,
    //   url: 'http://3.7.217.93:9002/CreateToken',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   data: data
    // };

    // axios.request(config)
    //   .then((response) => {
    //     console.log(JSON.stringify(response.data));
    //     localStorage.setItem("userid", response.data.clientid);
    //     localStorage.setItem("usession", response.data.token);

    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });


    // localStorage.setItem("userid", 'BL241');
    // localStorage.setItem("usession", 'ad5aaf26fd75b2769ebc2e5e4df57a14a125b0804d79eaf602d4151f248bef6c');
    // this.redirectpages();

  }
};
</script>
<style lang="scss">


*,
html,
body .v-application,
.v-application .display-4,
.v-application .display-3,
.v-application .display-1,
.v-application .headline,
.v-application .title,
.v-application .subtitle-1,
.v-application .subtitle-2,
.v-application .body-1,
.v-application .body-2,
.v-application .caption,
.v-application .overline {
  font-family: "Tenon" !important;
}

.no-scroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent !important;
}

::-webkit-scrollbar-thumb {
  background: #e5ebec;
  border-radius: 16px;
}

.cust-appbar {
  background-color: white !important;
  position: fixed !important;
  z-index: 1 !important;
  border-bottom: 1px solid #eceff3 !important;
  box-shadow: 0px 1px 15px 0px rgba(44, 44, 45, 0.03),
    0px 5px 22px 0px rgba(154, 154, 156, 0.02) !important;
}

.body-card {
  height: calc(100vh - 8px) !important;
  max-width: 100% !important;
  overflow-y: hidden !important;
}

.v-text-field--enclosed.v-input--:not(.v-text-field--solo) .v-input__prepend-outer {
  display: none;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.txt-666 {
  color: #666 !important;
}

.rightpayout {
  .v-input input {
    font-size: 14px;
    font-weight: 600;
  }

  .v-label {
    font-size: 12px;
    font-weight: 569;
  }
}

.v-text-field input {
  font-size: 12px;
}

.v-file-input .v-file-input__text {
  font-size: 12px;
}

.v-select__selection--comma {
  font-size: 12px;
}

.v-label {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.theme--light.v-list-item--active::before {
  opacity: none !important;
}

.v-list-item--link:before {
  opacity: none !important;
  background-color: #eff2f5 !important;
  font-weight: 600 !important;
}

.snakbar-sty {
  z-index: 6 !important;
  position: fixed !important;
}

.rgt-crd {
  width: 80% !important;
}

.body-part {
  width: 100% !important;
}

@media only screen and (max-width: 1264px) {
  .body-card {
    max-width: 100% !important;
  }
}

@media only screen and (min-width: 959px) {
  .body-card {
    max-width: 90% !important;
  }
}

@media only screen and (max-width: 959px) {
  .rgt-crd {
    width: 100% !important;
  }

  .body-card {
    max-width: 100% !important;
  }
}
</style>